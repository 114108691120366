import React, { useCallback, useMemo, useState } from 'react';
import Snowfall from 'react-snowfall';
import { useFeatureFlag } from '../unleash/useFeatureFlag';

type RossumXmasContextType = {
  isXmas: boolean;
  toggleMerry: () => void;
};

const RossumXmasContext = React.createContext<RossumXmasContextType | null>(
  null
);

export const RossumXmas = ({ children }: { children: React.ReactNode }) => {
  const [isXmas, setIsXmas] = useState(false);

  const isXmasModeEnabled = useFeatureFlag('xmas');

  const toggleMerry = useCallback(() => {
    if (window.Sentry) {
      if (!isXmas) {
        window.Sentry.captureMessage('Snow started falling!');
      } else {
        window.Sentry.captureMessage('Snow stopped falling!');
      }
    }
    setIsXmas(xmas => !xmas);
  }, [isXmas]);

  const ctxValue = useMemo(
    () => ({ isXmas: isXmas && isXmasModeEnabled, toggleMerry }),
    [isXmas, isXmasModeEnabled, toggleMerry]
  );

  return (
    <RossumXmasContext.Provider value={ctxValue}>
      <Snowfall
        snowflakeCount={isXmas ? 500 : 0}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: 1000000,
        }}
      />
      {children}
    </RossumXmasContext.Provider>
  );
};

export const useXmasContext = () => {
  const ctx = React.useContext(RossumXmasContext);

  if (!ctx) {
    throw new Error('XMas context can only be used during XMas!');
  }

  return ctx;
};
