import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { Annotation } from '../../../../../types/annotation';
import { useAddAttachmentsModal } from '../../../../annotation-attachment/hooks/useAddAttachmentsModal';
import { useCanPerformAttachmentActions } from '../../../../annotation-attachment/hooks/useCanAddAttachments';
import { attachmentsFeatureSelector } from '../../../../pricing/selectors';
import { SidebarFooterAction } from './utils';

type UseLinkActionResult = {
  modal: JSX.Element | null;
  action: SidebarFooterAction | null;
};

export const useLinkAction = (annotation: Annotation): UseLinkActionResult => {
  const intl = useIntl();

  const { restrictedAccess } = annotation;

  const { node: addAttachmentModal, openModal: openAddAttachmentModal } =
    useAddAttachmentsModal();

  const isDocAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const canPerformAttachmentActions = useCanPerformAttachmentActions();

  const handleLinkOpen = useCallback(() => {
    openAddAttachmentModal(annotation.url);
  }, [annotation.url, openAddAttachmentModal]);

  const linkVisible =
    !isEmbedded() &&
    !restrictedAccess &&
    isDocAttachmentsEnabled &&
    canPerformAttachmentActions;

  const result = useMemo(() => {
    return {
      modal: addAttachmentModal,
      action: linkVisible
        ? {
            id: 'link' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.link',
            }),
            onClick: handleLinkOpen,
            dataCy: 'annotation-sidebar-add-attachment',
          }
        : null,
    };
  }, [addAttachmentModal, handleLinkOpen, intl, linkVisible]);

  return result;
};
