import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmbedded } from '../../../../../constants/config';
import { ModalKey } from '../../../../../containers/Modals/types';
import { snakeToCamel } from '../../../../../lib/keyConvertor';
import { deleteAnnotations } from '../../../../../redux/modules/annotations/actions';
import { pauseValidation } from '../../../../../redux/modules/beforeLeave/actions';
import { openModal } from '../../../../../redux/modules/modal/actions';
import { isUserViewer } from '../../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../../types/annotation';
import { State } from '../../../../../types/state';
import { SidebarFooterAction } from './utils';

type UseDeleteActionResult = {
  action: SidebarFooterAction | null;
};

export const useDeleteAction = (
  annotation: Annotation
): UseDeleteActionResult => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const { restrictedAccess } = annotation;

  const annotationStatus = snakeToCamel(annotation.status);

  const isConfirming = useSelector((state: State) => state.ui.confirming);

  const isReadOnly = useSelector((state: State) => state.ui.readOnly);

  const embeddedConfig = useSelector((state: State) => state.ui.embeddedConfig);

  const userIsViewer = useSelector(isUserViewer);

  const showInformation = useCallback(
    ({
      type,
      onConfirm,
      values,
    }: {
      type: ModalKey;
      onConfirm: () => void;
      values: unknown;
    }) =>
      () =>
        dispatch(openModal({ textId: type, values, onConfirm })),
    [dispatch]
  );

  const showDeleteInformation = useCallback(
    (annotation: Annotation) =>
      showInformation({
        type: 'annotationDelete',
        onConfirm: () =>
          dispatch(
            pauseValidation({
              nextAction: deleteAnnotations([annotation.url]),
              trigger: 'deleteAnnotation',
              reason: 'surveys',
            })
          ),
        values: { count: 1 },
      }),
    [dispatch, showInformation]
  );

  const isDeletable =
    [
      'rejected',
      'importing',
      'failedImport',
      'confirmed',
      'exported',
      'failedExport',
    ].includes(snakeToCamel(annotationStatus)) ||
    !(isConfirming || isReadOnly) ||
    restrictedAccess;

  const handleDelete = useCallback(() => {
    showDeleteInformation(annotation)();
  }, [annotation, showDeleteInformation]);

  const deleteVisible =
    !isEmbedded() ||
    (isEmbedded() && embeddedConfig?.deleteUrl && !userIsViewer);

  const result = useMemo(() => {
    return {
      action: deleteVisible
        ? {
            id: 'delete' as const,
            label: intl.formatMessage({
              id: 'components.sidebarV2.actions.delete',
            }),
            disabled: !isDeletable,
            onClick: handleDelete,
            dataCy: 'annotation-sidebar-delete',
          }
        : null,
    };
  }, [deleteVisible, handleDelete, intl, isDeletable]);

  return result;
};
