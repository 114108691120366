import { findIndex } from 'lodash';
import { AnyDatapointDataST } from '../../../../types/datapoints';

let idToIndexCache = new Map<number, number>();

let cacheRebuilds = 0;

export const getFindDatapointIndexStatistics = () => ({
  cacheRebuilds,
});

/**
 * NOTE: allDatapoints is coming from Redux, so it's an ImmutableArray.
 * To avoid using ImutableArray's Array methods, which are slow,
 * we use lodash functions instead.
 *
 * Replace it with native Array methods, when we get rid of seamleass-immutable.
 */
export const findDatapointIndex = (
  allDatapoints: AnyDatapointDataST[],
  id: number
) => {
  const cachedIndex = idToIndexCache.get(id);

  const cacheMiss =
    cachedIndex === undefined || allDatapoints[cachedIndex]?.id !== id;

  if (cacheMiss) {
    const index = findIndex(allDatapoints, d => d.id === id);

    if (index === -1) return -1;

    cacheRebuilds += 1;

    idToIndexCache = new Map(allDatapoints.map((d, i) => [d.id, i]));
    return index;
  }

  return idToIndexCache.get(id) ?? -1;
};

export const findDatapointById = (
  allDatapoints: AnyDatapointDataST[],
  id: number
): AnyDatapointDataST | undefined => {
  const index = findDatapointIndex(allDatapoints, id);

  return index === -1 ? undefined : allDatapoints[index];
};
