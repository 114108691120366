import {
  InputAdornment,
  inputBaseClasses,
  TextField,
  useTheme,
} from '@rossum/ui/material';
import { ChangeEventHandler, useCallback } from 'react';
import { MessagesStatsWithBlockers } from '../../automation-blockers/helpers';
import { MessageIndicator } from './MessageIndicator';

type SidebarItemActiveValueProps = {
  value: string;
  onChange: (val: string) => void;
  disabled: boolean;
  messagesStats: MessagesStatsWithBlockers | undefined;
};

export const SidebarItemActiveValue = ({
  value,
  onChange,
  disabled,
  messagesStats,
}: SidebarItemActiveValueProps) => {
  const theme = useTheme();

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    e => {
      return onChange(e.target.value);
    },
    [onChange]
  );

  const handleFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    // https://stackoverflow.com/questions/35951771/react-autofocus-sets-cursor-to-beginning-of-input-value
    const val = event.target.value;
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    // eslint-disable-next-line no-param-reassign
    event.target.value = val;
  };

  return (
    <TextField
      multiline
      size="small"
      value={value}
      onChange={handleChange}
      disabled={disabled}
      autoFocus
      onFocus={handleFocus}
      sx={{
        width: '100%',
        [`& .${inputBaseClasses.adornedEnd}`]: {
          alignItems: 'flex-start',
        },
      }}
      inputProps={{
        style: {
          ...theme.typography.body2,
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              height: '100%',
              marginRight: '-5px',
            }}
          >
            <MessageIndicator messagesStats={messagesStats} />
          </InputAdornment>
        ),
      }}
    />
  );
};
