import { Stack, Typography } from '@rossum/ui/material';

type SidebarItemLabelProps = {
  label: string;
  sublabel?: string;
};

export const SidebarItemLabel = ({
  label,
  sublabel,
}: SidebarItemLabelProps) => {
  const labelNode = (
    <Typography variant="body2" color="text.secondary">
      {label}
    </Typography>
  );

  const sublabelNode = (
    <Typography variant="caption" color="text.secondary">
      {sublabel}
    </Typography>
  );

  return (
    <Stack spacing={1} direction="row">
      {sublabelNode ? (
        <Stack spacing={0}>
          {labelNode}
          {sublabelNode}
        </Stack>
      ) : (
        labelNode
      )}
    </Stack>
  );
};
