import { Message } from '@rossum/api-client/shared';
import update from 'immutability-helper';
import { compact, findIndex, get } from 'lodash';
import * as R from 'remeda';
import { Rectangle2DCoordinates } from '../../../features/annotation-view/document-canvas/utils/geometry';
import { mapTree } from '../../../lib/helpers';
import {
  AnyDatapointData,
  AnyDatapointDataST,
  DatapointsST,
  MultivalueDatapointData,
  SimpleDatapointData,
} from '../../../types/datapoints';
import { AggregationMessage } from '../../../types/message';
import { AnyDatapointSchema } from '../../../types/schema';
import { insertArrayInArray } from '../utils';
import { findDatapointById } from './navigation/findDatapointIndex';
import {
  flattenDatapoints,
  removeAllChildren,
  updateIndexes,
  updateSimpleDatapoint,
} from './typedHelpers';
import { ValidateFulfilledPayload } from './types';

export const updateContentValue = (
  state: DatapointsST,
  { content, id }: SimpleDatapointData
) => {
  const datapoint = state.content.find(datapoint => datapoint.id === id);

  if (!datapoint) {
    return undefined;
  }

  if (datapoint.category !== 'datapoint') {
    // This shouldn't happen, but TS can't check that
    return datapoint;
  }

  return {
    ...datapoint,
    content: content
      ? {
          ...datapoint.content,
          value: content.value,
          page: content.page,
          position: content.position,
        }
      : null,
  };
};

const updateMultivalueDatapoint = (
  state: DatapointsST,
  _datapoint: MultivalueDatapointData,
  complexLineItemsEnabled: boolean
) => {
  const index = findIndex(state.content, { id: _datapoint.id });
  const datapointMeta = state.content[index]?.meta;
  const _state = removeAllChildren(state, index);

  const { content, newChilds } = flattenDatapoints(
    { content: [_datapoint] },
    complexLineItemsEnabled,
    index
  );

  const newDatapoints = update(content, {
    0: {
      meta: {
        $set: datapointMeta,
      },
    },
  });

  return update(
    update(
      update(_state, {
        content: datapoints =>
          updateIndexes({
            startIndex: index + 1,
            amount: newDatapoints.length - newChilds.length,
          })(datapoints),
      }),
      {
        content: datapoints =>
          newChilds.reduce(
            (acc, { index: childIndex }) => [
              ...acc.slice(0, childIndex),
              ...acc.slice(childIndex + 1),
            ],
            datapoints
          ),
      }
    ),
    {
      content: datapoints =>
        insertArrayInArray(index, newDatapoints)(datapoints),
    }
  );
};

// This function processes results of /validate action,
// so it accepts all types of datapoints (even if it updates only datapoint/multivalue).
export const updateDatapoints = (
  state: DatapointsST,
  datapoints: AnyDatapointData[],
  complexLineItemsEnabled: boolean
): DatapointsST =>
  datapoints.reduce(
    (_state, _datapoint) =>
      _datapoint.category === 'multivalue'
        ? updateMultivalueDatapoint(_state, _datapoint, complexLineItemsEnabled)
        : updateSimpleDatapoint(_state, _datapoint),
    state
  );

// When schema is changed on already exported annotation, we need to generate our own IDs.
// Normally BE generates new fields on /start request but it doesn't happen for exported docs
export const fillInDatapointIds = (datapoints: {
  content: AnyDatapointData[];
}) => {
  const { content, ...state } = datapoints;
  const result = mapTree(content, ({ id, ...rest }, i) => ({
    ...rest,
    id: id === null ? i : id,
  }));
  return {
    ...state,
    content: result,
  };
};

const typeValues = {
  info: 0,
  warning: 1,
  error: 2,
};

// Helper function to get the highest priority message
export const getHighestPriorityMessage = (messages: Array<Message>) =>
  R.firstBy(messages, message => -typeValues[message.type]);

// Preprocess messages into a map and sort them by priority
const preprocessMessages = (messages: Array<Message>) => {
  const groupedMessages = R.groupBy(
    messages,
    message => message.id ?? undefined
  );

  const sortedMessages = R.mapValues(
    groupedMessages,
    getHighestPriorityMessage
  );

  return sortedMessages;
};

export const messagesStatsAndSeverity = (
  messages: Array<Message>,
  allDatapoints: Array<AnyDatapointDataST>
) => {
  const result: Record<
    string,
    { count: number; highestSeverity: Message['type'] }
  > = {};

  for (let m = 0; m < messages.length; m += 1) {
    const message = messages[m];

    const id = Number(message.id);
    if (R.isNumber(id)) {
      const datapoint = findDatapointById(allDatapoints, id);
      const prev = result[id] ?? { count: 0, highestSeverity: 'info' };

      result[id] = {
        count: prev.count + 1,
        highestSeverity:
          typeValues[message.type] > typeValues[prev.highestSeverity]
            ? message.type
            : prev.highestSeverity,
      };

      let parentId = Number(datapoint?.meta.parentId);
      while (R.isNumber(parentId)) {
        const parentDatapoint = findDatapointById(allDatapoints, parentId);
        const prevParent = result[parentId] ?? {
          count: 0,
          highestSeverity: 'info',
        };

        result[parentId] = {
          count: prevParent.count + 1,
          highestSeverity:
            typeValues[message.type] > typeValues[prevParent.highestSeverity]
              ? message.type
              : prevParent.highestSeverity,
        };

        parentId = Number(parentDatapoint?.meta.parentId);
      }
    }
  }

  return result;
};

export const mapMessages = (
  datapoints: Array<AnyDatapointDataST>,
  messages: Array<Message>
) => {
  const preprocessedMessages = preprocessMessages(messages);

  const all = preprocessedMessages?.all;

  const [datapointsWithChildren, datapointsWithoutChildren] = R.partition(
    datapoints,
    dp => 'children' in dp
  );

  const [tupleDatapoints, multivalueDatapoints] = R.partition(
    datapointsWithChildren,
    dp => 'children' in dp && dp.category === 'tuple'
  );

  const simpleDatapointOwnMessages = datapointsWithoutChildren.flatMap(
    datapoint => {
      const { id } = datapoint;
      const ownMessage = preprocessedMessages?.[id];

      if (ownMessage) {
        return [[id, ownMessage]] as const;
      }
      return [];
    }
  );

  const tupleMessages = tupleDatapoints.flatMap(datapoint => {
    const { id, category } = datapoint;

    if (category !== 'tuple') {
      return [];
    }

    const childrenMessages = compact(
      datapoint.children.map(child => preprocessedMessages?.[child.id])
    );

    const allTupleMessages = preprocessedMessages?.[id]
      ? compact([preprocessedMessages[id], ...childrenMessages])
      : childrenMessages;

    const messageToShow = getHighestPriorityMessage(allTupleMessages);

    return messageToShow ? [[id, messageToShow] as const] : ([] as const);
  });

  const multivalueMessages = multivalueDatapoints.flatMap(datapoint => {
    const { id, category } = datapoint;
    if (category !== 'multivalue') {
      return [];
    }

    const childrenMessages = compact(
      datapoint.children.map(child => {
        const childOwnMessage = preprocessedMessages?.[child.id];

        // Simple multivalues children has their own messages, no need to pick those from tupleMessages
        if (childOwnMessage) return childOwnMessage;

        const childMessage = tupleMessages.find(
          tupleMessage =>
            R.isNumber(tupleMessage[0]) && tupleMessage[0] === child.id
        );

        return childMessage ? childMessage[1] : undefined;
      })
    );

    const allMultivalueMessages = preprocessedMessages?.[id]
      ? compact([preprocessedMessages[id], ...childrenMessages])
      : childrenMessages;

    const messageToShow = getHighestPriorityMessage(allMultivalueMessages);

    return messageToShow ? [[id, messageToShow] as const] : ([] as const);
  });

  const allMessagesTuples = [
    ...(all ? ([['all', all]] as const) : []),
    ...simpleDatapointOwnMessages,
    ...tupleMessages,
    ...multivalueMessages,
  ];

  const resultObject = Object.fromEntries(allMessagesTuples);

  return resultObject;
};

export const mapAggregations = (aggregations: AggregationMessage[]) =>
  aggregations.reduce<Record<number, Array<AggregationMessage>>>(
    (acc, aggregation) => ({
      ...acc,
      [aggregation.id]: (acc[Number(aggregation.id)] || []).concat(aggregation),
    }),
    {}
  );

export const addSchemaToUpdatedTreeDatapoints =
  (response: ValidateFulfilledPayload) => (schemas: AnyDatapointSchema[]) => {
    const updatedDatapoints = get(
      response,
      'updatedDatapoints',
      [] as AnyDatapointData[]
    );

    if (!updatedDatapoints.length) {
      return response;
    }

    return {
      ...response,
      updatedDatapoints: addSchemaToTreeDatapointsHelper(
        schemas,
        updatedDatapoints
      ),
    };
  };

export const addSchemaToSuggestedMultivalue = (
  schemas: AnyDatapointSchema[],
  multivalue: MultivalueDatapointData
) =>
  addSchemaToTreeDatapointsHelper(schemas, [
    multivalue,
  ])[0] as MultivalueDatapointData;

export const addSchemaToTreeDatapointsHelper = (
  schemas: AnyDatapointSchema[],
  updatedDatapoints: AnyDatapointData[],
  isNewDatapoint = false
): AnyDatapointData[] =>
  updatedDatapoints.map(datapoint => {
    const isTreeDatapoint = 'children' in datapoint;
    const datapointWithSchema =
      isNewDatapoint || isTreeDatapoint
        ? ({
            ...datapoint,
            schema: schemas.find(({ id }) => datapoint.schemaId === id),
          } as AnyDatapointData)
        : datapoint;

    // TS is not so clever, so we can't use isTreeDatapoint here
    if ('children' in datapoint) {
      return {
        ...datapointWithSchema,
        children: addSchemaToTreeDatapointsHelper(
          schemas,
          datapoint.children,
          true
        ),
      } as AnyDatapointData;
    }

    return datapointWithSchema;
  });

export const sortBboxAttributes = ([
  x1,
  y1,
  x2,
  y2,
]: Rectangle2DCoordinates) => [
  Math.min(x1, x2),
  Math.min(y1, y2),
  Math.max(x1, x2),
  Math.max(y1, y2),
];

const orderMessagesByPriority = (messages: Array<Message>) =>
  R.sortBy(messages, message => -typeValues[message.type]);

export const mapAllMessages = (messages: Array<Message>) => {
  const groupedMessages = R.groupBy(
    messages,
    message => message.id ?? undefined
  );

  const sortedMessages = R.mapValues(groupedMessages, orderMessagesByPriority);

  return sortedMessages;
};
