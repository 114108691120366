import { Queue } from '@rossum/api-client/queues';
import { Pathname } from 'history';
import { find, isNumber } from 'lodash';
import createSelector from '../../../lib/createDeepEqualSelector';
import { getCurrentQueueId } from '../../../lib/url';
import { State } from '../../../types/state';
import {
  findWorkspaceByQueue,
  workspacesSelector,
} from '../workspaces/selector';

export const queuesSelector = (state: State) => state.queues?.list;
const pathNameSelector = (state: State): Pathname =>
  state.router.location.pathname;

export const currentQueueFromPathnameSelector = createSelector(
  queuesSelector,
  pathNameSelector,
  (queues, pathname): Queue | null | undefined => {
    const id = getCurrentQueueId(pathname);
    if (!isNumber(id)) return null;
    return find(queues, { id });
  }
);

export const currentQueueAndWorkspaceSelector = createSelector(
  currentQueueFromPathnameSelector,
  workspacesSelector,
  (queue, workspaces) => {
    const workspace = queue ? findWorkspaceByQueue(workspaces, queue) : null;
    return { queue, workspace };
  }
);

export const queueByIdSelector = createSelector(
  queuesSelector,
  (_: State, queueId: number) => queueId,
  (queues, queueId) => queues.find(queue => queue.id === queueId)
);

const queuesErrorsSelector = (state: State) => state.queues?.errors;
export const queueErrorByIdSelector = createSelector(
  queuesErrorsSelector,
  errors => (queueId: number) => errors?.[queueId]
);
