import { ThemeType } from '@rossum/rossum-ui/theme';
import {
  CssBaseline,
  darken,
  lighten,
  ThemeProvider,
} from '@rossum/ui/material';
import { muiPaper, resolveTheme } from '@rossum/ui/theme';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { mapValues } from 'remeda';
import { useXmasContext } from './containers/RossumXmas';
import { organizationSelector } from './redux/modules/organization/selectors';
import { OrganizationBranding } from './types/organization';
import { State } from './types/state';

export const HTML_FONT_SIZE = 10;

type StateProps = {
  themeType: ThemeType;
  branding?: OrganizationBranding;
};

type OwnProps = {
  children: React.ReactNode;
  theme?: 'dark' | 'light';
};

type Props = StateProps & OwnProps;

const xMasPapers = {
  dark: {
    '0': 'none',
    '2': 'rgba(1, 14, 1, 1)', // #010901
    '4': 'rgba(2, 24, 2, 1)', // #021302
    '6': 'rgba(3, 33, 2, 1)', // #031C02
    '8': 'rgba(3, 38, 3, 1)', // #032103
    '12': 'rgba(4, 43, 3, 1)', // #042603
    '16': 'rgba(4, 47, 4, 1)', // #042A04
    '24': 'rgba(5, 52, 4, 1)', // #052F04
  },
  light: {},
};
const RuiThemeProvider: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ themeType, branding, children, theme }) => {
  const { isXmas } = useXmasContext();

  const themeConfig = useMemo(() => {
    const currentTheme = theme ?? (themeType === 'white' ? 'light' : 'dark');

    const resolvedTheme = resolveTheme(isXmas ? 'dark' : currentTheme, {
      // TODO: Proper resolution of all possible brandings?
      // we need mapping that takes into account current styling, branding options and
      // MUI mechanics - standalone task, this is just for primary color demo
      palette: isXmas
        ? {
            mode: 'dark',
            primary: {
              main: '#C41E3A',
              light: lighten('#C41E3A', 0.15),
              dark: darken('#C41E3A', 0.2),
            },
            secondary: {
              main: '#D4AF37',
              light: lighten('#D4AF37', 0.15),
              dark: darken('#D4AF37', 0.2),
            },
            background: {
              default: '#051006',
              paper: '#0B1C0C',
            },
          }
        : branding?.colors?.primary
          ? {
              primary: {
                main: branding.colors.primary,
              },
            }
          : {},
      // needs to be added to respect font-size: 10px on html
      typography: {
        htmlFontSize: HTML_FONT_SIZE,
        ...(branding?.font
          ? {
              fontFamily: branding?.font,
            }
          : {}),
      },
      // TODO: Document this and add value different than 0
      ...(branding?.borderRadiuses
        ? {
            shape: {
              borderRadius: 0,
            },
          }
        : {}),
      components: {
        MuiSelect: {
          styleOverrides: {
            select: {
              height: 0,
              lineHeight: 1.5,
            },
          },
        },
        MuiPaper: isXmas
          ? muiPaper(xMasPapers)({
              // @ts-expect-error
              palette: { mode: 'dark' },
              // @ts-expect-error
              shape: { borderRadius: 4 },
            })
          : {},
        MuiCssBaseline: {
          styleOverrides: {
            // Fix for beamer snippets with white titles on white background.
            '.beamerAnnouncementSnippetContent': {
              backgroundColor: 'white',
            },
            '.beamerAnnouncementSnippetTitle, .beamerAnnouncementSnippetText': {
              color: 'black',
            },
            body: {
              lineHeight: 1.42857143,
              fontSize: '1.4rem',
              letterSpacing: 'unset',
              '*::-webkit-scrollbar': {
                width: 8,
                height: 8,
              },
            },
          },
        },
      },
    });

    return {
      ...resolvedTheme,
      // Ensure all z-indexes are above the values used in our app
      // https://mui.com/customization/z-index/
      zIndex: mapValues(resolvedTheme.zIndex, zIndex => zIndex * 10),
    };
  }, [
    branding?.borderRadiuses,
    branding?.colors?.primary,
    branding?.font,
    isXmas,
    theme,
    themeType,
  ]);

  useEffect(() => {
    const themePalette = themeConfig.palette;
    [
      ['--color-primary', themePalette.primary.main],
      ['--graybackground', themePalette.background.paper],
      ['--graybox', themePalette.background.default],
      ['--validationBackground', themePalette.background.paper],
      ['--annotation-footer', themePalette.background.paper],
      [
        '--uberBlack-rgb',
        themePalette.mode === 'dark' ? '18, 17, 23' : '244, 243, 246',
      ],
      [
        '--white-rgb',
        themePalette.mode === 'dark' ? '255, 255, 255' : '18, 17, 23',
      ],
      ['--dropdown-item-hover', lighten(themePalette.background.paper, 0.1)],
      [
        '--annotation-fake-select-background',
        themePalette.mode === 'dark' ? '#000' : '#E7E4EB',
      ],
    ].forEach(([variable, value]) => {
      document.documentElement.style.setProperty(variable, value);
    });
  }, [isXmas, themeConfig.palette]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: State) => {
  const themeType =
    state.user.uiSettings.theme ??
    organizationSelector(state)?.uiSettings?.theme ??
    'dark';
  return {
    themeType,
    branding: organizationSelector(state)?.uiSettings?.branding,
  };
};

export default connect<StateProps, null, OwnProps, State>(mapStateToProps)(
  RuiThemeProvider
);
