import {
  IconLibrary,
  IconLink,
  IconMail,
  IconScissors,
  IconSettings,
} from '@rossum/ui/icons/tabler';
import { Badge, IconButton, Slide, Stack, SvgIcon } from '@rossum/ui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LineItemSettingsDialog } from '../../../../components/LineItemsControls/components/LineItemSettingsDialog';
import { EditDocumentsRestrictor } from '../../../../components/Restrictors';
import { isEmbedded } from '../../../../constants/config';
import { DrawerConfig } from '../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { constructDocumentUrl } from '../../../../lib/url';
import { safeOrganizationSelector } from '../../../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { Annotation } from '../../../../types/annotation';
import { State } from '../../../../types/state';
import { useFetchAttachmentRelation } from '../../../annotation-attachment/hooks/useFetchAttachmentRelation';
import { useRelatedEmail } from '../../../emails/annotation-email-badge/useRelatedEmail';
import { attachmentsFeatureSelector } from '../../../pricing/selectors';
import { useDocumentStore } from '../../document-store/DocumentStore';

type SidebarTopbarProps = {
  scrollableRef: HTMLDivElement | null;
  annotation: Annotation;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

export const SIDEBAR_TOPBAR_HEIGHT = 45;

export const SidebarTopbar = ({
  scrollableRef,
  annotation,
  onEmailThreadOpen,
}: SidebarTopbarProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const lastPosition = useRef(0);

  const handleScroll = useCallback(() => {
    const top = scrollableRef?.scrollTop ?? 0;

    const delta = top - lastPosition.current;

    lastPosition.current = top;

    if (delta > 5 && top > 100) {
      setIsOpen(false);
    }
    if (delta < -5) {
      setIsOpen(true);
    }
  }, [scrollableRef?.scrollTop]);

  useEffect(() => {
    if (scrollableRef) {
      scrollableRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableRef) {
        scrollableRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, scrollableRef]);

  // Settings button
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const [settingsOpen, setSettingsOpen] = useState(false);

  // Edit mode button
  const history = useHistory();

  const organization = useSelector(safeOrganizationSelector);

  const readOnly = useSelector((state: State) => state.ui.readOnly);

  const disableEditMode = !!organization?.uiSettings?.features?.disableEditMode;

  // Duplicates button
  const currentRelation = useSelector(
    (state: State) => state.relations.currentRelation
  );

  const openDrawer = useDocumentStore(state => state.openDrawer);

  // Attachments button
  const isAttachmentsEnabled = useSelector(attachmentsFeatureSelector);

  const { attachmentRelation } = useFetchAttachmentRelation(
    annotation.id ?? null
  );

  const attachmentsCount = attachmentRelation?.annotations.length;

  // Email button
  const { email } = useRelatedEmail({
    annotation: annotation ?? {
      relatedEmails: [],
      email: null,
      status: null,
    },
  });

  return (
    <>
      <Slide in={isOpen} direction="down">
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            zIndex: 50,
            py: 0.5,
            px: 1,
            backgroundColor: theme => theme.palette.background.paper,
            borderBottom: '1px solid',
            // TODO: Should be other.divider once available
            borderColor: theme => theme.palette.other.muted,
          }}
        >
          <IconButton size="medium" onClick={() => setSettingsOpen(true)}>
            <SvgIcon fontSize="small">
              <IconSettings />
            </SvgIcon>
          </IconButton>
          <EditDocumentsRestrictor>
            <IconButton
              size="medium"
              disabled={
                !(
                  (!readOnly || annotation.status === 'split') &&
                  !disableEditMode
                )
              }
              onClick={() => {
                history.push(
                  constructDocumentUrl({
                    id: annotation.id,
                    nestedPath: '/edit',
                  })
                );
              }}
            >
              <SvgIcon fontSize="small">
                <IconScissors />
              </SvgIcon>
            </IconButton>
          </EditDocumentsRestrictor>
          {!isEmbedded() && email ? (
            <IconButton
              size="medium"
              onClick={() =>
                onEmailThreadOpen({ highlightedEmailUrl: email.url })
              }
            >
              <Badge
                badgeContent={annotation.relatedEmails.length}
                color="secondary"
              >
                <SvgIcon fontSize="small">
                  <IconMail />
                </SvgIcon>
              </Badge>
            </IconButton>
          ) : null}
          {!isEmbedded() && currentRelation && annotation ? (
            <IconButton size="medium">
              <Badge
                badgeContent={currentRelation?.annotations?.length}
                color="secondary"
                max={9}
              >
                <SvgIcon
                  fontSize="small"
                  onClick={() =>
                    openDrawer({
                      drawer: 'duplicates',
                      annotationId: annotation.id,
                    })
                  }
                >
                  <IconLibrary />
                </SvgIcon>
              </Badge>
            </IconButton>
          ) : null}
          {isAttachmentsEnabled &&
          annotation.url &&
          attachmentsCount &&
          attachmentsCount > 0 ? (
            <IconButton
              size="medium"
              onClick={() =>
                openDrawer({
                  drawer: 'attachments',
                  annotationId: annotation.id,
                })
              }
            >
              <Badge badgeContent={attachmentsCount} color="secondary" max={9}>
                <SvgIcon fontSize="small">
                  <IconLink />
                </SvgIcon>
              </Badge>
            </IconButton>
          ) : null}
        </Stack>
      </Slide>
      <LineItemSettingsDialog
        key={`${complexLineItemsEnabled}`}
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      />
    </>
  );
};
