import { find, get } from 'lodash';
import { useState } from 'react';
import { clamp } from 'remeda';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { gridScaleFactorSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { Vector } from '../../lib/spaceConvertor';
import { ColumnPosition, RowPosition } from '../../types/datapoints';
import { MIN_SEPARATOR_DISTANCE } from './constants';
import { useGridContext } from './GridContext';
import { useDraggableInteraction } from './useDraggableInteraction';
import { moveSeparator } from './utils';

// Using lodash get with casts instead of ts-expert error to access dynamic properties based on orientation
// Any better idea welcome
export const useSeparatorState = (
  orientation: 'horizontal' | 'vertical',
  index: number,
  onMoved: (index: number) => void
) => {
  const scaleFactor = useDocumentStore(gridScaleFactorSelector);

  const [orientationKey, positionKey, dimensionKey, diffIndex] =
    orientation === 'horizontal'
      ? (['rows', 'topPosition', 'height', 1] as const)
      : (['columns', 'leftPosition', 'width', 0] as const);

  const {
    gridState,
    gridDraftState: [draftState, setDraftState],
  } = useGridContext();

  const currentGridState = draftState ?? gridState;

  // Deletion state
  const [markedForDeletion, setMarkedForDeletion] = useState(false);

  // Positioning
  const cssPosition =
    (get(currentGridState, [orientationKey, index, positionKey], 0) -
      get(currentGridState, [orientationKey, 0, positionKey], 0)) *
    scaleFactor;

  // Moving
  const originalPosition = get(
    gridState,
    [orientationKey, index, positionKey],
    0
  ) as number;

  const previousSeparatorOriginalPosition =
    index > 0
      ? (get(gridState, [orientationKey, index - 1, positionKey], 0) as number)
      : null;

  const nextSeparatorOriginalPosition =
    index < gridState[orientationKey].length - 1
      ? (get(gridState, [orientationKey, index + 1, positionKey], 0) as number)
      : (((get(gridState, [dimensionKey], 0) as number) +
          get(gridState, [orientationKey, 0, positionKey], 0)) as number);

  const { handleMouseDown, isDragging } = useDraggableInteraction({
    onDragStart: () => {
      setDraftState(gridState);
    },
    onDrag: (diff: Vector) =>
      setDraftState(
        moveSeparator(
          orientation,
          gridState,
          index,
          clamp(Math.round(diff[diffIndex]), {
            min: -(
              originalPosition -
              Number(previousSeparatorOriginalPosition) -
              2 * MIN_SEPARATOR_DISTANCE
            ),
            max:
              nextSeparatorOriginalPosition -
              originalPosition -
              2 * MIN_SEPARATOR_DISTANCE,
          })
        )
      ),
    onDragEnd: () => {
      onMoved(index);
      setDraftState(null);
    },
  });

  const nextSeparator = find(
    get(currentGridState, [orientationKey]),
    colOrRow =>
      (get(colOrRow, [positionKey], 0) as number) >
      (get(currentGridState, [orientationKey, index, positionKey], 0) as number)
  ) as ColumnPosition | RowPosition | undefined;

  const currentPosition = get(
    currentGridState,
    [orientationKey, index, positionKey],
    0
  );

  const startEdgePosition = get(
    currentGridState,
    [orientationKey, 0, positionKey],
    0
  );

  const endEdgePosition =
    startEdgePosition + get(currentGridState, [dimensionKey]);

  const nextSeparatorPosition = Math.min(
    get(nextSeparator, [positionKey], endEdgePosition),
    endEdgePosition
  );

  const size = nextSeparatorPosition - currentPosition;

  return {
    cssPosition,
    handleMouseDown,
    isDragging,
    markedForDeletion: [markedForDeletion, setMarkedForDeletion] as const,
    size,
  };
};
