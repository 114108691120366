import {
  SidebarEnumFieldModel,
  SidebarLineItemFieldModel,
  SidebarMultivalueChildFieldModel,
  SidebarMultivalueFieldModel,
  SidebarSimplevalueFieldModel,
} from '../useSidebarData';

export type Item =
  | SidebarEnumFieldModel
  | SidebarLineItemFieldModel
  | SidebarMultivalueChildFieldModel
  | SidebarSimplevalueFieldModel
  | SidebarMultivalueFieldModel;

export type ItemWithValue =
  | SidebarEnumFieldModel
  | SidebarMultivalueChildFieldModel
  | SidebarSimplevalueFieldModel;

export const formatConfidenceScore = (score: number | null) =>
  typeof score === 'number' ? `${(score * 100).toFixed(2)}%` : undefined;

export const getFieldsSettingsPath = (
  queueId: number | undefined,
  schemaId: string | undefined
) =>
  typeof queueId === 'number' && !!schemaId
    ? `/queues/${queueId}/settings/fields/${schemaId}`
    : null;
