import { createSelector } from 'reselect';
import { approvalWorkflowsFeatureSelector } from '../../../features/pricing/selectors';
import { Annotation } from '../../../types/annotation';
import { State } from '../../../types/state';
import { findParentsSchemas, schemaSelector } from '../datapoints/selector';
import { currentAnnotationQueueSelector } from './currentAnnotationQueueSelector';
import { DatapointAutomationBlocker } from './types';

export const queueHasWorkflowsSelector = createSelector(
  approvalWorkflowsFeatureSelector,
  currentAnnotationQueueSelector,
  (workflowsEnabled, queue) =>
    !!workflowsEnabled &&
    queue &&
    queue.settings?.workflows?.enabled &&
    queue.workflows &&
    queue.workflows.length > 0
);

export const queueHasBypassWorkflowsEnabledSelector = createSelector(
  currentAnnotationQueueSelector,
  queue => queue && queue.settings?.workflows?.bypassWorkflowsAllowed
);

export const rejectionEnabledSelector = createSelector(
  currentAnnotationQueueSelector,
  queue => queue?.settings.rejectionConfig?.enabled ?? false
);

export const annotationSelector = (state: State): Annotation | undefined =>
  state.annotation.id === null ? undefined : (state.annotation as Annotation);

export const annotationSideloadsSelector = (state: State) =>
  state.annotation.sideloads;

// automation blockers

const automationBlockers = (state: State) =>
  state.annotation.sideloads.automationBlockers;

const canShowAutomationBlockers = createSelector(
  currentAnnotationQueueSelector,
  queue =>
    queue &&
    queue.automationEnabled &&
    ['confident', 'always'].includes(queue.automationLevel)
);

export const automationBlockersSelector = createSelector(
  canShowAutomationBlockers,
  automationBlockers,
  (enabled, blockers) => (enabled ? blockers : [])
);

const fieldAutomationBlockersVisibleSelector = (state: State) =>
  state.ui.fieldAutomationBlockersVisible;

const mapBlockersBySchemaId = (
  blockers: ReturnType<typeof automationBlockersSelector>,
  schema: ReturnType<typeof schemaSelector>
): { [schemaId: string]: DatapointAutomationBlocker[] } =>
  blockers.reduce<Record<string, DatapointAutomationBlocker[]>>(
    (acc, blocker) => {
      if (schema && blocker.level === 'datapoint') {
        const multivalueSchemaId = findParentsSchemas(
          schema,
          blocker.schemaId
        ).find(({ category }) => category === 'multivalue')?.id;

        return {
          ...acc,
          [blocker.schemaId]: (acc[blocker.schemaId] || []).concat(blocker),
          ...(multivalueSchemaId && {
            [multivalueSchemaId]: (acc[multivalueSchemaId] || []).concat(
              blocker
            ),
          }),
        };
      }

      return acc;
    },
    {}
  );

export const datapointBlockerVisibleSelector = createSelector(
  automationBlockersSelector,
  schemaSelector,
  fieldAutomationBlockersVisibleSelector,
  (blockers, schema, visible) => {
    if (!visible) return {};

    return mapBlockersBySchemaId(blockers, schema);
  }
);

export const datapointBlockerSelector = createSelector(
  automationBlockersSelector,
  schemaSelector,
  (blockers, schema) => {
    return mapBlockersBySchemaId(blockers, schema);
  }
);

export const relevantBlockersSelector = createSelector(
  automationBlockersSelector,
  blockers =>
    blockers.filter(
      b =>
        b.type !== 'automation_disabled' &&
        b.type !== 'human_confirmation_required'
    )
);

export const displayReviewIsNeededSelector = (s: State) =>
  s.annotation.sideloads.automationBlockers.some(
    ab => ab.type === 'human_confirmation_required'
  );
