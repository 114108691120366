import {
  IconAlertCircle,
  IconCircleCheck,
  IconX,
} from '@rossum/ui/icons/tabler';
import {
  AlertProps,
  IconButton,
  Snackbar,
  snackbarContentClasses,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { actionMap } from './actions';
import { CustomToastProps } from './types';
import { getSeverityStyles } from './utils';

type Severity = NonNullable<AlertProps['severity']>;

const iconMap: Record<Severity, JSX.Element | null> = {
  success: <IconCircleCheck />,
  warning: <IconAlertCircle />,
  error: <IconAlertCircle />,
  info: null,
};

export const ToasterMessage = ({
  title,
  description,
  severity,
  onClose,
  toastAction,
}: {
  severity: Severity;
} & CustomToastProps) => {
  const intl = useIntl();
  const Icon = iconMap[severity];

  const Action = toastAction
    ? actionMap[toastAction.key]({ ...toastAction.props, intl })
    : null;

  return (
    <Snackbar
      data-cy={`${severity}-toaster`}
      sx={{
        position: 'static',
        flex: 1,
        maxWidth: 500,
      }}
      open
      message={
        <Stack direction="row" gap={1} alignItems="center">
          {Icon ? <SvgIcon color={severity}>{Icon}</SvgIcon> : null}
          <Stack>
            <Typography>{title}</Typography>
            {description ? (
              <Typography variant="body2">{description}</Typography>
            ) : null}
          </Stack>
        </Stack>
      }
      action={
        <Stack direction="row" gap={0.5}>
          {Action}

          <IconButton
            onClick={() => {
              onClose?.();
            }}
          >
            <SvgIcon sx={{ color: 'text.primary' }}>
              <IconX />
            </SvgIcon>
          </IconButton>
        </Stack>
      }
      ContentProps={{
        elevation: 6,
        sx: theme => ({
          [`& .${snackbarContentClasses.message}`]: {
            flex: 1,
          },

          border: '1px solid',
          color: 'text.primary',
          borderRadius: `${theme.shape.borders.small}px`,
          backgroundImage: severity === 'info' ? '' : 'unset',
          ...getSeverityStyles(severity, theme),
        }),
      }}
    />
  );
};
