import { escapeRegExp } from 'lodash';
import { OptionT } from '../../../components/Picker';

export const filterWorkspaces = <
  Q extends { name: string; id: number },
  W extends { name: string; queues: Q[] },
>(
  search: string,
  workspaces: W[]
): W[] => {
  const value = escapeRegExp(search.toUpperCase());

  return workspaces.reduce<W[]>((acc, workspace) => {
    if (workspace.name.toUpperCase().search(value) !== -1)
      return [...acc, workspace];

    const queues = workspace.queues.reduce<Q[]>(
      (queuesAcc, queue) =>
        queue.name.toUpperCase().search(value) !== -1 ||
        queue.id.toString() === value
          ? [...queuesAcc, queue]
          : queuesAcc,
      []
    );

    return queues.length
      ? [
          ...acc,
          {
            ...workspace,
            queues,
            originalQueuesCount: workspace.queues.length,
          },
        ]
      : acc;
  }, []);
};

export const workspacesToOptions = <
  T extends { name: string; queues: { name: string; id: number }[] },
>(
  workspaces: T[]
): OptionT[] =>
  workspaces
    .filter(({ queues }) => queues.length)
    .map(({ name, queues }) => ({
      label: name,
      value: queues.map(({ name: _name, id }) => ({
        label: _name,
        value: id,
      })),
    }));
