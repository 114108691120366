import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Annotation } from '@rossum/api-client/annotations';
import { useQuery } from '@tanstack/react-query';
import { unique } from 'remeda';
import { useApiClient } from '../../../../lib/apiClient';

type UseRequestDetailActivityPayload = {
  annotation: Annotation;
};

export const useRequestDetailActivity = ({
  annotation,
}: UseRequestDetailActivityPayload) => {
  const api = useApiClient();
  const activity = useQuery({
    queryKey: ['workflows', 'activities', annotation.id] as const,

    queryFn: () =>
      api.unpaginatedRequest(endpoints.workflowActivities.list)({
        annotation: annotation.id,
      }),
  });

  const activityUserIds = activity.data
    ? unique(
        activity.data
          .flatMap(activity => [
            activity.createdBy,
            ...(activity.assignees ?? []),
          ])
          .flatMap(userUrl => (userUrl ? [getIDFromUrl(userUrl)] : []))
      )
    : undefined;

  const workflowStepIds = activity.data
    ? unique(
        activity.data
          .map(activity => activity.workflowStep)
          .flatMap(workflowStepUrl =>
            workflowStepUrl ? [getIDFromUrl(workflowStepUrl)] : []
          )
      )
    : undefined;

  const workflowIds = activity.data
    ? unique(
        activity.data
          .map(activity => activity.workflow)
          .flatMap(workflowUrl =>
            workflowUrl ? [getIDFromUrl(workflowUrl)] : []
          )
      )
    : undefined;

  const users = useQuery({
    queryKey: ['workflows', 'activityUsers', activityUserIds],

    queryFn: () =>
      api.request(
        endpoints.users.list({
          id: activityUserIds,
          pageSize: activityUserIds?.length ?? 1,
        })
      ),

    enabled: activityUserIds && activityUserIds.length > 0,
  });

  const workflowSteps = useQuery({
    queryKey: ['workflows', 'activityWorkflowSteps', workflowStepIds],

    queryFn: () =>
      api.request(
        endpoints.workflowSteps.list({
          id: workflowStepIds,
          pageSize: workflowStepIds?.length ?? 1,
        })
      ),

    enabled: workflowStepIds && workflowStepIds.length > 0,
  });

  const workflows = useQuery({
    queryKey: ['workflows', 'activityWorkflows', workflowIds],

    queryFn: () =>
      api.request(
        endpoints.workflows.list({
          id: workflowIds,
          pageSize: workflowIds?.length ?? 1,
        })
      ),

    enabled: workflowIds && workflowIds.length > 0,
  });

  return { activity, users, workflowSteps, workflows };
};
