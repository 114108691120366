import { omitBy } from 'remeda';
import { recentActionsLength } from '../../constants/values';
import { ActionType, Next } from '../../types/redux';

type RecentAction = Omit<ActionType, 'payload'>;

// TODO: improve typing once TS is ready to rock 🤘 and RootActionType is available
export default () => (next: Next) => (action: ActionType) => {
  const previousActions = window.recent_actions || [];
  if (previousActions.length >= recentActionsLength) previousActions.shift();

  const { type, meta } = action;
  const payload = omitBy<RecentAction>(
    { type, meta },
    a => a === undefined
  ) as RecentAction;

  window.recent_actions = [...previousActions, payload];
  return next(action);
};
