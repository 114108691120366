import { format, isThisYear, isToday, isYesterday } from 'date-fns';
import { IntlShape, useIntl } from 'react-intl';
import { dateFnsLocales } from '../../../i18n/dateLocales';
import {
  dateTimeFormats,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultTimeFormat,
  timeFormats,
} from '../../../lib/timeUtils';

const formatDateTime = (date: Date, intl: IntlShape) => {
  const locale = dateFnsLocales[intl.locale];
  const dateFormat = dateTimeFormats[intl.locale] ?? defaultDateTimeFormat;
  try {
    return format(new Date(date), dateFormat, { locale });
  } catch {
    return '';
  }
};
const getFormattedDate = (date: Date, intl: IntlShape) => {
  const locale = dateFnsLocales[intl.locale];

  const formatAsDayTime = isToday(date);
  const formatAsYesterdayString = isYesterday(date);
  const formatAsThisYear = isThisYear(date);

  try {
    if (formatAsDayTime) {
      const timeFormat = timeFormats[intl.locale] ?? defaultTimeFormat;
      return format(date, timeFormat, { locale });
    }
    if (formatAsYesterdayString) {
      return intl.formatMessage({
        id: 'containers.annotationList.extractedValues.date.yesterday',
      });
    }
    if (formatAsThisYear) {
      return format(date, defaultDateFormat, { locale });
    }

    return format(date, 'PP', { locale });
  } catch {
    return '';
  }
};

const useFormattedDate = (date: Date | undefined) => {
  const intl = useIntl();

  const formattedDate = date ? getFormattedDate(date, intl) : '';
  const formattedDateTime = date ? formatDateTime(date, intl) : '';
  return { formattedDate, formattedDateTime };
};

export default useFormattedDate;
