import { Collapse } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { relevantBlockersSelector } from '../../../../redux/modules/annotation/selectors';
import { AutomationBlockersList } from './AutomationBlockersList';

type UseAutomationBlockersListParams = {
  handleSelectDatapoint: (id: number) => void;
};

export const useAutomationBlockersList = ({
  handleSelectDatapoint,
}: UseAutomationBlockersListParams) => {
  const automationBlockers = useSelector(relevantBlockersSelector);

  return (
    <Collapse in={automationBlockers.length > 0}>
      {automationBlockers.length > 0 ? (
        <AutomationBlockersList
          handleSelectDatapoint={handleSelectDatapoint}
          automationBlockers={automationBlockers}
        />
      ) : (
        <span />
      )}
    </Collapse>
  );
};
