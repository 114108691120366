import { getIDFromUrl } from '@rossum/api-client';
import { ArrowDropDown } from '@rossum/ui/icons';
import { ListItemProps, Skeleton, Stack } from '@rossum/ui/material';
import deepEqual from 'fast-deep-equal/es6/react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sidebarFieldIdsEnabledSelector } from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { EnumOption } from '../../../../types/schema';
import { SchemaFieldIconType } from '../../../../ui/schema-field-icon/SchemaFieldIcon';
import { SidebarEnumFieldModel } from '../useSidebarData';
import { InactiveValue } from './shared/InactiveValue';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { SidebarItemActiveEnumValue } from './shared/SidebarItemActiveEnumValue';
import { SidebarItemLabel } from './shared/SidebarItemLabel';
import { SidebarItemLayout } from './shared/SidebarItemLayout';
import { formatConfidenceScore, getFieldsSettingsPath } from './utils';

type EnumItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarEnumFieldModel;
  active: boolean;
  onChange: (item: SidebarEnumFieldModel, newValue: string) => void;
  onClick: (datapointId: number) => void;
  loading: boolean;
  selected?: boolean;
  disabled: boolean;
};

const EnumItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    active,
    onChange,
    onClick,
    disabled,
    selected,
    loading,
    ...listItemProps
  }: EnumItemProps) => {
    const handleChange = useCallback(
      (newValue: EnumOption | null) => {
        onChange(item, newValue?.value ?? '');
      },
      [item, onChange]
    );

    const handleClick = useCallback(() => {
      onClick(item.id);
    }, [item.id, onClick]);

    const isDisabled = disabled || item.editing === 'disabled';

    const isEdited = !!('noRecalculation' in item && item.noRecalculation);

    const isConfirmed = useMemo(() => {
      return item.validationSources.includes('human');
    }, [item.validationSources]);

    const iconType: SchemaFieldIconType = item.valueSource;

    const iconVariant = useMemo(() => {
      if (isEdited) {
        return 'edited';
      }

      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed, isEdited]);

    const iconColor = useMemo(() => {
      if (isEdited || isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed, isEdited]);

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <MagicContainer
        active={active}
        sidebarScrollableRef={sidebarScrollableRef}
        onClick={active ? undefined : handleClick}
        selected={selected}
        disabled={isDisabled}
        {...listItemProps}
      >
        <SidebarItemLayout
          iconSlot={
            <ItemContextActions
              item={item}
              active={active}
              disabled={isDisabled}
              iconProps={{
                type: iconType,
                variant: iconVariant,
                color: iconColor,
              }}
              fieldSettingsPath={getFieldsSettingsPath(queueId, item.schemaId)}
            />
          }
          labelSlot={
            <SidebarItemLabel
              label={item.label}
              sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
            />
          }
          valueSlot={
            loading ? (
              <Stack height={1} justifyContent="center">
                <Skeleton height="100%" />
              </Stack>
            ) : active ? (
              <SidebarItemActiveEnumValue
                value={item.valueOption}
                onChange={handleChange}
                messagesStats={item.messagesStats}
                options={item.options}
                clearable={item.clearable}
                disabled={isDisabled}
              />
            ) : (
              <InactiveValue
                value={item.valueOption?.label ?? ''}
                confidence={formatConfidenceScore(item.confidenceScore)}
              />
            )
          }
          decorationSlot={
            active ? null : (
              <Stack direction="row" spacing={0.5}>
                <MessageIndicator messagesStats={item.messagesStats} />
                <ArrowDropDown
                  style={{ marginTop: '-2px', marginRight: '4px' }}
                  color={isDisabled ? 'disabled' : 'secondary'}
                />
              </Stack>
            )
          }
        />
        {active && item.messages.length > 0 && (
          <Messages messages={item.messages} />
        )}
      </MagicContainer>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

EnumItem.displayName = 'EnumItem';

export { EnumItem };
