import { Message } from '@rossum/api-client/shared';
import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { LastInteractionT } from '../../../components/Datapoint';
import SidebarDatapoint from '../../../components/Datapoint/SidebarDatapoint';
import { displayReviewIsNeededSelector } from '../../../redux/modules/annotation/selectors';
import { hasMatchedTriggerRules } from '../../../redux/modules/datapoints/selector';
import { schemaMapSelector } from '../../../redux/modules/schema/schemaMapSelector';
import {
  AnyDatapointDataST,
  MatchedTriggerRulesPerLevel,
  SectionDatapointDataST,
} from '../../../types/datapoints';
import styles from '../style.module.sass';
import DeleteRecommendation from './DeleteRecommendation';
import { ReviewIsNeeded } from './ReviewIsNeeded';

type Props = {
  datapoints: Array<AnyDatapointDataST>;
  editingDatapointValue: boolean;
  messages: { [key: number]: Message };
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
  readOnly: boolean;
  searchPanelShown: boolean;
  sections: Array<SectionDatapointDataST>;
  setCurrentDatapointRef: (ref: HTMLElement) => void;
  lastInteraction: LastInteractionT;
  handleLastInteraction: (interactionAction: LastInteractionT) => void;
};

const Middle = ({
  datapoints,
  editingDatapointValue,
  messages,
  matchedTriggerRules,
  readOnly,
  searchPanelShown,
  sections,
  setCurrentDatapointRef,
  lastInteraction,
  handleLastInteraction,
}: Props) => {
  const schemaMap = useSelector(schemaMapSelector);
  const displayDeleteRecommendation =
    hasMatchedTriggerRules(matchedTriggerRules);

  const displayReviewIsNeeded = useSelector(displayReviewIsNeededSelector);

  return (
    <>
      {displayDeleteRecommendation && (
        <DeleteRecommendation matchedTriggerRules={matchedTriggerRules} />
      )}
      {displayReviewIsNeeded && <ReviewIsNeeded />}
      {sections.map((section: SectionDatapointDataST, sectionIndex) => (
        <React.Fragment key={section.id}>
          <div className={styles.SectionTitle} data-cy="invoice-section-title">
            {get(section, 'schema.label')}
          </div>

          {section.children.map(({ index }) => {
            const datapoint = datapoints[index];
            const schema = schemaMap.get(datapoint.schemaId);
            const message = get(messages, datapoint.id);
            const myPath = [section.id, datapoint.id];

            return (
              <SidebarDatapoint
                datapointIndex={index}
                editingDatapointValue={editingDatapointValue}
                inFooter={false}
                isSimpleMultivalue={!!datapoint.meta.isSimpleMultivalue}
                key={`${datapoint.id}${index}`}
                message={message}
                messages={messages}
                myPath={myPath}
                readOnly={readOnly}
                schema={schema}
                searchPanelShown={searchPanelShown}
                setCurrentDatapointRef={setCurrentDatapointRef}
                lastInteraction={lastInteraction}
                handleLastInteraction={handleLastInteraction}
              />
            );
          })}
          {sectionIndex < sections.length - 1 && ( // last section must be without divider
            <div key={section.id} className={styles.SectionDivider} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Middle;
