import { HooksListQuery } from '@rossum/api-client/hooks';
import { Hook } from '@rossum/api-client/hooks';
import { partition, unique } from 'remeda';
import { ExtensionEventAction, normalizeExtensionEvents } from '../lib/helpers';
import { SelectedEventT } from './types';

export type HookNormalizedEvents = Omit<Hook, 'events'> & {
  events: Array<ExtensionEventAction>;
};

export type ExtendedHook = HookNormalizedEvents & {
  runBefore: Hook['url'][];
};

/**
 * Helper function which:
 * 1. normalizes events
 * This normalization ensures that events are in the same format and are comparable
 */
export const normalizeEventsInExtensions = (extensions: Hook[]) =>
  extensions.map((extension): HookNormalizedEvents => {
    return {
      ...extension,
      events: normalizeExtensionEvents(extension.events),
    };
  });

/**
 * Helper function that convert extensions in following steps:
 * 1. filter extensions based on selectedEvent
 * 2. sanitize runAfter and add runBefore
 */
export const createGraphFunctionsInput = (
  extensions: HookNormalizedEvents[],
  selectedEvent: SelectedEventT
) => {
  const filteredByEvent = filterByEvent(extensions, selectedEvent);

  return sanitizeRunAfterAndAddRunBefore(filteredByEvent);
};

/**
 * Helper function which:
 * 1. extends hook with a new field runBefore
 * This field contains direct children and it is derived from runAfter of other extensions.
 * 2. filters runAfter
 * Removes runAfter which are not present in the list of filtered extensions
 */
const sanitizeRunAfterAndAddRunBefore = (extensions: HookNormalizedEvents[]) =>
  extensions.map((extension): ExtendedHook => {
    const directChildren = extensions
      .filter(({ runAfter }) => runAfter.includes(extension.url))
      .map(({ url }) => url);

    return {
      ...extension,
      runBefore: directChildren,
      runAfter: extension.runAfter.filter(url =>
        extensions.some(extension => extension.url === url)
      ),
    };
  });

/**
 * Helper function which:
 * 1. filters extensions based on selectedEvent
 */
const filterByEvent = (
  extensions: HookNormalizedEvents[],
  selectedEvent: SelectedEventT
) =>
  selectedEvent === 'all'
    ? extensions
    : extensions.filter(hook => hook.events.includes(selectedEvent));

export type ExtensionsGraphData = Array<Array<ExtendedHook>>;

export const INVALID_INPUT_DATA_ERROR_MESSAGE =
  'There is an unreachable extension in the data.';

type GetGraphData = (
  extensions: Array<ExtendedHook>,
  visited?: Array<ExtendedHook>
) => ExtensionsGraphData;

/**
 * @param extensions - an array of extensions extended with runBefore
 * @param visited - an array of extensions which are already visited
 * @returns an array of columns, each column contains extensions which are supposed to run in the same "stage"
 *
 * 1. Split extensions into two groups.
 *  - First group consists of those with empty runAfter, these should run in current stage
 *  - Second group consists of those which are not used in current stage
 *
 * 2. Split first group by runBefore - put group of extensions with at least one runBefore before group with none
 *
 * 3. Call function recursively with nodesForOtherColumns and concat visited with nodesForCurrentColumn
 *
 * 4. Repeat until there aren't any nodesForOtherColumns extensions
 *
 */
export const getGraphData: GetGraphData = (extensions, visited = []) => {
  const [nodesForCurrentColumn, nodesForOtherColumns] = partition(
    extensions,
    ext => {
      const runAfterWithoutVisited = ext.runAfter.filter(
        url => !visited.map(({ url }) => url).includes(url)
      );
      return runAfterWithoutVisited.length === 0;
    }
  );

  const firstColumn = partition(
    nodesForCurrentColumn,
    ext => ext.runBefore.length > 0
  ).flat();

  if (!firstColumn.length && nodesForOtherColumns.length) {
    throw new Error(INVALID_INPUT_DATA_ERROR_MESSAGE);
  }

  return [
    firstColumn,
    ...(nodesForOtherColumns.length
      ? getGraphData(nodesForOtherColumns, [
          ...visited,
          ...nodesForCurrentColumn,
        ])
      : []),
  ];
};

type ExtensionsMap = Record<ExtendedHook['url'], ExtendedHook['url'][]>;

const mapExtensionDependencies = (
  extensions: Array<ExtendedHook>,
  dependenciesKey: 'runAfter' | 'runBefore'
) => {
  return extensions.reduce<ExtensionsMap>((acc, extension) => {
    const getChainedExtensions: (
      e: ExtendedHook
    ) => ExtendedHook['url'][] = e => {
      return [
        ...e[dependenciesKey],
        ...e[dependenciesKey].flatMap(url => {
          const extension = extensions.find(({ url: _url }) => _url === url);
          return extension ? getChainedExtensions(extension) : [];
        }),
      ];
    };

    return { ...acc, [extension.url]: unique(getChainedExtensions(extension)) };
  }, {});
};

export type ExtensionsRelationMap = Record<
  ExtendedHook['url'],
  Record<'parents' | 'children' | 'mergedRelations', ExtendedHook['url'][]>
>;

/**
 *
 * Map of parents and children for each extension.
 * Used for highlighting of whole graph for each extension.
 *
 */
export const mergeParentsAndChildren = (extensions: Array<ExtendedHook>) => {
  const mappedParents = mapExtensionDependencies(extensions, 'runAfter');
  const mappedChildren = mapExtensionDependencies(extensions, 'runBefore');

  return extensions.reduce<ExtensionsRelationMap>((acc, extension) => {
    const extensionUrl = extension.url;

    return {
      ...acc,
      [extensionUrl]: {
        parents: mappedParents[extensionUrl],
        children: mappedChildren[extensionUrl],
        mergedRelations: [
          ...mappedParents[extensionUrl],
          ...mappedChildren[extensionUrl],
        ],
      },
    };
  }, {});
};

export const getQueueHooksQuery = (queueId?: number): HooksListQuery => ({
  pageSize: 100,
  queue: queueId,
  active: true,
});
