import { Queue } from '@rossum/api-client/queues';
import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { RuiSectionTitle } from '@rossum/rossum-ui/RuiSectionTitle';
import { Box, Paper, Stack, styled, Typography } from '@rossum/ui/material';
import { isEqual } from 'lodash';
import AlertIcon from 'mdi-react/AlertIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import HiddingButton from '../../../../components/UI/HiddingButton';
import {
  ChildrenOrFormattedMessage,
  childrenOrFormattedMessage,
} from '../../../../components/UI/utils/childrenOrFormattedMessage';
import { devHub, helpCenter } from '../../../../constants/values';
import { QueueSettingsBreadcrumbs } from '../../../../features/queue-settings/components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../../../../features/queue-settings/components/QueueSettingsTabs';
import { boldText, linebreak, link } from '../../../../lib/formaterValues';
import { updateQueueDetail } from '../../../../redux/modules/queues/actions';
import { isGenericEngine } from '../../../../redux/modules/queues/helpers';
import { updateSchemaContent } from '../../../../redux/modules/schema/actions';
import { originalSchemaSelector } from '../../../../redux/modules/schema/selectors';
import { Header } from '../../../../ui/header/Header';
import PaperBox from '../../../../ui/paper-box/PaperBox';
import AutomationDataTable from './AutomationDataTable';
import AutomationLevels from './AutomationLevels';
import DefaultScoreThreshold, {
  clampThresholdValue,
  getRatio,
} from './DefaultScoreThreshold';
import styles from './style.module.sass';

type InfoBannerProps = { icon: ReactNode } & ChildrenOrFormattedMessage;

const InfoBanner = (props: InfoBannerProps) => (
  <Stack component={Paper} elevation={4}>
    <Stack direction="row" m={2}>
      <div className={styles.InfoBannerIcon}>{props.icon}</div>
      <div className={styles.InfoBannerMessage}>
        {childrenOrFormattedMessage(props)}
      </div>
    </Stack>
  </Stack>
);

const GenericEngineWarning = ({ value }: { value: number }) => (
  <InfoBanner
    icon={<AlertIcon size={20} className={styles.ColorError} />}
    id="containers.settings.automation.genericEngineWarning"
    values={{
      linebreak,
      boldText,
      value,
      link: link(
        helpCenter('/article/do-you-need-generic-or-dedicated-ai-engine/')
      ),
    }}
  />
);

const AutomationInfoBanner = () => (
  <InfoBanner icon={<InformationOutlineIcon size={20} />}>
    <FormattedMessage id="containers.settings.automation.infoBanner.message" />

    <div className={styles.LinkList}>
      <ExternalLink
        href={helpCenter('/article/making-fields-required-validation-screen/')}
        id="containers.settings.automation.infoBanner.makeHeaderFieldsRequired"
      />
      <ExternalLink
        href={helpCenter('/article/making-fields-required-validation-screen/')}
        id="containers.settings.automation.infoBanner.makeLineItemsRequired"
      />
      <ExternalLink
        href={devHub(
          'docs/using-ai-confidence-thresholds-for-automation-in-rossum'
        )}
        id="containers.settings.automation.infoBanner.scoreThresholdForSeparateFields"
      />
      <ExternalLink
        href={devHub('docs/automation-built-in-checks')}
        id="containers.settings.automation.infoBanner.builtInChecks"
      />
      <ExternalLink
        href={devHub('docs/history-based-data-checks')}
        id="containers.settings.automation.infoBanner.historyBasedDataChecks"
      />
      <ExternalLink
        href={devHub(
          'docs/implementing-custom-checks-for-automation-in-rossum'
        )}
        id="containers.settings.automation.infoBanner.customAutomationChecks"
      />
      <ExternalLink
        href={devHub('docs/automation-rules-overview')}
        id="containers.settings.automation.infoBanner.possibleExceptions"
      />
    </div>
  </InfoBanner>
);

const SecondaryText = (props: ChildrenOrFormattedMessage) => (
  <Typography variant="body2" color="text.secondary" paragraph>
    {childrenOrFormattedMessage(props)}
  </Typography>
);

const Li = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ExternalLink = (props: { href: string } & ChildrenOrFormattedMessage) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {childrenOrFormattedMessage(props)}
  </a>
);

type Props = {
  selectedQueue: Queue;
  parentPath: string;
};

export const AutomationPage = ({ selectedQueue, parentPath }: Props) => {
  const dispatch = useDispatch();

  const originalSchema = useSelector(originalSchemaSelector);
  const [updatedSchemaTree, setUpdatedSchemaTree] = useState(
    () => originalSchema ?? []
  );

  useEffect(() => {
    setUpdatedSchemaTree(originalSchema ?? []);
  }, [originalSchema]);

  const defaultValues = {
    automationLevel: selectedQueue.automationLevel,
    defaultScoreThreshold: selectedQueue.defaultScoreThreshold,
  };

  const { control, handleSubmit, watch } = useForm<
    Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>
  >({
    mode: 'onSubmit',
    defaultValues,
  });

  const watchValues = watch();

  const queueSettingsChanged = !isEqual(watchValues, defaultValues);
  const schemaChanged = !isEqual(originalSchema, updatedSchemaTree);

  const noChanges = !queueSettingsChanged && !schemaChanged;

  const onSubmit = (data: Partial<Queue>) => {
    if (queueSettingsChanged) {
      dispatch(
        updateQueueDetail(selectedQueue.id, {
          ...data,
          automationEnabled: data.automationLevel !== 'never',
        })
      );
    }
    if (schemaChanged) {
      dispatch(updateSchemaContent(updatedSchemaTree, { withMessage: false }));
    }
  };

  const clampedThresholdValue = clampThresholdValue(
    watchValues.defaultScoreThreshold
  );

  const intl = useIntl();

  const history = useHistory();

  // TODO: This should probably not be copy-pasted around
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              queueName={selectedQueue.name}
              settingsPath={parentPath}
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'containers.settings.queues.queue.automation',
                  }),
                  to: `${parentPath}/automation`,
                },
              ]}
            />
          }
          tabs={
            <QueueSettingsTabs parentPath={parentPath} value="automation" />
          }
          title={intl.formatMessage({
            id: 'containers.settings.queues.queue.automation',
          })}
          description={intl.formatMessage({
            id: 'containers.settings.queues.queue.automation.description',
          })}
          onBackButtonClicked={onBackButtonClicked}
        />
      )}
    >
      <PageContainer data-page-title="automation-detail">
        <PaperBox>
          <div className={styles.WidthLimit}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={5} sx={{ marginTop: 4 }}>
                <Box component="section">
                  <RuiSectionTitle>
                    {intl.formatMessage({
                      id: 'containers.settings.automation.documentAutomation.title',
                    })}
                  </RuiSectionTitle>
                  <SecondaryText
                    id="containers.settings.automation.documentAutomation.description"
                    values={{
                      link: link(devHub('docs/data-capture-automation')),
                    }}
                  />
                  <AutomationLevels control={control} />
                </Box>
                <Box component="section">
                  <RuiSectionTitle>
                    {intl.formatMessage({
                      id: 'containers.settings.automation.fieldAutomation.title',
                    })}
                  </RuiSectionTitle>
                  <SecondaryText
                    id="containers.settings.automation.fieldAutomation.description"
                    values={{
                      link: link(
                        helpCenter('/article/fields-with-grey-or-green-ticks/')
                      ),
                    }}
                  />
                  <DefaultScoreThreshold
                    control={control}
                    defaultValue={defaultValues.defaultScoreThreshold}
                  />
                  {isGenericEngine(selectedQueue) && (
                    <GenericEngineWarning
                      value={getRatio(clampedThresholdValue)}
                    />
                  )}
                </Box>
                <Box component="section">
                  <AutomationInfoBanner />
                </Box>
                <Box component="section">
                  <RuiSectionTitle>
                    {intl.formatMessage({
                      id: 'containers.settings.automation.automationData.title',
                    })}
                  </RuiSectionTitle>

                  <SecondaryText id="containers.settings.automation.automationData.description_1" />
                  <SecondaryText id="containers.settings.automation.automationData.description_2" />

                  <ol>
                    {(
                      [
                        'containers.settings.automation.automationData.li_1',
                        'containers.settings.automation.automationData.li_2',
                        'containers.settings.automation.automationData.li_3',
                      ] as const
                    ).map(key => (
                      <Li key={key}>{intl.formatMessage({ id: key })}</Li>
                    ))}
                  </ol>
                </Box>
                <AutomationDataTable
                  queueId={selectedQueue.id}
                  queueThreshold={clampedThresholdValue}
                  schema={updatedSchemaTree}
                  updateSchema={setUpdatedSchemaTree}
                />
              </Stack>

              <div className={styles.ButtonWrapper}>
                <HiddingButton
                  hideCondition={noChanges}
                  messageId="containers.settings.automation.save"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </PaperBox>
      </PageContainer>
    </PageLayoutV2>
  );
};
