import { Warning } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { isArray } from 'remeda';

type ErrorMessagesProps = {
  errors: unknown;
};

const ErrorMessages = ({ errors }: ErrorMessagesProps) => {
  if (!isArray(errors) || errors.length === 0) {
    return null;
  }
  return (
    <Stack spacing={1}>
      {errors.map(error =>
        typeof error === 'string' ? (
          <Stack key={error} direction="row" spacing={2}>
            <Warning color="error" fontSize="small" />
            <Typography key={error} variant="body2" color="error">
              {error}
            </Typography>
          </Stack>
        ) : null
      )}
    </Stack>
  );
};

export default ErrorMessages;
