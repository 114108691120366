import { Queue } from '@rossum/api-client/queues';
import { get, isFinite } from 'lodash';
import { ReactNode, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import ContentWrapper from '../../components/ContentWrapper';
import PageLayout from '../../components/PageLayout';
import Scrollable from '../../components/Scrollable';
import { LocalizationKeys } from '../../i18n';
import { selectedExtensionSelector } from '../../redux/modules/extensions/selectors';
import { currentQueueFromPathnameSelector } from '../../redux/modules/queues/selector';
import { Extension } from '../../types/extensions';
import { State } from '../../types/state';
import ExtensionsFooter from '../Extensions/ExtensionsFooter';
import Sidebar from './containers/Sidebar';
import SidebarItems from './containers/SidebarItems';

type StateProps = {
  backLink: { title: LocalizationKeys; to: string } | null;
  selectedExtension?: Extension;
  selectedQueue?: Queue | null;
};

type OwnProps = RouteComponentProps & {
  children: (args: Omit<StateProps, 'backLink'>) => ReactNode;
};

type Props = OwnProps & StateProps;

const Settings = ({
  location,
  selectedExtension,
  selectedQueue,
  children,
  match,
  backLink,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const parsedSearch = new URLSearchParams(location.search);
  const page = parsedSearch.get('page');
  const view = parsedSearch.get('view');

  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  return (
    <PageLayout>
      <Sidebar backLink={backLink}>
        <Route
          component={() => <SidebarItems section="extensions" />}
          exact
          path={[
            `${match.path}/store`,
            `${match.path}/extensions`,
            `${match.path}/extensions/create`,
            `${match.path}/extensions/:id`,
            `${match.path}/extensions/logs`,
          ]}
        />
      </Sidebar>
      <ContentWrapper>
        <Scrollable ref={scrollRef}>
          {children({ selectedQueue, selectedExtension })}
        </Scrollable>
        <Route
          exact
          path={`${match.path}/extensions`}
          component={view === 'graph' ? undefined : ExtensionsFooter}
        />
      </ContentWrapper>
    </PageLayout>
  );
};

const mapStateToProps = (state: State, { location }: OwnProps): StateProps => {
  const splitPathname = location.pathname.split('/');
  const currentRoute = splitPathname[2];
  const isDetailRoute =
    isFinite(Number(splitPathname[3])) || isFinite(Number(splitPathname[4]));

  const selectedQueue = currentQueueFromPathnameSelector(state);
  const selectedExtension = selectedExtensionSelector(state);
  const backLink = get(
    location,
    'state.backLink',
    splitPathname.slice(0, 3).join('/')
  );

  return {
    backLink:
      isDetailRoute && currentRoute === 'queues'
        ? {
            title:
              `containers.settings.sidebar.title.${currentRoute}Detail` as const,
            to: backLink,
          }
        : null,
    selectedExtension,
    selectedQueue,
  };
};

export default connect<StateProps, null, OwnProps, State>(mapStateToProps)(
  Settings
);
