import { isSameMonth } from 'date-fns';
import humanizeDuration, { Unit } from 'humanize-duration';
import { first, includes, last, memoize } from 'lodash';
import { isTruthy, pickBy } from 'remeda';
import { baseFormat, convertToUTC } from '../components/Datepicker/helpers';
import { tickCount } from '../components/Graphs/constants';
import { GranularityT } from '../containers/Statistics/containers/Granularity';
import { humanizeDurationLocaleMap } from '../i18n';
import { fallbackLocale } from '../i18n/config';
import { DateFnsLocale,dateFnsLocales } from '../i18n/dateLocales';

export const UTCtoLocalTime = (utc: string | undefined | null) =>
  utc?.length ? new Date(utc) : null;

export const getDurationUnit = (avgValue: number) => {
  const sampleTime = humanizeDuration(avgValue * (1000 / tickCount), {
    largest: 1,
  });
  const unitName = last(sampleTime.split(' '));
  return includes(unitName, 'month') ? 'mo' : (first(unitName) as Unit);
};

export const limitUnits = (unit: Unit) => {
  const units: Array<Unit> = ['y', 'mo', 'w', 'd', 'h', 'm', 's'];
  const unitIndex = units.findIndex(_unit => _unit === unit);

  return units.slice(unitIndex);
};

export const shortenTimes =
  (language: string, unit?: Unit) =>
  (time = 0) => {
    const humanize = humanizeDuration.humanizer({
      language,
      fallbacks: [humanizeDurationLocaleMap[language], fallbackLocale],
      languages: {
        cs: {
          y: () => 'r',
          mo: () => 'měs',
          w: () => 'týd',
          d: () => 'dní',
          h: () => 'hod',
          m: () => 'min',
          s: () => 's',
        },
        en: {
          y: () => 'y',
          mo: () => 'mo',
          w: () => 'w',
          d: () => 'd',
          h: () => 'h',
          m: () => 'm',
          s: () => 's',
        },
      },
    });

    return humanize(
      time * 1000,
      pickBy(
        { largest: 1, units: unit ? [unit] : undefined, round: true },
        isTruthy
      )
    );
  };

type GranularityFormats = Partial<
  Record<
    | 'day'
    | 'weekDate1'
    | 'weekDate1SameMonth'
    | 'weekDate1DiffMonth'
    | 'weekDate2'
    | 'weekDate2SameMonth'
    | 'weekDate2DiffMonth'
    | 'month',
    string
  >
>;

const customGranularityFormats: Record<string, GranularityFormats> = {
  ja: {
    day: 'MMM do',
    weekDate1: 'MMM do',
    weekDate2SameMonth: 'do',
    weekDate2DiffMonth: 'MMM do',
  },
};

const defaultGranulatityFormats: GranularityFormats = {
  day: 'd MMM',
  weekDate1SameMonth: 'd',
  weekDate1DiffMonth: 'd MMM',
  weekDate2: 'd MMM',
  month: 'MMM',
};

export const dateFormatByGranularity = memoize(
  (
    lang: DateFnsLocale,
    granularity: GranularityT,
    beginDateString: string,
    endDateString: string
  ) => {
    const locale = dateFnsLocales[lang];
    const formats: GranularityFormats = {
      ...defaultGranulatityFormats,
      ...customGranularityFormats[lang],
    };

    const beginDateLocal = new Date(beginDateString);
    const endDateLocal = new Date(endDateString);

    // Display UTC time as-is instead of converting it to local timezone.
    const beginDate = convertToUTC(beginDateLocal);
    const endDate = convertToUTC(endDateLocal);

    switch (granularity) {
      case 'day':
        return baseFormat(beginDate, { locale }, formats.day);
      case 'week': {
        const sameMonth = isSameMonth(beginDate, endDate);
        const date1 = baseFormat(
          beginDate,
          { locale },
          formats?.[sameMonth ? 'weekDate1SameMonth' : 'weekDate1DiffMonth'] ??
            formats?.weekDate1
        );

        const date2 = baseFormat(
          endDate,
          { locale },
          formats?.[sameMonth ? 'weekDate2SameMonth' : 'weekDate2DiffMonth'] ??
            formats?.weekDate2
        );
        return `${date1}–${date2}`;
      }
      case 'month':
        return baseFormat(beginDate, { locale }, formats.month);
      default:
        return '';
    }
  },
  (...args) => args.join()
);

export const defaultDateTimeFormat = 'cccc, HH:mm:ss, dd/MM/yyyy';

export const defaultTimeFormat = 'HH:mm aa';

export const defaultDateFormat = 'MMM dd';

export const dateTimeFormats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'ccc, ho:mo:so aa, yo MMM do',
  zh: 'ccc, HH:mm:ss, yyyy-MM-dd', // TODO: translation format was not checked, not sure it is correct format
  fr: 'ccc, HH:mm:ss, dd/MM/yyyy',
  de: 'ccc, HH:mm:ss, yyyy-MM-dd', // TODO: translation format was not checked, not sure it is correct format
  es: 'ccc, HH:mm:ss, dd/MM/yyyy',
  'pt-br': 'ccc, HH:mm:ss, dd/MM/yyyy', // TODO: translation format was not checked, not sure it is correct format
  enUS: 'ccc, hh:mm:ss aa, MM/dd/yyyy',
  en: 'ccc, hh:mm:ss aa, dd/MM/yyyy',
  cs: 'ccc, HH:mm:ss, dd. MM. yyyy',
};

export const dateFormats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'MMM do', // translation was checked for correctnes
};

export const timeFormats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'ho:mo aa',
  zh: 'HH:mm',
  fr: 'HH:mm',
  de: 'HH:mm',
  es: 'HH:mm',
  'pt-br': 'HH:mm',
  enUS: 'hh:mm aa',
  en: 'hh:mm aa',
  cs: 'HH:mm',
};

export const fullDateFormats: Partial<Record<DateFnsLocale, string>> = {
  ja: 'yo MMM do',
  zh: 'yyyy-MM-dd', // TODO: translation format was not checked, not sure it is correct format
  fr: 'dd/MM/yyyy',
  de: 'yyyy-MM-dd', // TODO: translation format was not checked, not sure it is correct format
  es: 'dd/MM/yyyy',
  'pt-br': 'dd/MM/yyyy', // TODO: translation format was not checked, not sure it is correct format
  enUS: 'MM/dd/yyyy',
  en: 'dd/MM/yyyy',
  cs: 'dd.MM.yyyy',
};

export const defaultFullDateFormat = 'MM/dd/yyyy';
