import { Skeleton, TableCell } from '@rossum/ui/material';
import { range } from 'remeda';
import { TableRow } from './BillingTable';

type LoadingTableProps = {
  rowsCount: number;
  columnsCount: number;
};

const LoadingTable = ({ rowsCount, columnsCount }: LoadingTableProps) => (
  <>
    {range(0, rowsCount).map(i => (
      <TableRow key={i} className="TableRowOverride TableRowPlaceholder">
        {range(0, columnsCount).map(column => (
          <TableCell key={column}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default LoadingTable;
