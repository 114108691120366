import { ListItem, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { MatchedTriggerRule } from '../../../../types/datapoints';

type MatchedCriteriaProps =
  | {
      conditionType: Exclude<MatchedTriggerRule['type'], 'datapoint'>;
      value: string | number | undefined;
    }
  | {
      conditionType: Exclude<
        MatchedTriggerRule['type'],
        'page_count' | 'filename'
      >;
      value: string | number | undefined;
      enumLabel: string | undefined;
    };

export const MatchedCriteria = (props: MatchedCriteriaProps) => {
  const intl = useIntl();
  return (
    <ListItem
      sx={{
        display: 'list-item',
        p: 0,
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        display="inline-flex"
      >
        <Typography variant="body2" color="text.primary">
          {props.conditionType === 'datapoint'
            ? props.enumLabel
            : intl.formatMessage({
                id: `components.sidebar.deleteRecommendation.ruleList.condition.${props.conditionType}`,
              })}
        </Typography>
        <Typography variant="body2" color="secondary">
          {intl.formatMessage({
            id: `components.sidebar.deleteRecommendation.ruleList.condition.comparison.${props.conditionType}`,
          })}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {props.value ?? 'N/A'}
        </Typography>
      </Stack>
    </ListItem>
  );
};
