import { Box, Chip } from '@rossum/ui/material';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellProps,
} from '@rossum/ui/x-data-grid-pro';
import { get } from 'lodash';
import { IntlShape } from 'react-intl';
import { fromEntries } from 'remeda';
import { text, totalCountAggregationColumnField } from '../constants';
import { GridColumns } from './columns/types';
import { isNonEmptyObject } from './getProcessRowUpdate';
import {
  DetailGridField,
  GridRowModel,
  GridRowModelKeys,
} from './rows/rowTypes';

type Aggregations = Record<string, Set<string>>;

export const getAggregations = (
  columns: GridColumns,
  rows: GridRowModel[]
): Aggregations =>
  rows.reduce<Aggregations>(
    (aggregatedRowFieldSets, row) =>
      columns.reduce((aggregatedColumns, column) => {
        if (column.field === totalCountAggregationColumnField) {
          return aggregatedColumns;
        }

        const flatValue =
          // @ts-expect-error colDef and column mismatch
          column.valueGetter?.({ row, field: column.field, colDef: column }) ??
          get(row, column.field) ??
          null;

        const fieldSet = aggregatedColumns[column.field];

        const stringifiedFlatValue = isNonEmptyObject(flatValue)
          ? JSON.stringify(flatValue)
          : String(flatValue);

        return fieldSet !== undefined && flatValue !== text.cellNotEditable
          ? {
              ...aggregatedColumns,
              [column.field]: fieldSet.add(stringifiedFlatValue),
            }
          : aggregatedColumns;
      }, aggregatedRowFieldSets),
    {
      ...fromEntries(columns.map(column => [column.field, new Set<string>()])),
      [totalCountAggregationColumnField]: new Set([String(rows.length)]),
    }
  );

export const formatAggregate = (
  field: DetailGridField,
  intl: IntlShape,
  aggregate: GridCellProps['value'],
  onClick: (
    field: Extract<GridRowModelKeys, 'options' | 'formula'>,
    aggregate: GridCellProps['value']
  ) => void
) => {
  if (field === GRID_CHECKBOX_SELECTION_FIELD || field === 'actions') {
    return null;
  }
  if (field === totalCountAggregationColumnField) {
    return (
      <Box>
        {`${intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.aggregations.formatAggregate.total',
        })} ${[...aggregate][0]}`}
      </Box>
    );
  }

  const fieldIsFormulaOrEnum = field === 'options' || field === 'formula';
  return aggregate && aggregate.size > 1 ? (
    <Chip
      label={intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.aggregations.formatAggregate.variants.label',
        },
        { count: aggregate.size }
      )}
      variant={fieldIsFormulaOrEnum ? 'outlined' : 'filled'}
      color={fieldIsFormulaOrEnum ? 'secondary' : 'default'}
      onClick={
        fieldIsFormulaOrEnum ? () => onClick(field, aggregate) : undefined
      }
    />
  ) : (
    <Chip
      label={intl.formatMessage({
        id: 'features.fieldManager.fieldDetail.aggregations.formatAggregate.same.label',
      })}
      variant="outlined"
      color="success"
    />
  );
};
