import { Stack } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import { AutomationBlocker } from '../../../../redux/modules/annotation/types';
import { SidebarContainer } from '../shared/SidebarContainer';
import { AnnotationBlocker } from './AnnotationBlocker';
import { DatapointBlocker } from './DatapointBlocker';

type AutomationBlockersListType = {
  automationBlockers: AutomationBlocker[];
  handleSelectDatapoint: (id: number) => void;
};

const LIST_MAX_HEIGHT = 270;

export const AutomationBlockersList = ({
  automationBlockers,
  handleSelectDatapoint,
}: AutomationBlockersListType) => {
  const intl = useIntl();

  const [annotationBlockers, datapointBlockers] = useMemo(
    () =>
      R.partition(
        automationBlockers,
        blocker => blocker.level === 'annotation'
      ),
    [automationBlockers]
  );

  return (
    <SidebarContainer
      title={intl.formatMessage({
        id: 'components.sidebarV2.automationBlockers.title',
      })}
    >
      <Stack
        sx={{
          maxHeight: LIST_MAX_HEIGHT,
          overflow: 'auto',
          py: 1,
        }}
        spacing={2}
      >
        {annotationBlockers.map(blocker => (
          <AnnotationBlocker key={blocker.type} blocker={blocker} />
        ))}
        {datapointBlockers.map(blocker => (
          <DatapointBlocker
            key={`${blocker.schemaId}-${blocker.type}`}
            blocker={blocker}
            handleSelectDatapoint={handleSelectDatapoint}
          />
        ))}
      </Stack>
    </SidebarContainer>
  );
};
