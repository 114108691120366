import { darken, lighten, Theme } from '@rossum/ui/material';
import { Severity } from './types';

export const getSeverityStyles = (severity: Severity, { palette }: Theme) => {
  const isLight = palette.mode === 'light';
  const styleFn = isLight ? lighten : darken;
  const coefficient = isLight ? 0.9 : 0.8;
  return severity === 'info'
    ? {
        backgroundColor: palette.background.paper,
        borderColor: palette.other.tooltip,
      }
    : {
        backgroundColor: styleFn(palette[severity].main, coefficient),
        borderColor: palette[severity].main,
      };
};
