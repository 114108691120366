import { DecodingError, HttpError } from '@rossum/api-client/errors';
import { Mutation, Query } from '@tanstack/react-query';
import { get } from 'lodash';
import { pipe } from 'lodash/fp';
import { push, replace } from 'redux-first-history';
import { isTruthy, omitBy, pickBy } from 'remeda';
import { AjaxError } from 'rxjs/ajax';
import { absoluteApiUrl, isEmbedded } from '../constants/config';
import { ErrorPageRouterState } from '../features/error-page';
import { logoutFromWorkflows } from '../features/workflows';
import { signOut } from '../redux/modules/auth/actions';
import { toAbsoluteUrl } from './helpers';
import { isIgnoredApiError } from './sentry';
import { clearAuthToken, getAuthToken } from './token';

export const checkStatus = (response: Response) =>
  response.status >= 200 && response.status < 300
    ? Promise.resolve(response)
    : Promise.reject(new Error(response.statusText || String(response.status)));

const inferCollection = (url: string): string => {
  const absoluteUrl = toAbsoluteUrl(url);

  try {
    const { pathname } = new URL(absoluteUrl);
    const { pathname: apiUrlPathname } = new URL(absoluteApiUrl);
    return pathname.replace(apiUrlPathname, '').replace(/\d+/g, '**');
  } catch {
    return `Invalid Url - requestUrl: ${absoluteUrl} - apiUrl: ${absoluteApiUrl}`;
  }
};

export const report = (
  error: AjaxError | { status: string },
  options?: Record<string, unknown>
) => {
  if (window.Rollbar && !isIgnoredApiError(error)) {
    const status = get(error, 'status', 'Unknown status');
    const method = get(error, 'request.method', 'Unknown method');
    const requestUrl = get(error, 'request.url');
    const responseBody = get(error, 'response.body', 'No body');
    const url = requestUrl
      ? inferCollection(requestUrl)
      : 'Unable to process response';

    const title = `${status} ${method} (${url})`;

    const payload = omitBy(
      {
        title,
        fingerprint: title,
      },
      item => item === undefined
    );
    window.Rollbar.configure({ payload });
    window.Rollbar.error(error, {
      error: { ...error },
      recent_actions: window.recent_actions || [],
      responseBody,
      ...options,
    });
  }

  if (window.Sentry) {
    window.Sentry.captureException(error);
  }

  return true;
};

const elisClientReportPayload = (error: unknown) => {
  if (error instanceof HttpError) {
    const title = `[@rossum/api-client] ${error.name}: ${error.code} ${error.endpoint}`;
    const fingerprint = `${error.name} ${
      error.code
    } ${error.endpoint.replaceAll(/\d+/g, 'X')}`;
    return { title, fingerprint };
  }

  if (error instanceof DecodingError) {
    const title = `[@rossum/api-client] ${error.name}: ${error.endpoint}`;
    const fingerprint = `${error.name} ${error.endpoint.replaceAll(
      /\d+/g,
      'X'
    )}`;
    return { title, fingerprint };
  }

  if (error instanceof Error) {
    const title = error.name;

    return { title, fingerprint: title };
  }

  const title = 'Unexpected error type';

  return { title, fingerprint: title };
};

export const reportQueryError = (error: unknown, query?: Query) => {
  if (window.Rollbar && !isIgnoredApiError(error)) {
    const { queryKey } = query || {};
    window.Rollbar.configure({
      payload: elisClientReportPayload(error),
    });

    window.Rollbar.error(error, {
      recentActions: JSON.stringify(window.recent_actions),
      queryKey,
    });
  }

  if (window.Sentry) {
    window.Sentry.captureException(error);
  }
};

// we can test this when we have a mutation
export const reportMutationError = (
  error: unknown,
  { mutation }: { mutation: Mutation<unknown, unknown, unknown, unknown> }
) => {
  if (window.Rollbar && !isIgnoredApiError(error)) {
    const {
      options: { mutationKey },
    } = mutation;

    window.Rollbar.configure({
      payload: elisClientReportPayload(error),
    });

    window.Rollbar.error(error, {
      error,
      recentActions: JSON.stringify(window.recent_actions),
      mutationKey,
    });
  }

  if (window.Sentry) {
    window.Sentry.captureException(error);
  }
};

const jsonOptions = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const rossumTrackingOrigin = {
  'X-Rossum-Request-Origin': 'web_app',
};

export const jsonWithTrackingHeaders = {
  ...jsonOptions,
  ...rossumTrackingOrigin,
};

export const getAuthorizationHeader = (overwriteToken?: string) => {
  const token = overwriteToken ?? getAuthToken();

  if (token !== null) {
    return { Authorization: `Token ${token}` } as const;
  }

  return undefined;
};

export const withAuthorization = (
  headers: Record<string, string> | undefined
): Record<string, string> => ({
  ...getAuthorizationHeader(),
  ...rossumTrackingOrigin,
  ...headers,
});

const withJSON = (header: Record<string, string>) => ({
  ...jsonOptions,
  ...header,
});

export const createAuthJSONHeaders = pipe(
  withAuthorization,
  withJSON,
  pickBy(isTruthy)
) as (headers?: Record<string, string>) => Record<string, string>;

export const handleThrottleError = (error: unknown) => {
  if (error instanceof HttpError && 'code' in error && error.code === 429) {
    const retryAfterHeader = error.headers?.['Retry-After'];

    const throttleState: ErrorPageRouterState = {
      from: window.location.pathname,
      initialCountdown: retryAfterHeader
        ? parseInt(retryAfterHeader, 10)
        : undefined,
    };

    window.store.dispatch(
      push('/error?reason=too-many-requests', throttleState)
    );
  }
};

export const handleAuthError = (error: unknown) => {
  if (
    error &&
    typeof error === 'object' &&
    'code' in error &&
    error.code === 401
  ) {
    clearAuthToken();
    logoutFromWorkflows();

    if (isEmbedded()) {
      window.store.dispatch(replace('/timeExpired'));
    } else {
      window.store.dispatch(signOut({ handled: true }));
    }
  }
};
