import { IconCustomBoltPause, IconHelp } from '@rossum/ui/icons/tabler';
import {
  Alert,
  AlertProps,
  Button,
  Grow,
  IconButton,
  Link,
  Stack,
  SvgIcon,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import { isMessageDetailVisible } from '../../../../../components/DatapointMessage/helpers';
import HTMLMessage from '../../../../../components/DatapointMessage/HTMLMessage';
import MessageDetailDialog from '../../../../../containers/Sidebar/components/MessageDetailDialog';
import {
  BLOCKERS_COLOR,
  MessageOrBlocker,
} from '../../automation-blockers/helpers';

type MessagesProps = {
  messages: Array<MessageOrBlocker>;
  alertVariant?: AlertProps['variant'];
};

const MESSAGE_STEP_SIZE = 3;
const DEFAULT_MESSAGES_COUNT = MESSAGE_STEP_SIZE;

const LIST_MAX_HEIGHT = 270;

export const Messages = ({
  messages,
  alertVariant = 'filled',
}: MessagesProps) => {
  const intl = useIntl();
  const [numberOfVisible, setNumberOfVisible] = useState(
    DEFAULT_MESSAGES_COUNT
  );
  const [visibleMessages, restOfMessages] = R.partition(
    messages,
    (_, index) => index < numberOfVisible
  );

  const [messageDetail, setMessageDetail] = useState<{
    detail: NonNullable<MessageOrBlocker['detail']>;
    content: MessageOrBlocker['content'];
    id: MessageOrBlocker['id'];
  } | null>(null);

  return (
    <Stack
      spacing={1}
      sx={{
        maxHeight: LIST_MAX_HEIGHT,
        overflow: 'auto',
      }}
    >
      <Grow in={visibleMessages.length > 0} unmountOnExit>
        <Stack spacing={1}>
          {visibleMessages.map(({ detail, type, content, docsLink, id }) => {
            const detailAvailable = isMessageDetailVisible(detail, type);
            return (
              <Alert
                // TODO: @sidebarV2 what should we use for keys? messages can have the same content..., add uuids when fetching?
                key={content}
                variant={alertVariant}
                severity={type === 'blocker' ? BLOCKERS_COLOR : type}
                icon={
                  type === 'blocker' ? (
                    <SvgIcon
                      component={IconCustomBoltPause}
                      fontSize="inherit"
                    />
                  ) : undefined
                }
                action={
                  docsLink ? (
                    <IconButton
                      component={Link}
                      href={docsLink}
                      target="_blank"
                      rel="noreferrer noopener"
                      color="inherit"
                      size="small"
                      sx={{ '&:hover': { color: 'inherit' } }}
                    >
                      <SvgIcon
                        component={IconHelp}
                        fontSize="inherit"
                        sx={{ fill: 'none' }}
                      />
                    </IconButton>
                  ) : undefined
                }
              >
                <HTMLMessage content={content} />
                {detailAvailable && (
                  <Button
                    variant="text"
                    color="inherit"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontWeight: 'inherit',
                      mt: 1,
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() => {
                      setMessageDetail({
                        content,
                        detail,
                        id,
                      });
                    }}
                  >
                    {intl.formatMessage({
                      id: 'components.documentValidation.sidebar.exceptionalError.moreDetails',
                    })}
                  </Button>
                )}
              </Alert>
            );
          })}
        </Stack>
      </Grow>
      <Stack
        justifyContent="flex-end"
        direction="row"
        spacing={1}
        sx={{ '&:empty': { display: 'none' } }}
      >
        {numberOfVisible > DEFAULT_MESSAGES_COUNT && (
          <Button
            variant="outlined"
            onClick={() => setNumberOfVisible(DEFAULT_MESSAGES_COUNT)}
            color="secondary"
            sx={{ alignSelf: 'flex-end' }}
          >
            {intl.formatMessage({
              id: 'components.sidebarV2.messages.hide',
            })}
          </Button>
        )}
        {messages.length > DEFAULT_MESSAGES_COUNT && (
          <Button
            variant="outlined"
            onClick={() =>
              setNumberOfVisible(prevCount => prevCount + MESSAGE_STEP_SIZE)
            }
            color="secondary"
            sx={{ alignSelf: 'flex-end' }}
            disabled={restOfMessages.length === 0}
          >
            {intl.formatMessage(
              {
                id: 'components.sidebarV2.messages.loadMore',
              },
              { count: restOfMessages.length }
            )}
          </Button>
        )}
      </Stack>
      {messageDetail && (
        <MessageDetailDialog
          open
          messageDetail={messageDetail.detail}
          onClose={() => setMessageDetail(null)}
          content={messageDetail.content}
          datapointId={messageDetail.id}
        />
      )}
    </Stack>
  );
};
