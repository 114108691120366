import {
  IconAlignLeft,
  IconCode,
  IconHelpHexagon,
} from '@rossum/ui/icons/tabler';
import { IconButton, Menu, Stack, SvgIcon } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuLinkItem } from '../../../../../ui/menu-link-item/MenuLinkItem';
import { EditorType } from '../InfoMessage';
import { documentationLinksConfig } from './documentationLinksConfig';

const icons = {
  textSubject: IconAlignLeft,
  codeTags: IconCode,
};

type Props = { editorType: EditorType };

const DocumentationLinks = ({ editorType }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();

  const isOpen = !!anchorEl;

  const links =
    editorType === 'function'
      ? documentationLinksConfig.codeEditor.map(d => ({
          ...d,
          localizationKey:
            `components.editor.documentationLinks.${editorType}.${d.translation}` as const,
        }))
      : documentationLinksConfig.schemaEditor.map(d => ({
          ...d,
          localizationKey:
            `components.editor.documentationLinks.${editorType}.${d.translation}` as const,
        }));

  return (
    <Stack>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {links.map(option => (
          <MenuLinkItem
            key={`editor-docs-links-${option.translation}`}
            href={option.link}
            Icon={icons[option.icon]}
            label={intl.formatMessage({ id: option.localizationKey })}
            rel="noopener noreferrer"
          />
        ))}
      </Menu>
      <IconButton
        data-cy="documentation-links-button"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <SvgIcon sx={{ fill: 'none' }} component={IconHelpHexagon} />
      </IconButton>
    </Stack>
  );
};

export default DocumentationLinks;
