import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalTitle } from '@rossum/rossum-ui/Modal';
import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import { Add } from '@rossum/ui/icons';
import { Button, Stack } from '@rossum/ui/material';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import ValidationInput from '../../../../components/ReactHookForm/ValidationInput';
import { emailDomainName } from '../../../../constants/config';
import { modalWidth } from '../../../../constants/values';
import { clearValidationsOnChangeCreator } from '../../../../lib/messages';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import styles from './styles.module.sass';

const createInboxValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    emailPrefix: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'containers.settings.queues.email.messages.emailPrefix.required',
        })
      )
      .matches(
        /^\S*$/,
        intl.formatMessage({
          id: 'containers.settings.queues.email.messages.emailPrefix.noSpaces',
        })
      ),
  });

type Props = {
  clearValidationMessages: typeof clearValidationMessagesAction;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (_emailPrefixAttrubutes: { emailPrefix: string }) => void;
  validationMessages: InputMessages;
};

const CreateInboxModal = ({
  clearValidationMessages,
  isOpen,
  onCancel,
  onSubmit,
  validationMessages,
}: Props) => {
  const intl = useIntl();

  const { reset, handleSubmit, control } = useForm({
    mode: 'onTouched',
    defaultValues: {
      emailPrefix: '',
    },
    resolver: yupResolver(createInboxValidationSchema(intl)),
  });

  useEffect(() => {
    if (!isOpen) {
      reset({ emailPrefix: '' });
    }
  }, [reset, isOpen]);

  const clearValidationsOnChange = clearValidationsOnChangeCreator(
    clearValidationMessages,
    'inbox',
    validationMessages
  );

  return (
    <Modal isOpen={isOpen} width={modalWidth} exitAnimation="ZoomOut">
      <ModalTitle
        text={intl.formatMessage({
          id: 'containers.settings.queues.email.noInbox.create',
        })}
        onCancel={onCancel}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.Field}>
          <div className={styles.Label}>
            {intl.formatMessage({
              id: 'containers.settings.queues.email.emailPrefix',
            })}
            <Tooltip
              innerPopperClassName={styles.Tooltip}
              content={intl.formatMessage(
                {
                  id: 'containers.settings.queues.email.emailPrefix.tooltip',
                },
                { domainName: emailDomainName }
              )}
            >
              <InfoIcon size={20} />
            </Tooltip>
          </div>
          <ValidationInput
            clearValidationsOnChange={clearValidationsOnChange('emailPrefix')}
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.email.emailPrefix.placeholder',
            })}
            control={control}
            getErrorMessage={(_, { message }) => message ?? ''}
            name="emailPrefix"
          />
        </div>
        <Stack
          direction="row-reverse"
          spacing={2}
          justifyContent="flex-start"
          sx={{ my: 2, mx: 4 }}
        >
          <Button variant="contained" startIcon={<Add />} type="submit">
            {intl.formatMessage({
              id: 'containers.settings.queues.email.noInbox.create',
            })}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onCancel()}
          >
            {intl.formatMessage({ id: 'components.modal.cancel' })}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default CreateInboxModal;
