// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const schemaActionSchema = z.object({
  type: z.union([
    z.literal('add_automation_blocker'),
    z.literal('show_message'),
    z.literal('send_email'),
    z.literal('hide_field'),
    z.literal('change_queue'),
    z.literal('show_field'),
    z.literal('change_status'),
    z.literal('add_label'),
    z.literal('remove_label'),
    z.string(),
  ]),
  payload: z.unknown().optional(),
});

export const schemaRuleActionSchema = z.object({
  id: z.string(),
  enabled: z.boolean(),
  name: z.string().optional(),
  description: z.string().optional(),
  action: schemaActionSchema.optional(),
});

export const schemaRuleSchema = z.object({
  id: idSchema,
  enabled: z.boolean(),
  name: z.string(),
  description: z.string(),
  schema: urlSchema,
  triggerCondition: z.string(),
  triggerConditionSummary: z.string().optional(),
  ruleActions: z.array(schemaRuleActionSchema),
});
