import { Queue } from '@rossum/api-client/queues';
import { includes } from 'lodash';
import { getIdFromUserPath } from '../../../containers/Users/helpers';
import createSelector from '../../../lib/createDeepEqualSelector';
import { Groups } from '../../../types/group';
import { State } from '../../../types/state';
import { User } from '../../../types/user';
import { Workspace } from '../../../types/workspace';
import { getRoleName, isUserAdminLike } from '../groups/helpers';

const groupsSelector = (state: State) => state.groups;
export const usersSelector = (state: State) => state.users.list;
const queuesSelector = (state: State) => state.queues.list;
const workspacesSelector = (state: State) => state.workspaces.list;
const pathnameSelector = (state: State) => state.router.location.pathname;

const userWithCounts = (
  workspaces: Workspace[],
  groups: Groups,
  queues: Queue[],
  user: User
) => {
  const role = getRoleName(user.groups, groups);
  const isAdmin = isUserAdminLike(role);

  const workspacesCount = isAdmin
    ? workspaces.length
    : workspaces.filter(({ queues: _queues }) =>
        user.queues.some(queue => includes(_queues, queue))
      ).length;

  const queuesCount = isAdmin ? queues.length : user.queues.length;

  return { ...user, workspacesCount, queuesCount, role };
};

const propsUserSelector = (_: unknown, props: { user: User }) => props.user;

export const userWithCountsSelector = (props: { user: User }) =>
  createSelector(
    workspacesSelector,
    groupsSelector,
    queuesSelector,
    state => propsUserSelector(state, props),
    userWithCounts
  );

export const usersWithCountsSelector = createSelector(
  usersSelector,
  workspacesSelector,
  groupsSelector,
  queuesSelector,
  (users, workspaces, groups, queues) =>
    users.map(user => userWithCounts(workspaces, groups, queues, user))
);

export const selectedUserSelector = createSelector(
  usersSelector,
  pathnameSelector,
  groupsSelector,
  (users, pathname, groups) => {
    const userId = getIdFromUserPath(pathname);
    const user = users.find(({ id }) => id === userId);

    if (user && groups.length) {
      const role = getRoleName(user.groups, groups);
      return { ...user, role };
    }
    return undefined;
  }
);
