import { IconAlertTriangleFilled } from '@rossum/ui/icons/tabler';
import {
  Box,
  MenuItem,
  Select,
  selectClasses,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { includes } from 'lodash';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { linebreak } from '../../../../../lib/formaterValues/index';
import { EventNameOptions } from '../types';

type Props = {
  assignedFunctionEventNames: Array<EventNameOptions>;
  events: Array<EventNameOptions>;
  currentEvent: EventNameOptions;
  disabled: boolean;
  savedInput: string;
  setCurrentEvent: (event: EventNameOptions) => void;
};

const FunctionEventsDropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      assignedFunctionEventNames,
      events,
      currentEvent,
      disabled,
      savedInput,
      setCurrentEvent,
    },
    ref
  ) => {
    const intl = useIntl();

    const getFunctionEvent = (event: EventNameOptions) => {
      const shouldDisplayWarning =
        !includes(assignedFunctionEventNames, event) &&
        event !== 'mySavedInput';

      if (event === 'mySavedInput' && !savedInput) {
        return (
          <Tooltip
            title={intl.formatMessage(
              {
                id: 'components.editor.inputBox.dropdown.noSavedInput',
              },
              { linebreak }
            )}
            placement="right"
            arrow
          >
            <Box ref={ref} width={1} sx={{ cursor: 'not-allowed' }}>
              <Typography variant="body2" color="text.disabled">
                {intl.formatMessage({
                  id: `components.editor.inputBox.dropdown.${event}`,
                })}
              </Typography>
            </Box>
          </Tooltip>
        );
      }

      if (shouldDisplayWarning) {
        return (
          <Tooltip
            title={intl.formatMessage(
              {
                id: 'components.editor.inputBox.dropdown.eventNotAssigned',
              },
              { linebreak }
            )}
            placement="right"
            arrow
          >
            <Stack
              ref={ref}
              width={1}
              direction="row"
              alignItems="center"
              gap={1}
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: `components.editor.inputBox.dropdown.${event}`,
                })}
              </Typography>
              <SvgIcon
                sx={{ color: t => t.palette.text.disabled }}
                component={IconAlertTriangleFilled}
                fontSize="small"
              />
            </Stack>
          </Tooltip>
        );
      }

      return (
        <Typography variant="body2">
          {intl.formatMessage({
            id: `components.editor.inputBox.dropdown.${event}`,
          })}
        </Typography>
      );
    };

    return (
      <Stack>
        <Select
          size="small"
          fullWidth
          value={currentEvent}
          onChange={e => {
            const event = e.target.value as EventNameOptions;

            if (
              event &&
              !disabled &&
              currentEvent !== event &&
              event !== 'mySavedInput'
            ) {
              setCurrentEvent(event);
            }
          }}
          renderValue={value => (
            <Box>
              {intl.formatMessage({
                id: `components.editor.inputBox.dropdown.${value}`,
              })}
            </Box>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          sx={{
            borderRadius: 4,
            backgroundColor: t => t.palette.background.paper,
            fontSize: t => t.typography.caption.fontSize,
            [`.${selectClasses.select}`]: {
              p: 0.5,
              pl: 1.5,
            },
          }}
        >
          {events.map(event => (
            <MenuItem key={event} value={event}>
              {getFunctionEvent(event)}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    );
  }
);

export default FunctionEventsDropdown;
