import { Modifiers } from '@rossum/rossum-ui/PopperInPortal';
import { Grow } from '@rossum/ui/material';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import styles from './styles.module.sass';

export type ItemPropsT = {
  open?: boolean;
  className: string;
};

type ItemFunction = (_props: ItemPropsT) => ReactNode;

type Props = {
  align?: 'right' | 'left' | 'center';
  children: ReactNode | ((params: { open: boolean }) => ReactNode);
  className?: string;
  dataCy?: string;
  inPortal?: boolean;
  inDrawer?: boolean;
  items: Array<ItemFunction>;
  listClassName?: string;
  onBlur?: () => void;
  openOnHover?: boolean;
  keepOpen?: boolean;
  position?: 'up' | 'down';
  withIcons?: boolean;
  modifiers?: Modifiers;
  hiddenTabs?: boolean; // TODO: Remove this flag with unifing all variants of component
};

const Dropdown = ({
  align = 'right',
  children,
  dataCy,
  inPortal,
  inDrawer,
  items,
  listClassName = '',
  onBlur,
  openOnHover = false,
  keepOpen,
  position = 'down',
  withIcons = true,
  modifiers = [],
  hiddenTabs,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);
  const [touched, setTouched] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const portalContainerRef = useRef<HTMLDivElement | null>(null);

  const root = inDrawer ? document.body : document.getElementById('root')!;

  useEffect(() => {
    const onBlurSubscription = fromEvent(
      // this is where React 17 is delegating events instead of 'document'
      root,
      'click'
    )
      .pipe(
        filter(() => open),
        filter(
          e =>
            !keepOpen ||
            !(inPortal ? portalContainerRef : containerRef).current?.contains(
              e.target as Node
            )
        )
      )
      .subscribe(() => setOpen(false));
    return () => onBlurSubscription.unsubscribe();
  }, [inPortal, keepOpen, open, root]);

  useEffect(() => {
    if (touched && onBlur && !open) {
      onBlur();
    }
  }, [onBlur, open, touched]);

  return (
    <Manager>
      <>
        <Reference>
          {({ ref }) => (
            <div
              {...props}
              data-cy={dataCy}
              className={clsx(styles.DropdownWrapper, props.className)}
              ref={ref}
            >
              <div ref={containerRef}>
                <div
                  onClick={() => {
                    setTouched(true);
                    setOpen(!open);
                  }}
                  className={styles.DropdownToggle}
                  onMouseEnter={() => {
                    if (openOnHover) setOpen(true);
                  }}
                >
                  {isFunction(children) ? children({ open }) : children}
                </div>
                {!inPortal && open && (
                  <div
                    className={clsx(
                      styles.DropdownItemsInParent,
                      styles.DropdownItems,
                      position === 'up' && styles.DropUpItems,
                      styles[`DropdownAlign-${align}`],
                      open && styles.DropdownItemsShown,
                      withIcons && styles.DropdownItemsSvg,
                      hiddenTabs && styles.DropdownItemsHiddenTabs,
                      listClassName
                    )}
                  >
                    <Grow
                      style={{
                        transformOrigin: `${
                          position === 'up' ? 'bottom' : 'top'
                        } center 0`,
                      }}
                      in={open}
                      appear
                      unmountOnExit
                    >
                      <div>
                        {items.map(item =>
                          item({ open, className: styles.DropdownItem })
                        )}
                      </div>
                    </Grow>
                  </div>
                )}
              </div>
            </div>
          )}
        </Reference>
        {open &&
          inPortal &&
          ReactDOM.createPortal(
            <div ref={portalContainerRef}>
              <Popper
                placement="bottom-start"
                modifiers={[
                  {
                    name: 'flip',
                    enabled: false,
                  },
                  { name: 'hide', enabled: true },
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      padding: 0,
                    },
                  },
                  ...(modifiers || []),
                ]}
              >
                {({ style, ref, isReferenceHidden }) => (
                  <div
                    ref={ref}
                    style={{ ...style, opacity: isReferenceHidden ? 0 : 1 }}
                    className={clsx(
                      styles.DropdownItems,
                      withIcons && styles.DropdownItemsSvg,
                      listClassName
                    )}
                  >
                    {items.map(item =>
                      item({ open, className: styles.DropdownItem })
                    )}
                  </div>
                )}
              </Popper>
              ,
            </div>,
            root
          )}
      </>
    </Manager>
  );
};

export default Dropdown;
