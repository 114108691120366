import { IconEdit } from '@rossum/ui/icons/tabler';
import {
  Button,
  Chip,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { settingsPath } from '../../../containers/Settings/helpers';
import { ItemTile, TILE_HEIGHT } from '../../../ui/item-tile/ItemTile';
import { ItemTileDescription } from '../../../ui/item-tile/ItemTileDescription';
import { EmptyList } from '../components/EmptyList';
import { TaskTypes, useDatasetContext } from '../context';
import { DatasetsHeader } from '../header';
import { useGetDatasets } from '../hooks/useGetDatasets';
import { datasetCreateRoute, datasetTablePath } from '../routes';
import { pageSpacingStyles } from '../styles';
import { transformNameIntoId } from '../utils';

const iconMap: (intl: IntlShape) => Record<TaskTypes, JSX.Element> = intl => ({
  delete: (
    <Chip
      label={intl.formatMessage({
        id: 'features.datasets.list.tileStatus.delete',
      })}
      size="small"
      variant="outlined"
      color="warning"
    />
  ),
  update: (
    <Chip
      label={intl.formatMessage({
        id: 'features.datasets.list.tileStatus.update',
      })}
      size="small"
      variant="outlined"
    />
  ),
  create: (
    <Chip
      label={intl.formatMessage({
        id: 'features.datasets.list.tileStatus.create',
      })}
      size="small"
      variant="outlined"
    />
  ),
});

export const DatasetList = () => {
  const intl = useIntl();
  const history = useHistory();
  const { datasetTasks } = useDatasetContext();

  const { data, isLoading } = useGetDatasets();

  const datasets = useMemo(() => {
    if (!data) return [];

    const taskMap = new Map(datasetTasks.map(task => [task.datasetName, task]));
    const normalized = data.map(item => {
      const existingTask = taskMap.get(item.dataset_name);
      return { ...item, taskProcess: existingTask?.type ?? ('none' as const) };
    });

    // when data set is queued to be created its not in the response of useGetDatasets.
    // so we add it here to be able to show it in the dataset list
    return datasetTasks.reduce(
      (acc, task) =>
        task.type === 'create' &&
        normalized.every(dataset => dataset.dataset_name !== task.datasetName)
          ? acc.concat({
              taskProcess: 'create',
              dataset_name: task.datasetName,
              status: 'IN_PROGRESS',
            })
          : acc,
      normalized
    );
  }, [data, datasetTasks]);

  return (
    <PageLayoutV2
      renderHeader={props => (
        <DatasetsHeader
          {...props}
          breadcrumbs={[]}
          title={intl.formatMessage({
            id: 'features.datasets.list.header.title',
          })}
          description={intl.formatMessage({
            id: 'features.datasets.list.header.text',
          })}
          onBackButtonClicked={() => history.push(settingsPath())}
          buttons={[
            <Button
              key="add-dataset-button"
              data-cy="add-dataset-button"
              variant="contained"
              component={Link}
              to={datasetCreateRoute()}
            >
              {intl.formatMessage({
                id: 'features.datasets.list.header.button.addDataset',
              })}
            </Button>,
          ]}
        />
      )}
    >
      <Stack gap={0.5} sx={pageSpacingStyles}>
        {data?.length === 0 ? (
          <EmptyList />
        ) : isLoading ? (
          <DatasetSkeletons />
        ) : (
          datasets.map(item => {
            const datasetPathId = transformNameIntoId(item.dataset_name);
            return datasetPathId ? (
              <ItemTile
                key={v4()}
                to={datasetTablePath(datasetPathId)}
                sx={
                  item.taskProcess === 'create' || item.taskProcess === 'delete'
                    ? { pointerEvents: 'none', color: 'text.disabled' }
                    : {}
                }
              >
                <ItemTileDescription
                  label={
                    <Stack direction="row" gap={1}>
                      <SvgIcon>
                        <IconEdit />
                      </SvgIcon>
                      <Typography
                        variant="h6"
                        sx={{ minWidth: 0 }}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {item.dataset_name}
                      </Typography>
                      {item.taskProcess !== 'none'
                        ? iconMap(intl)[item.taskProcess]
                        : null}
                    </Stack>
                  }
                  description=""
                  to={datasetTablePath(datasetPathId)}
                  isFaded={
                    item.taskProcess === 'create' ||
                    item.taskProcess === 'delete'
                  }
                />
              </ItemTile>
            ) : null;
          })
        )}
      </Stack>
    </PageLayoutV2>
  );
};

const DatasetSkeletons = () => (
  <>
    {Array.from({ length: 10 }, (_, i) => i).map(id => (
      <Stack key={id} height={TILE_HEIGHT} component={Paper} py={1} px={4}>
        <Stack direction="row" gap={1}>
          <Skeleton variant="circular" height={30} width={30} />
          <Stack gap={0.5}>
            <Skeleton variant="rectangular" height={15} width={150} />
            <Skeleton variant="rectangular" height={15} width={50} />
          </Stack>
        </Stack>
      </Stack>
    ))}
  </>
);
