import {
  isSchemaDatapoint,
  isSchemaDatapointButton,
  isSchemaDatapointEnum,
  SchemaUiConfigEdit,
  SchemaUiConfigType,
} from '@rossum/api-client/schemas';
import { Message } from '@rossum/api-client/shared';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { asMutable } from 'seamless-immutable';
import { datapointBlockerSelector } from '../../../redux/modules/annotation/selectors';
import { DatapointAutomationBlocker } from '../../../redux/modules/annotation/types';
import {
  allMessagesSelector,
  datapointsSelector,
  getVisibleDatapoints,
  messagesStatsSelector,
} from '../../../redux/modules/datapoints/selector';
import { schemaMapSelector } from '../../../redux/modules/schema/schemaMapSelector';
import {
  AnyDatapointDataST,
  Children,
  MessagesStats,
  MessagesStatsState,
  MultivalueDatapointDataST,
  SectionDatapointDataST,
  SimpleDatapointDataST,
} from '../../../types/datapoints';
import {
  ButtonDatapointSchema,
  EnumDatapointSchema,
  EnumOption,
} from '../../../types/schema';
import {
  combineMessagesAndBlockers,
  combineMessagesStatsAndBlockers,
  MessageOrBlocker,
  MessagesStatsWithBlockers,
} from './automation-blockers/helpers';

// This is intended as a TEMPORARY object, we need these properties to interface with
// Redux on a lower level
// But eventually we would like to get rid of this dependency
type SidebarItemMeta = {
  datapointIndex: number;
};

export type SidebarSectionModel = {
  id: number;
  label: string;
  children: (
    | SidebarSimplevalueFieldModel
    | SidebarButtonFieldModel
    | SidebarMultivalueFieldModel
    | SidebarLineItemFieldModel
    | SidebarEnumFieldModel
  )[];
};

export type SidebarSimplevalueFieldModel = {
  kind: 'sidebar-simplevalue-field';
  id: number;
  label: string;
  value: string;
  schemaId: string;
  valueSource: SchemaUiConfigType | 'unset';
  editing: SchemaUiConfigEdit;
  validationSources: string[];
  meta: SidebarItemMeta;
  messagesStats: MessagesStatsWithBlockers | undefined;
  messages: MessageOrBlocker[];
  noRecalculation: boolean | undefined;
  confidenceScore: number | null;
};

export type SidebarButtonFieldModel = {
  kind: 'sidebar-button-field';
  id: number;
  label: string;
  url?: string | null;
  canObtainToken: boolean;
  meta: SidebarItemMeta;
};

export type SidebarEnumFieldModel = {
  kind: 'sidebar-enum-field';
  id: number;
  label: string;
  options: EnumOption[];
  value: string;
  valueOption: EnumOption | undefined;
  validationSources: string[];
  valueSource: SchemaUiConfigType | 'unset';
  meta: SidebarItemMeta;
  messagesStats: MessagesStatsWithBlockers | undefined;
  messages: MessageOrBlocker[];
  clearable: boolean;
  editing: SchemaUiConfigEdit;
  schemaId: string;
  noRecalculation: boolean | undefined;
  confidenceScore: number | null;
};

export type SidebarMultivalueChildFieldModel = {
  kind: 'sidebar-multivalue-child-field';
  parentLabel: string;
  id: number;
  schemaId: string;
  value: string;
  valueSource: SchemaUiConfigType | 'unset';
  editing: SchemaUiConfigEdit;
  validationSources: string[];
  meta: SidebarItemMeta;
  messagesStats: MessagesStatsWithBlockers | undefined;
  messages: MessageOrBlocker[];
  confidenceScore: number | null;
};

export type SidebarMultivalueFieldModel = {
  kind: 'sidebar-multivalue-field';
  id: number;
  schemaId: string;
  children: SidebarMultivalueChildFieldModel[];
  childrenSchemaId: string | undefined;
  label: string;
  validationSources: string[];
  meta: SidebarItemMeta;
  messagesStats: MessagesStatsWithBlockers | undefined;
  messages: MessageOrBlocker[];
};

export type SidebarLineItemFieldModel = {
  kind: 'sidebar-line-item-field';
  id: number;
  label: string;
  schemaId: string;
  validationSources: string[];
  // TODO: Do we want this dependency?
  // Line items need children IDs to distinguish virtual DPs so we have to have this here
  children: Children[];
  meta: SidebarItemMeta;
  messages: MessageOrBlocker[];
  messagesStats: MessagesStatsWithBlockers | undefined;
};

const constructSidebarSimplevalueModel = (
  dp: SimpleDatapointDataST,
  messages: Message[] | undefined,
  messagesStats: MessagesStats | undefined,
  blockers: DatapointAutomationBlocker[] | undefined,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  intl: IntlShape
): SidebarSimplevalueFieldModel => {
  const messagesWithBlockers = combineMessagesAndBlockers(
    dp.schemaId,
    messages,
    blockers,
    schemaMap,
    intl
  );

  return {
    kind: 'sidebar-simplevalue-field',
    id: dp.id,
    label: dp.schema?.label ?? '',
    value: dp.content?.value ?? '',
    schemaId: dp.schemaId,
    valueSource: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.type ?? ('unset' as const)
      : ('unset' as const),
    editing: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.edit ?? 'enabled'
      : 'enabled',
    validationSources: dp.validationSources,
    meta: {
      datapointIndex: dp.meta.index,
    },
    messages: messagesWithBlockers,
    messagesStats: combineMessagesStatsAndBlockers(messagesStats, blockers),
    noRecalculation: dp.noRecalculation,
    confidenceScore: dp.content?.rirConfidence ?? null,
  };
};

const isSimpleValue = (dp: AnyDatapointDataST): dp is SimpleDatapointDataST =>
  isSchemaDatapoint(dp.schema) && !isSchemaDatapointButton(dp.schema);

type SimpleDatapointButtonST = Omit<SimpleDatapointDataST, 'schema'> & {
  schema?: ButtonDatapointSchema;
};

const isButton = (dp: unknown): dp is SimpleDatapointButtonST =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isSchemaDatapointButton((dp as any).schema);

const constructSidebarButtonModel = (
  dp: SimpleDatapointButtonST
): SidebarButtonFieldModel => {
  return {
    kind: 'sidebar-button-field',
    id: dp.id,
    label: dp.schema?.label ?? '',
    url: dp.schema?.popupUrl ?? null,
    canObtainToken: dp.schema?.canObtainToken ?? false,
    meta: {
      datapointIndex: dp.meta.index,
    },
  };
};

type SimpleDatapointEnumST = Omit<SimpleDatapointDataST, 'schema'> & {
  schema?: EnumDatapointSchema;
};

const isEnum = (dp: unknown): dp is SimpleDatapointEnumST =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isSchemaDatapointEnum((dp as any).schema);

const constructSidebarEnumModel = (
  dp: SimpleDatapointEnumST,
  messages: Message[] | undefined,
  messagesStats: MessagesStats | undefined,
  blockers: DatapointAutomationBlocker[] | undefined,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  intl: IntlShape
): SidebarEnumFieldModel => {
  const options = dp.options ?? asMutable(dp.schema?.options) ?? [];
  // If value of enum is not found, it has value === '', but there could be
  // an option with empty string value in the options as well.
  // We want to set this option instead of fallbacking to default value if the empty value option is available
  const isEmptyStringInOptions = options.some(option => option.value === '');

  const valueOption = R.find(options, option => {
    const resolvedValue =
      dp.content?.value !== undefined &&
      dp.content.value !== null &&
      (dp.content.value !== '' || isEmptyStringInOptions)
        ? dp.content.value
        : dp.schema?.defaultValue ?? '';

    return option.value === resolvedValue;
  });

  const required = isSchemaDatapoint(dp.schema)
    ? dp.schema?.constraints?.required ?? true
    : true;
  const clearable = !isEmptyStringInOptions && !required;

  const messagesWithBlockers = combineMessagesAndBlockers(
    dp.schemaId,
    messages,
    blockers,
    schemaMap,
    intl
  );

  return {
    kind: 'sidebar-enum-field',
    id: dp.id,
    label: dp.schema?.label ?? '',
    meta: {
      datapointIndex: dp.meta.index,
    },
    schemaId: dp.schemaId,
    options,
    value: dp.content?.value ?? '',
    valueOption,
    messages: messagesWithBlockers,
    messagesStats: combineMessagesStatsAndBlockers(messagesStats, blockers),
    clearable,
    validationSources: dp.validationSources,
    valueSource: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.type ?? ('unset' as const)
      : ('unset' as const),
    editing: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.edit ?? 'enabled'
      : 'enabled',
    noRecalculation: dp.noRecalculation,
    confidenceScore: dp.content?.rirConfidence ?? null,
  };
};

const isLineItem = (dp: AnyDatapointDataST): dp is MultivalueDatapointDataST =>
  dp.category === 'multivalue' && !dp.meta.isSimpleMultivalue;

const constructSidebarLineItemModel = (
  dp: MultivalueDatapointDataST,
  messages: Message[] | undefined,
  automationBlockers: DatapointAutomationBlocker[] | undefined,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  messagesStats: MessagesStats | undefined,
  intl: IntlShape
): SidebarLineItemFieldModel => {
  return {
    kind: 'sidebar-line-item-field',
    id: dp.id,
    label: dp.schema?.label ?? '',
    schemaId: dp.schemaId,
    children: dp.children,
    validationSources: dp.validationSources ?? [],
    meta: {
      datapointIndex: dp.meta.index,
    },
    messagesStats: combineMessagesStatsAndBlockers(
      messagesStats,
      automationBlockers
    ),
    messages: combineMessagesAndBlockers(
      dp.schemaId,
      messages,
      automationBlockers,
      schemaMap,
      intl
    ),
  };
};

const constructSidebarMultivalueChildModel = (
  dp: SimpleDatapointDataST,
  parentLabel: string,
  messages: Message[] | undefined,
  messagesStats: MessagesStats | undefined,
  automationBlockers: DatapointAutomationBlocker[] | undefined,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  intl: IntlShape
): SidebarMultivalueChildFieldModel => {
  return {
    kind: 'sidebar-multivalue-child-field',
    id: dp.id,
    parentLabel,
    schemaId: dp.schemaId,
    value: dp.content?.value ?? '',
    valueSource: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.type ?? ('unset' as const)
      : ('unset' as const),
    editing: isSchemaDatapoint(dp.schema)
      ? dp.schema.uiConfiguration?.edit ?? 'enabled'
      : 'enabled',
    validationSources: dp.validationSources,
    meta: {
      datapointIndex: dp.meta.index,
    },
    messagesStats: combineMessagesStatsAndBlockers(
      messagesStats,
      automationBlockers
    ),
    messages: combineMessagesAndBlockers(
      dp.schemaId,
      messages,
      automationBlockers,
      schemaMap,
      intl
    ),
    confidenceScore: dp.content?.rirConfidence ?? null,
  };
};

const isMultivalue = (
  dp: AnyDatapointDataST
): dp is Omit<MultivalueDatapointDataST, 'grid'> & { grid: never } =>
  dp.category === 'multivalue' && !!dp.meta.isSimpleMultivalue;

const constructSidebarMultivalueModel = (
  dp: MultivalueDatapointDataST,
  allDatapoints: AnyDatapointDataST[],
  allMessages: Record<string, Message[]>,
  allMessagesStats: MessagesStatsState,
  allAutomationBlockers: Record<string, DatapointAutomationBlocker[]>,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  messagesStats: MessagesStats | undefined,
  intl: IntlShape
): SidebarMultivalueFieldModel => {
  const label = dp.schema?.label ?? '';

  return {
    kind: 'sidebar-multivalue-field',
    id: dp.id,
    schemaId: dp.schemaId,
    label,
    childrenSchemaId: dp.schema?.children?.[0],
    children: dp.children.flatMap(child => {
      const childDp = allDatapoints[child.index];
      if (childDp && isSimpleValue(childDp)) {
        return constructSidebarMultivalueChildModel(
          childDp,
          label,
          allMessages[childDp.id],
          allMessagesStats[childDp.id],
          allAutomationBlockers[childDp.schemaId],
          schemaMap,
          intl
        );
      }

      return [];
    }),
    validationSources: dp.validationSources ?? [],
    meta: {
      datapointIndex: dp.meta.index,
    },
    messagesStats: combineMessagesStatsAndBlockers(
      messagesStats,
      allAutomationBlockers[dp.schemaId]
    ),
    messages: combineMessagesAndBlockers(
      dp.schemaId,
      allMessages[dp.id],
      allAutomationBlockers[dp.schemaId],
      schemaMap,
      intl
    ),
  };
};

const constructSidebarSectionModel = (
  section: SectionDatapointDataST,
  allDatapoints: AnyDatapointDataST[],
  allMessages: {
    [key: number]: Message[];
    all?: Message[];
  },
  allAutomationBlockers: Record<string, DatapointAutomationBlocker[]>,
  schemaMap: ReturnType<typeof schemaMapSelector>,
  messagesStats: MessagesStatsState,
  intl: IntlShape
): SidebarSectionModel => {
  return {
    id: section.id,
    label: section.schema?.label ?? '',
    children: R.filter(
      section.children.map(({ index }) => {
        const dp = allDatapoints[index];

        if (!dp) {
          return null;
        }

        if (isButton(dp)) {
          return constructSidebarButtonModel(dp);
        }

        if (isEnum(dp)) {
          return constructSidebarEnumModel(
            dp,
            allMessages[dp.id],
            messagesStats[dp.id],
            allAutomationBlockers[dp.schemaId],
            schemaMap,
            intl
          );
        }

        if (isSimpleValue(dp)) {
          return constructSidebarSimplevalueModel(
            dp,
            allMessages[dp.id],
            messagesStats[dp.id],
            allAutomationBlockers[dp.schemaId],
            schemaMap,
            intl
          );
        }

        if (isMultivalue(dp)) {
          return constructSidebarMultivalueModel(
            dp,
            allDatapoints,
            allMessages,
            messagesStats,
            allAutomationBlockers,
            schemaMap,
            messagesStats[dp.id],
            intl
          );
        }

        if (isLineItem(dp)) {
          return constructSidebarLineItemModel(
            dp,
            allMessages[dp.id],
            allAutomationBlockers[dp.schemaId],
            schemaMap,
            messagesStats[dp.id],
            intl
          );
        }

        return null;
      }),
      R.isTruthy
    ),
  };
};

export const useSidebarData = () => {
  const allDatapoints = useSelector(datapointsSelector);

  const visibleDatapoints = useSelector(getVisibleDatapoints);

  const allMessages = useSelector(allMessagesSelector);

  const messagesStats = useSelector(messagesStatsSelector);

  const allAutomationBlockers = useSelector(datapointBlockerSelector);

  const schemaMap = useSelector(schemaMapSelector);

  const intl = useIntl();

  const result = visibleDatapoints.reduce<SidebarSectionModel[]>(
    (acc, cur, _idx) => {
      if (cur.category !== 'section' || cur.children.length === 0) {
        return acc;
      }

      return [
        ...acc,
        constructSidebarSectionModel(
          cur,
          allDatapoints,
          allMessages,
          allAutomationBlockers,
          schemaMap,
          messagesStats,
          intl
        ),
      ];
    },
    []
  );

  return result;
};
