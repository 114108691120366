import { getIDFromUrl } from '@rossum/api-client';
import {
  DedicatedEngineSchemaObjectKind,
  isDatapointField,
  isSimpleMultivalueField,
} from '@rossum/api-client/dedicatedEngineSchema';
import {
  DatapointField,
  SimpleMultivalueField,
  TableMultivalueField,
} from '@rossum/api-client/dedicatedEngineSchema';
import { ArrowRightAlt, KeyboardArrowDown, MoreVert } from '@rossum/ui/icons';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { range } from 'remeda';
import { useQueueToSchemaDatapointMap } from '../../../../../../../../../business/queues/useQueueToSchemaDatapointMap';
import { getUnsafe } from '../../../../../../../../../lib/helpers';
import ErrorMessages from '../../IntegrityValidationStep/components/ErrorMessages';

const SHRINK_SOURCES_LIST_ITEMS_COUNT = 3;

type CallbackAction<TCallbackArg> = {
  id: string;
  label: string;
  callback?: (value: TCallbackArg) => void;
};

type EngineSchemaObjectCardProps = CardProps & {
  field: DatapointField | TableMultivalueField | SimpleMultivalueField;
  fieldErrors?: unknown;
  disabled?: boolean;
  actions?: CallbackAction<
    DatapointField | TableMultivalueField | SimpleMultivalueField
  >[];
  elevation?: number;
  fieldType: 'headerField' | 'lineItemColumn';
};

const fieldErrorsKeys = [
  'label',
  'engineOutputId',
  'type',
  'nonFieldErrors',
] as const;

type EngineObjectSchemaSourceProps = {
  source: { queue: string; schemaField: string; errors: unknown };
};

const EngineSchemaObjectSource = ({
  source,
}: EngineObjectSchemaSourceProps) => (
  <Stack
    sx={{
      border: theme => `1px solid ${theme.palette.divider}`,
      borderRadius: 1,
      p: 1,
      mt: 1,
    }}
  >
    <Stack
      alignItems="center"
      direction="row"
      divider={<ArrowRightAlt color="secondary" fontSize="inherit" />}
      spacing={1}
    >
      <Typography variant="body2" color="text.secondary">
        {source.queue}
      </Typography>
      <Typography variant="body2">{source.schemaField}</Typography>
    </Stack>

    <ErrorMessages errors={source.errors} />
  </Stack>
);

const EngineSchemaObjectCard = ({
  field,
  fieldErrors,
  actions,
  disabled,
  elevation = 2,
  fieldType,
  ...cardProps
}: EngineSchemaObjectCardProps) => {
  const mode = Array.isArray(actions) ? 'editable' : 'expandable';

  const intl = useIntl();

  // for simple multivalue, ignore `multivalue` parent
  const displayedField = isSimpleMultivalueField(field)
    ? field.children
    : field;

  const resolvedErrors = useMemo(() => {
    if (!fieldErrors) {
      return undefined;
    }

    if (isSimpleMultivalueField(field)) {
      return getUnsafe(fieldErrors, 'children');
    }

    return fieldErrors;
  }, [field, fieldErrors]);

  const sources = useMemo(
    () => (isDatapointField(displayedField) ? displayedField.sources : []),
    [displayedField]
  );
  // all of this should be deduped internally, but it is a point of discussion how to
  // approach data fetching like this
  // we could also fetch in parent and pass mapped object (intuitively reasonable)
  // but in theory we should also be able to just put this component anywhere and it should work as expected
  // we also don't need _all_ the datapoint options, just the one used in mapping
  // plus we could probably architect the data fetching in a more general and better way :thinking-face:
  const { data, status } = useQueueToSchemaDatapointMap(
    compact(sources.map(source => getIDFromUrl(source.queue))),
    fieldType,
    dp =>
      dp.type !== 'button' &&
      (!dp.uiConfiguration || dp.uiConfiguration.type === 'captured') &&
      !dp.disablePrediction
  );

  const sourcesToDisplay = useMemo(() => {
    return sources.map((source, i) => {
      const sourceId = getIDFromUrl(source.queue);
      const queue = sourceId ? data[sourceId] : undefined;

      return {
        _id: i,
        queue: queue?.queueName ?? '',
        schemaField:
          queue?.datapoints.find(dp => dp.id === source.schemaId)?.label ?? '',
        errors: getUnsafe(resolvedErrors, ['sources', i, 'schemaId']),
      };
    });
  }, [data, sources, resolvedErrors]);

  /** Actions menu */
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuOpen = Boolean(anchorEl);

  /** Expandable variant */
  const [expanded, setExpanded] = useState(() => !!fieldErrors);

  const [showMoreListItems, setShowMoreListItems] = useState(
    () => !!resolvedErrors
  );

  const handleShowMore = () => setShowMoreListItems(prev => !prev);

  return (
    <Card elevation={elevation} sx={{ borderRadius: 1 }} {...cardProps}>
      <CardHeader
        disableTypography
        title={
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            divider={
              <Divider
                orientation="vertical"
                light
                sx={{ height: 16, alignSelf: 'center' }}
              />
            }
          >
            <Typography variant="body2">{displayedField.label}</Typography>
            <Typography variant="caption" color="text.disabled">
              {displayedField.type}
            </Typography>
            <Typography variant="caption" color="text.disabled">
              {displayedField.engineOutputId}
            </Typography>
            {displayedField.type === 'freeform' ||
              (displayedField.type === 'grid' && (
                <Typography variant="caption" color="text.disabled">
                  {intl.formatMessage({
                    id: `components.engineSchemaObjectCard.type.${displayedField.type}`,
                  })}
                </Typography>
              ))}
            {field.__kind ===
              DedicatedEngineSchemaObjectKind.SimpleMultivalue && (
              <Typography variant="caption" color="text.disabled">
                {intl.formatMessage({
                  id: `components.engineSchemaObjectCard.multivalue`,
                })}
              </Typography>
            )}
          </Stack>
        }
        action={
          // TODO: Stuff like this dropdown could be components on system level, when we define all variants and functionality
          // later
          mode === 'editable' ? (
            actions &&
            actions.length > 0 && (
              <>
                <IconButton
                  size="small"
                  aria-controls="header-field-menu"
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? 'true' : undefined}
                  onClick={e => setAnchorEl(e.currentTarget)}
                  disabled={disabled}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="header-field-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={() => setAnchorEl(null)}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.id}
                      onClick={() => {
                        action.callback?.(field);
                        setAnchorEl(null);
                      }}
                      dense
                    >
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )
          ) : (
            <IconButton
              size="small"
              onClick={() => setExpanded(e => !e)}
              disabled={disabled}
            >
              <KeyboardArrowDown
                sx={{
                  transform: expanded ? 'rotate(180deg)' : 'none',
                  transition: theme => theme.transitions.create('transform'),
                }}
              />
            </IconButton>
          )
        }
        sx={{ pt: '10px', pb: '10px' }}
      />
      <Collapse in={mode === 'editable' || expanded} unmountOnExit>
        <CardContent sx={{ pt: 0 }}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              {displayedField.description}
            </Typography>
            {fieldErrorsKeys.map(key =>
              getUnsafe(resolvedErrors, key) ? (
                <ErrorMessages
                  key={key}
                  errors={getUnsafe(resolvedErrors, key)}
                />
              ) : null
            )}
            <Stack spacing={0.5}>
              {isDatapointField(displayedField) ? (
                status === 'success' ? (
                  <>
                    {sourcesToDisplay.length >
                      SHRINK_SOURCES_LIST_ITEMS_COUNT && (
                      <Stack direction="row" justifyContent="end">
                        <Button
                          onClick={handleShowMore}
                          variant="text"
                          sx={{
                            color: 'text.primary',
                          }}
                          fullWidth={false}
                          endIcon={
                            <KeyboardArrowDown
                              sx={{
                                transform: showMoreListItems
                                  ? 'rotate(180deg)'
                                  : 'none',
                                transition: theme => {
                                  return theme.transitions.create('transform');
                                },
                              }}
                            />
                          }
                        >
                          {showMoreListItems ? (
                            <Typography variant="caption">
                              {intl.formatMessage({
                                id: 'components.engineSchemaObjectCard.showLess',
                              })}
                            </Typography>
                          ) : (
                            <Typography variant="caption">
                              {intl.formatMessage(
                                {
                                  id: 'components.engineSchemaObjectCard.showMore',
                                },
                                {
                                  count:
                                    sourcesToDisplay.length -
                                    SHRINK_SOURCES_LIST_ITEMS_COUNT,
                                }
                              )}
                            </Typography>
                          )}
                        </Button>
                      </Stack>
                    )}
                    <Stack>
                      {sourcesToDisplay
                        .filter((_, i) => i <= SHRINK_SOURCES_LIST_ITEMS_COUNT)
                        .map(source => (
                          <EngineSchemaObjectSource
                            key={source._id}
                            {...{ source }}
                          />
                        ))}
                      <Collapse in={showMoreListItems} unmountOnExit>
                        {sourcesToDisplay
                          .filter((_, i) => i > SHRINK_SOURCES_LIST_ITEMS_COUNT)
                          .map(source => (
                            <EngineSchemaObjectSource
                              key={source._id}
                              {...{ source }}
                            />
                          ))}
                      </Collapse>
                    </Stack>
                  </>
                ) : status === 'loading' ? (
                  range(0, 3).map(i => (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      width="100%"
                      height={30}
                    />
                  ))
                ) : null
              ) : (
                displayedField.children.children.map((datapointField, i) => (
                  <EngineSchemaObjectCard
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}-${datapointField.engineOutputId}`}
                    field={datapointField}
                    fieldErrors={getUnsafe(resolvedErrors, [
                      'children',
                      'children',
                      i,
                    ])}
                    elevation={4}
                    fieldType="lineItemColumn"
                  />
                ))
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default EngineSchemaObjectCard;
