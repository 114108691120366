import { getIDFromUrl } from '@rossum/api-client';
import { ListItemProps, Stack } from '@rossum/ui/material';
import deepEqual from 'fast-deep-equal/es6/react';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LineItemsControls from '../../../../components/LineItemsControls';
import { datapointsSelector } from '../../../../redux/modules/datapoints/selector';
import { isVirtualDatapoint } from '../../../../redux/modules/datapoints/typedHelpers';
import { sidebarFieldIdsEnabledSelector } from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { MultivalueDatapointDataST } from '../../../../types/datapoints';
import { SidebarLineItemFieldModel } from '../useSidebarData';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { MultivalueChip } from './shared/MultivalueChip';
import { SidebarItemLabel } from './shared/SidebarItemLabel';
import { SidebarItemLayout } from './shared/SidebarItemLayout';
import { getFieldsSettingsPath } from './utils';

// TODO: Refactor so everything is in this folder instead of CLI folders (when deleting old sidebar)
type LineItemsItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarLineItemFieldModel;
  active: boolean;
  disabled: boolean;
  onClick: (datapointId: number) => void;
};

const LineItemsItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    active,
    disabled,
    onClick,
    ...listItemProps
  }: LineItemsItemProps) => {
    const allDatapoints = useSelector(datapointsSelector);

    // ugh
    const currentDatapoint = allDatapoints[
      item.meta.datapointIndex
    ] as MultivalueDatapointDataST;

    const handleClick = useCallback(() => {
      onClick(item.id);
    }, [item.id, onClick]);

    const isConfirmed = useMemo(() => {
      return item.validationSources?.includes('human') ?? false;
    }, [item.validationSources]);

    const iconVariant = useMemo(() => {
      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed]);

    const iconColor = useMemo(() => {
      if (isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed]);

    const realChildrenCount = item.children.filter(
      dp => !isVirtualDatapoint(dp.id)
    ).length;

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <MagicContainer
        hasMagicLine={false}
        active={active}
        disabled={disabled}
        sidebarScrollableRef={sidebarScrollableRef}
        onClick={active ? undefined : handleClick}
        {...listItemProps}
      >
        <SidebarItemLayout
          iconSlot={
            <ItemContextActions
              item={item}
              active={active}
              disabled={disabled}
              iconProps={{
                type: 'lineItem',
                variant: iconVariant,
                color: iconColor,
              }}
              fieldSettingsPath={getFieldsSettingsPath(queueId, item.schemaId)}
            />
          }
          labelSlot={
            <SidebarItemLabel
              label={item.label}
              sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
            />
          }
          decorationSlot={
            <Stack direction="row" spacing={2} alignItems="center">
              <MessageIndicator messagesStats={item.messagesStats} />
              <MultivalueChip childrenCount={realChildrenCount} />
            </Stack>
          }
        />
        {active ? (
          <Stack spacing={1} sx={{ '&:empty': { display: 'none' } }}>
            {disabled ? null : (
              <LineItemsControls
                tupleIds={item.children.map(child => child.id)}
                currentDatapoint={currentDatapoint}
              />
            )}
            {item.messages.length > 0 && <Messages messages={item.messages} />}
          </Stack>
        ) : null}
      </MagicContainer>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

LineItemsItem.displayName = 'LineItemsItem';

export { LineItemsItem };
