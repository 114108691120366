import { alpha, Button } from '@rossum/ui/material';
import { ActionKeys, ActionProps } from './types';

export const actionMap: Record<
  ActionKeys,
  (props: ActionProps) => JSX.Element
> = {
  refresh: ({ onClick, intl }) => (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        color: 'text.primary',
        '&:hover': {
          backgroundColor: t => alpha(t.palette.text.primary, 0.08),
        },
      }}
    >
      {intl.formatMessage({ id: 'component.toaster.action.refresh' })}
    </Button>
  ),
};
