import { Queue } from '@rossum/api-client/queues';
import { useQueryClient } from '@tanstack/react-query';
import equal from 'fast-deep-equal/es6/react';
import { get, isEmpty } from 'lodash';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import * as R from 'remeda';
import Immutable from 'seamless-immutable';
import { SCHEMA_QUERY_KEY } from '../../../../business/schema/useSchema';
import mountWhen from '../../../../decorators/mountWhen';
import {
  OpenModal,
  openModal as openModalAction,
} from '../../../../redux/modules/modal/actions';
import { currentQueueAndWorkspaceSelector } from '../../../../redux/modules/queues/selector';
import {
  UpdateSchemaContent,
  updateSchemaContent as updateSchemaContentAction,
  ValidateSchema,
  validateSchema as validateSchemaAction,
} from '../../../../redux/modules/schema/actions';
import {
  originalSchemaSelector,
  schemaErrorsSelector,
  validSchemaSelector,
} from '../../../../redux/modules/schema/selectors';
import { enterQueueEditor } from '../../../../redux/modules/ui/actions';
import { withoutImmutable } from '../../../../redux/modules/utils';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { ImmutableArrayObject } from '../../../../types/CustomTypes/immutable';
import { OriginalAnyDatapointSchema } from '../../../../types/schema';
import { State } from '../../../../types/state';
import { Workspace } from '../../../../types/workspace';
import { LeavingDialog } from '../../../../ui/leaving-dialog/LeavingDialog';
import SchemaEditor, { schemaToString } from './components/SchemaEditor';
import Sidebar from './components/Sidebar';
import scrollingSubject from './lib/scrollingSubject';
import styles from './styles.module.sass';

type OwnProps = RouteComponentProps<{ queueId: string }>;

type StateProps = {
  queue?: Queue;
  schema?: ImmutableArrayObject<OriginalAnyDatapointSchema>;
  schemaErrors?: Array<{ key: string; message: string }>;
  validationTimeStamp: number;
  validSchema?: ImmutableArrayObject<OriginalAnyDatapointSchema>;
  workspace?: Workspace;
  schemaUrl?: string;
};
type DispatchProps = {
  openModal: OpenModal;
  validateSchema: ValidateSchema;
  updateSchemaContent: UpdateSchemaContent;
};

type Props = DispatchProps & StateProps & OwnProps;

const QueueSchema = ({
  location,
  openModal,
  queue,
  schema,
  schemaErrors,
  updateSchemaContent,
  validateSchema,
  validationTimeStamp,
  validSchema,
  workspace,
  schemaUrl,
}: Props) => {
  const [schemaConcept, setSchemaConcept] = useState(schema);
  const [schemaValue, setSchemaValue] = useState(schemaToString(schema));
  const [isJSONValid, setIsJSONValid] = useState(true);
  const [currentPath, setCurrentPath] = useState<Array<string>>([]);

  const dispatch = useDispatch();
  const history = useHistory<{
    backLink?: string;
  }>();
  const queryClient = useQueryClient();

  useEffect(() => {
    const idFromUrl = parse(history.location.search).id;

    if (idFromUrl && R.isString(idFromUrl)) {
      scrollingSubject.next(idFromUrl);
    }
  });

  useEffect(() => {
    dispatch(enterQueueEditor());
  }, [dispatch]);

  useEffect(
    () => setSchemaValue(schemaToString(schemaConcept)),
    [schemaConcept]
  );

  useEffect(() => setSchemaConcept(schema), [schema]);

  useEffect(() => {
    if (schemaUrl && schemaConcept)
      validateSchema({
        content: withoutImmutable<OriginalAnyDatapointSchema[]>(schemaConcept),
        url: schemaUrl,
      });
  }, [schemaConcept, validateSchema, schemaUrl]);

  const resetSchema = (callbackFn?: () => void) => {
    if (callbackFn) callbackFn();
    setSchemaConcept(schema);
  };

  const saveSchema = (withMessage = false) => {
    queryClient.invalidateQueries([SCHEMA_QUERY_KEY]);
    return schemaConcept && updateSchemaContent(schemaConcept, { withMessage });
  };

  const onCurrentSchemaPartChange = (
    newSchemaPart: OriginalAnyDatapointSchema
  ) => {
    const newSchema = schemaConcept?.setIn(
      currentPath,
      newSchemaPart
    ) as ImmutableArrayObject<OriginalAnyDatapointSchema>;
    setSchemaConcept(newSchema);
  };

  const isSchemaChanged = !equal(schema, schemaConcept);

  const showMessageWhenLeave = !isJSONValid || isSchemaChanged;
  const isSchemaOutdated = Boolean(
    !isJSONValid || (schemaErrors && schemaErrors.length)
  );
  const isCurrentPath = (path: string[]) =>
    equal(currentPath.slice(0, path.length), path);

  const redirectionData = {
    pathname:
      history.location.state?.backLink ??
      `/queues/${queue?.id}/settings/fields`,
    state: get(location, 'state'),
  };

  const handleSetCurrentPath = (path: Array<string>, searchQuery?: string) => {
    history.replace(
      searchQuery
        ? { ...history.location, search: searchQuery }
        : history.location.pathname
    );

    setCurrentPath(path);
  };

  return (
    <div className={styles.SchemaWrapper} data-page-title="queue-schema">
      {queue ? (
        <HelmetComponent
          translationKey="features.routes.pageTitles.queueSettings.schema"
          dynamicName={queue.name}
        />
      ) : null}
      <Sidebar
        isCurrentPath={isCurrentPath}
        isSchemaOutdated={isSchemaOutdated}
        redirectionData={redirectionData}
        queue={queue}
        schemaConceptExists={!isEmpty(schemaConcept)}
        toggleCurrent={(path: Array<string>) => {
          const id = schemaConcept?.getIn(path)?.id as string | undefined;
          const searchQuery = id ? `?id=${id}` : undefined;

          return handleSetCurrentPath(
            equal(path, currentPath) || isSchemaOutdated ? [] : path,
            searchQuery
          );
        }}
        validSchema={validSchema}
        workspace={workspace}
      />
      <SchemaEditor
        JSONisValid={isJSONValid}
        currentSchemaPart={currentPath && schemaConcept?.getIn(currentPath)}
        isCurrentPath={isCurrentPath}
        isSchemaChanged={isSchemaChanged}
        isSchemaOutdated={isSchemaOutdated}
        onCurrentSchemaPartChange={onCurrentSchemaPartChange}
        openModal={openModal}
        resetSchema={resetSchema}
        saveSchema={saveSchema}
        schemaConcept={schemaConcept}
        schemaErrors={schemaErrors}
        schemaValue={schemaValue}
        setCurrentPath={handleSetCurrentPath}
        setJSONisValid={setIsJSONValid}
        setSchema={(newSchema: OriginalAnyDatapointSchema[]) =>
          setSchemaConcept(
            Immutable(
              newSchema
            ) as ImmutableArrayObject<OriginalAnyDatapointSchema>
          )
        }
        currentPath={currentPath}
        setSchemaValue={setSchemaValue}
        validSchema={validSchema}
        validationTimeStamp={validationTimeStamp}
      />
      <LeavingDialog when={showMessageWhenLeave} />
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { workspace, queue } = currentQueueAndWorkspaceSelector(state);
  const schema = Immutable(
    originalSchemaSelector(state)
  ) as ImmutableArrayObject<OriginalAnyDatapointSchema>;
  const schemaErrors = schemaErrorsSelector(state);
  const validSchema = Immutable(
    validSchemaSelector(state)
  ) as ImmutableArrayObject<OriginalAnyDatapointSchema>;
  const { validationTimeStamp, url } = state.schema;
  return {
    workspace: workspace ?? undefined,
    queue: queue ?? undefined,
    schema,
    schemaErrors,
    validSchema,
    validationTimeStamp: validationTimeStamp ?? 0,
    schemaUrl: url,
  };
};

const mapDispatchToProps = {
  openModal: openModalAction,
  validateSchema: validateSchemaAction,
  updateSchemaContent: updateSchemaContentAction,
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(mountWhen(QueueSchema)('schema'));
