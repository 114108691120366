import { Queue } from '@rossum/api-client/queues';
import { Check, DoDisturb } from '@rossum/ui/icons';
import { Box, Button, Stack, Switch } from '@rossum/ui/material';
import { get, includes, map } from 'lodash';
import UsersIcon from 'mdi-react/AccountMultipleOutlineIcon';
import { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Dispatch } from 'redux';
import { isTruthy, pickBy } from 'remeda';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import SearchInput from '../../../components/UI/SearchInput';
import { pageSizes } from '../../../constants/values';
import { QueueSettingsBreadcrumbs } from '../../../features/queue-settings/components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../../../features/queue-settings/components/QueueSettingsTabs';
import { boldText, white } from '../../../lib/formaterValues';
import { parse, stringify } from '../../../lib/url';
import {
  getRoleName,
  isUserAdminLike,
} from '../../../redux/modules/groups/helpers';
import { openModal } from '../../../redux/modules/modal/actions';
import {
  updateQueueDetail as _updateQueueDetail,
  updateUsersOnQueue,
} from '../../../redux/modules/queues/actions';
import { getName } from '../../../redux/modules/user/helpers';
import { Group } from '../../../types/group';
import { State } from '../../../types/state';
import { User, UserList } from '../../../types/user';
import { Header } from '../../../ui/header/Header';
import { BasicTile } from '../../../ui/tiles/BasicTile';
import UsersFooter from '../../Users/components/UsersFooter';
import styles from '../styles.module.sass';

type DispatchProps = {
  updateQueueDetail: (payload: Partial<Queue>) => void;
  onEnableAll: (count: number) => () => void;
  onDisableAll: (count: number) => () => void;
};

type OwnProps = RouteChildrenProps & {
  selectedQueue?: Queue | null;
  parentPath: string;
};

type StateProps = {
  users: UserList;
  batchButtonsDisabled: boolean;
  count: number;
  groups: Array<Group>;
};

type Props = StateProps &
  OwnProps & {
    updateQueueDetail: (payload: Partial<Queue>) => void;
    onEnableAll: () => void;
    onDisableAll: () => void;
  };

const defaultQuery = { pageSize: 15, page: 1, ordering: 'username' };

const QueueAccess = ({
  batchButtonsDisabled,
  users,
  selectedQueue,
  history,
  parentPath,
  location,
  updateQueueDetail,
  count,
  onEnableAll,
  onDisableAll,
  groups,
}: Props) => {
  const { search = '', ...query } = parse(location.search);

  const intl = useIntl();

  useEffect(() => {
    const { pageSize, page, ordering } = parse(location.search) || {};
    if (!ordering || !page || !pageSize || !includes(pageSizes, pageSize)) {
      history.replace({
        state: get(location, 'state'),
        search: stringify(defaultQuery),
      });
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSearchPhrase = (value: string) =>
    history.replace({
      state: get(location, 'state'),
      search: stringify(pickBy({ ...query, search: value, page: 1 }, isTruthy)),
    });

  const isEnabled = ({ url }: User) =>
    includes(get(selectedQueue, 'users', []), url);

  const isAdmin = ({ groups: userGroups }: User) =>
    isUserAdminLike(getRoleName(userGroups, groups));

  const onToggle = (enabled: boolean, user: User) =>
    updateQueueDetail({
      users: enabled
        ? [...get(selectedQueue, 'users', []), user.url]
        : get(selectedQueue, 'users', []).filter(
            userUrl => userUrl !== user.url
          ),
    });

  // TODO: This should probably not be copy-pasted around
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              queueName={selectedQueue?.name ?? ''}
              settingsPath={parentPath}
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'containers.settings.queues.queue.access',
                  }),
                  to: `${parentPath}/access`,
                },
              ]}
            />
          }
          tabs={<QueueSettingsTabs parentPath={parentPath} value="access" />}
          title={intl.formatMessage({
            id: 'containers.settings.queues.queue.access',
          })}
          description={intl.formatMessage({
            id: 'containers.settings.queues.queue.access.description',
          })}
          onBackButtonClicked={onBackButtonClicked}
        />
      )}
    >
      <Box
        className={styles.AccessLayout}
        sx={{ p: 4 }}
        data-page-title="queue-access"
      >
        <div className={styles.AccessHeader}>
          <div className={styles.AccessTitle}>
            <FormattedMessage id="containers.settings.queues.queue.title" />
          </div>
          {!batchButtonsDisabled && (
            <div className={styles.AccessBadges}>
              <Button
                color="secondary"
                size="small"
                startIcon={<Check />}
                data-cy="queue-access-enable-all"
                onClick={() => onEnableAll()}
              >
                <FormattedMessage
                  id="containers.users.components.workspace.enableAll"
                  values={{ count }}
                />
              </Button>
              <Button
                color="secondary"
                size="small"
                data-cy="queue-access-disable-all"
                onClick={() => onDisableAll()}
                startIcon={<DoDisturb />}
              >
                <FormattedMessage
                  id="containers.users.components.workspace.disableAll"
                  values={{ count }}
                />
              </Button>
            </div>
          )}
          <SearchInput
            className={styles.Search}
            value={search ?? ''}
            onChange={setSearchPhrase}
            placeholder="containers.settings.queues.queue.searchPlaceholder"
          />
        </div>
        {users.length ? (
          <Stack direction="column" spacing={1} pt={2}>
            {map(users, user => (
              <BasicTile key={user.id}>
                <Stack direction="row" gap={4}>
                  <Switch
                    data-cy="queue-access-enable-user-toggle"
                    disabled={!selectedQueue || isAdmin(user)}
                    onChange={(_, value) => onToggle(value, user)}
                    checked={isEnabled(user) || isAdmin(user)}
                    size="small"
                  />
                  {getName(user)}
                </Stack>

                {isAdmin(user) && (
                  <FormattedMessage id="containers.settings.queues.queue.admin" />
                )}
              </BasicTile>
            ))}
          </Stack>
        ) : (
          <InfoPlaceholder
            icon={<UsersIcon />}
            title="containers.settings.queues.noUserFound"
            text="containers.settings.queues.noUserFound.text"
          />
        )}
      </Box>
      <UsersFooter />
    </PageLayoutV2>
  );
};

const mapStateToProps = ({ groups, users }: State, { location }: OwnProps) => {
  const isSearchActive = get(parse(location.search), 'search');
  const usersTotal = get(users, 'pagination.total', 0);
  const adminsTotal = get(users, 'adminsCount', 0);

  return {
    users: users.list,
    batchButtonsDisabled: usersTotal === adminsTotal,
    count: isSearchActive ? Math.max(usersTotal - adminsTotal, 0) : 0,
    groups,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { selectedQueue }: OwnProps
): DispatchProps => ({
  updateQueueDetail: (payload: Partial<Queue>) =>
    selectedQueue &&
    dispatch(_updateQueueDetail(get(selectedQueue, 'id'), payload)),
  onEnableAll: (count: number) => () =>
    selectedQueue &&
    dispatch(
      openModal({
        textId: 'enableUsersOnQueue',
        confirmAction: () =>
          updateUsersOnQueue({
            queueId: get(selectedQueue, 'id'),
            adding: true,
          }),
        values: {
          count,
          queueName: get(selectedQueue, 'name'),
          white,
          bold: boldText,
        },
      })
    ),
  onDisableAll: (count: number) => () =>
    dispatch(
      openModal({
        textId: 'disableUsersOnQueue',
        confirmAction: () =>
          updateUsersOnQueue({
            queueId: get(selectedQueue, 'id')!,
            adding: false,
          }),
        values: {
          count,
          queueName: get(selectedQueue, 'name'),
          white,
          bold: boldText,
        },
      })
    ),
});

const mergeProps = (
  stateProps: StateProps,
  { onEnableAll, onDisableAll, ...dispatchProps }: DispatchProps,
  ownProps: OwnProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onDisableAll: onDisableAll(stateProps.count),
  onEnableAll: onEnableAll(stateProps.count),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(QueueAccess);
