import { ValuesBase } from '@rossum/api-client/annotations';
import { FontDownloadOutlined } from '@rossum/ui/icons';
import {
  IconCancel,
  IconCheck,
  IconFileTime,
  IconHandFinger,
  IconHourglassEmpty,
  IconHourglassHigh,
  IconLogin,
  IconLogout,
  IconPencil,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import { Stack, SvgIcon } from '@rossum/ui/material';
import humanizeDuration from 'humanize-duration';
import { round } from 'lodash';
import { ReactNode } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import AggregateStat from '../../../components/Graphs/components/AggregateStat';
import { humanizeDurationLocaleMap, LocalizationKeys } from '../../../i18n';
import { fallbackLocale } from '../../../i18n/config';
import { getDurationUnit, limitUnits } from '../../../lib/timeUtils';

// TODO:
//   - unify type with props type of AggregateState compoennt
//   - replace ReactNode types with string / number
//   - replace formatted message components with inttl.formatMessage functions
export type AggregateData = Array<{
  value: ReactNode;
  icon: ReactNode;
  description: LocalizationKeys;
}>;

type AggregateProps = {
  data: AggregateData;
};

type Props = {
  isTmpState: boolean;
  totals: ValuesBase;
};

type IntlProps = { intl: IntlShape };
type PropsWithIntl = Props & IntlProps;

export const Aggregate = ({ data }: AggregateProps) => (
  <Stack
    direction="row"
    alignItems="center"
    pl={3}
    justifyContent="space-around"
  >
    {data.map(({ value, icon, description }) => (
      <AggregateStat
        key={description}
        icon={icon}
        value={value}
        description={<FormattedMessage id={description} />}
      />
    ))}
  </Stack>
);

const iconProps = {
  sx: { fill: 'none' },
} as const;

export const UsageAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.importedCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'info'}
            component={IconLogin}
          />
        ),
        description: 'components.graphs.usage.series.imported',
      },
      {
        value: totals.confirmedCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'warning'}
            component={IconCheck}
          />
        ),
        description: 'components.graphs.usage.series.confirmed',
      },
      {
        value: totals.exportedCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'success'}
            component={IconLogout}
          />
        ),
        description: 'components.graphs.usage.series.exported',
      },
      {
        value: totals.rejectedCount,
        icon: (
          <SvgIcon
            sx={{
              fill: 'none',
              color: t =>
                isTmpState ? t.palette.action.active : t.palette.error.light,
            }}
            component={IconCancel}
          />
        ),
        description: 'components.graphs.usage.series.rejected',
      },
      {
        value: totals.deletedCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'error'}
            component={IconTrash}
          />
        ),
        description: 'components.graphs.usage.series.deleted',
      },
    ],
  })
)(Aggregate);

export const TimeAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { isTmpState, totals, intl }: PropsWithIntl): AggregateProps => {
    const total = totals.turnaroundAvgS || 0;
    return {
      data: [
        {
          value: humanizeDuration(round(total, 2) * 1000, {
            largest: 2,
            language: intl.locale,
            fallbacks: [humanizeDurationLocaleMap[intl.locale], fallbackLocale],
            units: limitUnits(getDurationUnit(total)),
          }),
          icon: (
            <SvgIcon
              {...iconProps}
              color={isTmpState ? 'action' : 'info'}
              component={IconCheck}
            />
          ),
          description: 'components.graphs.aggregate.turnaroundAvgS',
        },
      ],
    };
  })(Aggregate)
);

export const CorrectionsAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { isTmpState, totals }: PropsWithIntl): AggregateProps => {
    const total = totals.correctionsPerDocumentAvg || 0;
    return {
      data: [
        {
          value: (
            <FormattedMessage
              id="components.graphs.correctionsPerDocumentAvg.unit"
              values={{ count: round(total, 2) }}
            />
          ),
          icon: (
            <SvgIcon
              {...iconProps}
              color={isTmpState ? 'action' : 'info'}
              component={IconPencil}
            />
          ),
          description: 'components.graphs.aggregate.corrections',
        },
      ],
    };
  })(Aggregate)
);

export const TimePerDocumentAggregate = injectIntl<'intl', PropsWithIntl>(
  connect((_, { intl, isTmpState, totals }: PropsWithIntl): AggregateProps => {
    const total = totals.timePerDocumentAvgS || 0;
    return {
      data: [
        {
          value: humanizeDuration(total * 1000, {
            largest: 2,
            units: limitUnits(getDurationUnit(total)),
            language: intl.locale,
            fallbacks: [humanizeDurationLocaleMap[intl.locale], fallbackLocale],
            round: true,
          }),
          icon: (
            <SvgIcon
              {...iconProps}
              color={isTmpState ? 'action' : 'info'}
              component={IconFileTime}
            />
          ),
          description: 'components.graphs.aggregate.timePerDocumentAvgS',
        },
      ],
    };
  })(Aggregate)
);

export const DelayAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.exportedOnTimeCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'info'}
            component={IconHourglassHigh}
          />
        ),
        description: 'components.graphs.delay.series.exportedOnTimeCount',
      },
      {
        value: totals.exportedLateCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'error'}
            component={IconHourglassEmpty}
          />
        ),
        description: 'components.graphs.delay.series.exportedLateCount',
      },
    ],
  })
)(Aggregate);

export const AutomatedAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => {
    const exportedNonAutomated =
      totals?.exportedCount && totals?.exportedAutomatedCount
        ? totals.exportedCount - totals.exportedAutomatedCount
        : null;

    return {
      data: [
        {
          value: totals.exportedAutomatedCount,
          icon: (
            <FontDownloadOutlined
              sx={{
                fill: t =>
                  isTmpState ? t.palette.action.active : t.palette.error.main,
              }}
            />
          ),
          description:
            'components.graphs.automated.series.exportedAutomatedCount',
        },
        {
          value: exportedNonAutomated,
          icon: (
            <SvgIcon
              {...iconProps}
              color={isTmpState ? 'action' : 'info'}
              component={IconHandFinger}
            />
          ),
          description:
            'components.graphs.automated.series.exportedNonAutomated',
        },
      ],
    };
  }
)(Aggregate);

export const RejectionAggregate = connect(
  (_, { isTmpState, totals }: Props): AggregateProps => ({
    data: [
      {
        value: totals.rejectedAutomaticallyCount,
        icon: (
          <FontDownloadOutlined
            sx={{
              fill: t =>
                isTmpState ? t.palette.action.active : t.palette.error.main,
            }}
          />
        ),
        description: 'components.graphs.rejection.series.automatedRejections',
      },
      {
        value: totals.rejectedManuallyCount,
        icon: (
          <SvgIcon
            {...iconProps}
            color={isTmpState ? 'action' : 'info'}
            component={IconHandFinger}
          />
        ),
        description: 'components.graphs.rejection.series.manualRejections',
      },
    ],
  })
)(Aggregate);
