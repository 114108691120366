import { Url } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { ExpandLess, ExpandMore, FolderOutlined } from '@rossum/ui/icons';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Chip,
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { forwardRef, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { unique } from 'remeda';
import { useWorkspacesWithQueues } from '../hooks/useWorkspacesWithQueues';

const RenderGroup = ({
  params,
  workspace,
  selectedQueues,
  selectAll,
  deselectAll,
  inputValue,
}: {
  params: AutocompleteRenderGroupParams;
  workspace: Workspace;
  selectedQueues: Queue[];
  selectAll: () => void;
  deselectAll: () => void;
  inputValue: string;
}) => {
  const [open, setOpen] = useState(true);
  const intl = useIntl();

  return (
    <List key={params.group} dense sx={{ py: 0 }}>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <FolderOutlined />
        </ListItemIcon>
        <ListItemText primary={workspace?.name ?? 'N/A'} />
        {/* Here, it is not obvious if counts are related to the filtered or non-filtered results. */}
        {/* So we should better hide those options not to be ambiguous. */}
        {inputValue ? null : (
          <Stack spacing={1} direction="row">
            {selectedQueues.length < workspace.queues.length ? (
              <Link
                variant="body2"
                color="text.primary"
                sx={{
                  '&:hover': { color: theme => theme.palette.text.primary },
                }}
                onClick={e => {
                  e.stopPropagation();
                  selectAll();
                }}
              >
                {intl.formatMessage({ id: 'features.queues.selectAll' })}
              </Link>
            ) : (
              <Link
                variant="body2"
                color="text.primary"
                sx={{
                  '&:hover': { color: theme => theme.palette.text.primary },
                }}
                onClick={e => {
                  e.stopPropagation();
                  deselectAll();
                }}
              >
                {intl.formatMessage({ id: 'features.queues.deselectAll' })}
              </Link>
            )}
            <Chip
              size="tiny"
              label={`${selectedQueues.length} / ${workspace.queues.length}`}
            />
          </Stack>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {params.children}
        </List>
      </Collapse>
    </List>
  );
};

type QueueMultiSelectProps = {
  value: Url[];
  onChange: (values: Url[]) => void;
  withLabel?: boolean;
};

export const QueueMultiSelect = forwardRef(
  ({ value, onChange, withLabel = true }: QueueMultiSelectProps, ref) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = useState<string>('');

    const { isLoading, workspaces, workspacesWithQueues } =
      useWorkspacesWithQueues({
        enableQueries: true,
      });

    const stableQueues = useMemo(
      () =>
        workspacesWithQueues?.flatMap(ws =>
          ws.queues.map(queue => ({ ...queue, workspaceName: ws.name }))
        ) ?? [],
      [workspacesWithQueues]
    );

    const stableValue = useMemo(
      () => stableQueues?.filter(queue => value.includes(queue.url)) ?? [],
      [stableQueues, value]
    );

    return !isLoading ? (
      <Autocomplete
        data-cy="queue-multi-select"
        PaperComponent={Paper}
        slotProps={{ paper: { elevation: 6 } }}
        multiple
        ref={ref}
        size="small"
        options={stableQueues}
        getOptionLabel={option => `${option.workspaceName} ${option.name}`}
        groupBy={option => option.workspace ?? ''}
        value={stableValue}
        disableCloseOnSelect
        onChange={(_e, value) => {
          if (value) {
            onChange(value.map(queue => queue.url));
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={
              withLabel
                ? intl.formatMessage({
                    id: 'features.queues.queueMultiSelect.label',
                  })
                : null
            }
            placeholder={intl.formatMessage({
              id: 'features.queues.queueMultiSelect.placeholder',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderGroup={params => {
          const workspace = workspaces?.find(ws => ws.url === params.group);
          const selectedQueues = stableValue.filter(queue =>
            workspace?.queues.includes(queue.url)
          );
          return workspace ? (
            <RenderGroup
              params={params}
              workspace={workspace}
              selectedQueues={selectedQueues}
              selectAll={() =>
                onChange(unique([...value, ...workspace.queues]))
              }
              deselectAll={() =>
                onChange(
                  value.filter(queue => !workspace.queues.includes(queue))
                )
              }
              inputValue={inputValue}
            />
          ) : null;
        }}
        renderOption={(params, queue) => (
          <ListItemButton
            component="li"
            {...params}
            key={queue.url}
            data-cy="queue-option"
          >
            <ListItemText secondary={queue.name} />
          </ListItemButton>
        )}
        renderTags={(tagValue, getTagProps) => {
          const limit = 5;
          return (
            <>
              {tagValue.slice(0, limit).map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  size="small"
                  key={option.url}
                  label={
                    <Typography
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      <Typography
                        component="span"
                        variant="caption"
                        color="text.primary"
                      >
                        {option.workspaceName}
                      </Typography>{' '}
                      - {option.name}
                    </Typography>
                  }
                />
              ))}
              {tagValue.length > limit ? (
                <Chip
                  size="small"
                  label={
                    <Typography
                      component="span"
                      variant="caption"
                      color="text.secondary"
                    >
                      +{tagValue.length - limit}
                    </Typography>
                  }
                />
              ) : null}
            </>
          );
        }}
      />
    ) : null;
  }
);
