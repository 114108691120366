import {
  IconArrowAutofitWidth,
  IconArrowLeft,
  IconFileSearch,
  IconZoomIn,
  IconZoomOut,
} from '@rossum/ui/icons/tabler';
import {
  AppBar,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Toolbar,
  toolbarClasses,
} from '@rossum/ui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { getAnnotationBacklink } from '../../../components/AnnotationInformation/components/useAnnotationBacklink';
import { SearchButton } from '../../../components/NavBar/SearchButton';
import PoweredBy from '../../../components/UI/PoweredBy';
import UserPanel from '../../../components/UserPanel';
import { isEmbedded } from '../../../constants/config';
import {
  annotationSelector,
  annotationSideloadsSelector,
} from '../../../redux/modules/annotation/selectors';
import { pauseValidation } from '../../../redux/modules/beforeLeave/actions';
import { displaySearchPanel } from '../../../redux/modules/search/actions';
import { leaveValidation } from '../../../redux/modules/ui/actions';
import { State } from '../../../types/state';
import { useQuickSearchContext } from '../../quick-search/QuickSearchContext';
import { getBoxCoordinates } from '../document-store/documentGeometry';
import {
  useCanvasGeometryActions,
  useDocumentStore,
  useDocumentStoreContext,
} from '../document-store/DocumentStore';
import { visiblePageZustandSelector } from '../document-store/documentStoreSelectors';
import { DocumentContextActions } from './DocumentContextActions';
import { DocumentInformation } from './DocumentInformation';
import { PageNavigation } from './PageNavigation';

const TOOLBAR_ZOOM_FACTOR = 0.2;

export const TopBarV2 = () => {
  const dispatch = useDispatch();

  const matrixActions = useCanvasGeometryActions();

  // <Ex-DocumentToolbar actions>
  const handleZoomIn = useCallback(() => {
    matrixActions.zoomBy(+TOOLBAR_ZOOM_FACTOR, undefined);
  }, [matrixActions]);

  const handleZoomOut = useCallback(() => {
    matrixActions.zoomBy(-TOOLBAR_ZOOM_FACTOR, undefined);
  }, [matrixActions]);

  const handleResetZoom = useCallback(() => {
    matrixActions.resetZoom();
  }, [matrixActions]);

  const showingDocumentSearch = useSelector(
    (state: State) => state.search.shouldShow
  );
  const toggleDocumentSearch = useCallback(() => {
    dispatch(displaySearchPanel(!showingDocumentSearch));
  }, [dispatch, showingDocumentSearch]);
  // </Ex-DocumentToolbar actions>

  // <QuickSearch>
  const { setOpen } = useQuickSearchContext();

  const openQuickSearch = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  // </QuickSearch>

  // <Annotation document + queue + workspace data>
  // TODO: Get rid of Redux here
  const currentAnnotation = useSelector(annotationSelector);
  const annotationSideloads = useSelector(annotationSideloadsSelector);
  const annotationDocument = annotationSideloads.document;
  const annotationQueue = annotationSideloads.queue;
  const annotationWorkspace = annotationSideloads.workspace;
  // </Annotation document + queue + workspace data>

  // <Annotation stack data>
  const annotationStack = useSelector((state: State) => state.stack);
  const totalAnnotations = annotationStack.length;
  const currentAnnotationIndex = currentAnnotation
    ? annotationStack.indexOf(currentAnnotation?.id)
    : -1;
  // </Annotation stack data>

  const leaveAnnotation = useCallback(() => {
    if (isEmbedded()) {
      dispatch(leaveValidation());
    } else {
      const backArrowLink = getAnnotationBacklink();
      dispatch(
        pauseValidation({
          nextAction: push(backArrowLink),
          trigger: 'cancelAnnotation',
          reason: 'surveys',
        })
      );
    }
  }, [dispatch]);

  // <Page scrolling>
  const pages = useSelector((state: State) => state.pages.pages);

  const { dimensions } = useDocumentStoreContext();

  const { pageNumber: visiblePageNumber } = useDocumentStore(
    visiblePageZustandSelector(dimensions)
  );

  const handleScrollToPage = useCallback(
    (page: number) => {
      const pageDimension = dimensions.pages.find(p => p.pageNumber === page);

      if (pageDimension) {
        matrixActions.translateIntoViewport(
          getBoxCoordinates(
            page,
            {
              // a third chosen pseudorandomly so that top of the page is on top of the viewport in most cases
              // could probably be done better
              x: pageDimension.dimensions.width / 3,
              y: pageDimension.dimensions.height / 3,
              width: 42,
              height: 69,
            },
            dimensions.pages
          )
        );
      }
    },
    [dimensions.pages, matrixActions]
  );
  // </Page scrolling>

  return (
    <AppBar position="static" sx={{ zIndex: 100 }}>
      <Toolbar
        variant="dense"
        component={Paper}
        elevation={0}
        square
        sx={{
          border: '1px solid',
          // TODO: Should be `other.divider` once available
          borderColor: theme => theme.palette.other.muted,
          [`&.${toolbarClasses.root}`]: {
            px: 1,
            py: 0,
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            alignItems="center"
            sx={{ flex: '1 0 35%', overflow: 'hidden' }}
          >
            <IconButton
              size="medium"
              color="secondary"
              onClick={leaveAnnotation}
            >
              <SvgIcon fontSize="small">
                <IconArrowLeft />
              </SvgIcon>
            </IconButton>
            <DocumentInformation
              document={annotationDocument}
              queue={annotationQueue}
              workspace={annotationWorkspace}
              annotationsInStack={totalAnnotations}
              currentAnnotationIndex={currentAnnotationIndex}
            />
            <DocumentContextActions
              id="document-context-actions-button"
              document={annotationDocument}
              annotation={currentAnnotation}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              px: 2,
              flex: '1 0 20%',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <PageNavigation
              currentPage={visiblePageNumber}
              totalPages={pages.length}
              onNavigate={handleScrollToPage}
            />
            <IconButton size="medium" color="secondary" onClick={handleZoomIn}>
              <SvgIcon fontSize="small">
                <IconZoomIn />
              </SvgIcon>
            </IconButton>
            <IconButton size="medium" color="secondary" onClick={handleZoomOut}>
              <SvgIcon fontSize="small">
                <IconZoomOut />
              </SvgIcon>
            </IconButton>
            <IconButton
              size="medium"
              color="secondary"
              onClick={handleResetZoom}
            >
              <SvgIcon fontSize="small">
                <IconArrowAutofitWidth />
              </SvgIcon>
            </IconButton>
            <IconButton
              size="medium"
              color="secondary"
              onClick={toggleDocumentSearch}
            >
              <SvgIcon fontSize="small">
                <IconFileSearch />
              </SvgIcon>
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              flex: '1 0 35%',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <PoweredBy />
            <SearchButton onClick={openQuickSearch} />
            <UserPanel />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
