import { WithEtag } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Schema } from '@rossum/api-client/schemas';
import {
  IconCirclePlus,
  IconToggleLeft,
  IconToggleRight,
} from '@rossum/ui/icons/tabler';
import {
  Button,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { Header } from '../../../../ui/header/Header';
import { ItemTile } from '../../../../ui/item-tile/ItemTile';
import { ItemTileDescription } from '../../../../ui/item-tile/ItemTileDescription';
import { visibleActions } from '../../../rules/actions';
import { QueueSettingsBreadcrumbs } from '../../components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../../components/QueueSettingsTabs';
import { useListRules } from '../hooks/useListRules';
import { usePatchRule } from '../hooks/usePatchRule';

const RulesPageEmpty = ({ createRuleUrl }: { createRuleUrl: string }) => {
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  return (
    <Stack spacing={2} textAlign="center" alignItems="center">
      <IconCirclePlus
        onClick={() => history.push(createRuleUrl)}
        stroke={1}
        size={64}
        color={theme.palette.secondary.main}
        cursor="pointer"
      />
      <Stack spacing={1}>
        <Typography variant="h5" color="text.secondary">
          {intl.formatMessage({
            id: 'features.queueSettings.rules.list.empty.title',
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage(
            {
              id: 'features.queueSettings.rules.list.empty.description',
            },
            {
              link: (chunks: ReactNode[]) => (
                <Link
                  to={createRuleUrl}
                  style={{
                    textDecoration: 'underline',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {chunks}
                </Link>
              ),
            }
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

type RulesPageProps = {
  schema: WithEtag<Schema>;
  queue: Queue;
  parentPath: string;
};

export const RulesPage = ({ schema, queue, parentPath }: RulesPageProps) => {
  const intl = useIntl();
  const { data: rules, isLoading } = useListRules(schema.url);

  const createRuleUrl = useMemo(
    () => `${parentPath}/rules/new#copilot`,
    [parentPath]
  );

  const { mutate: patchRule, isLoading: isPatching } = usePatchRule();

  const history = useHistory();

  const onBackButtonClicked = useCallback(() => {
    if (history.length > 1) {
      return history.goBack();
    }

    return history.push(`${parentPath}/basic`);
  }, [history, parentPath]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          tabs={<QueueSettingsTabs value="rules" parentPath={parentPath} />}
          onBackButtonClicked={onBackButtonClicked}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              breadcrumbs={[
                {
                  label: 'Rules',
                  to: `${parentPath}/rules`,
                },
              ]}
              queueName={queue.name}
              settingsPath={parentPath}
            />
          }
          buttons={[
            <Button
              key="create-rule"
              variant="contained"
              component={Link}
              to={createRuleUrl}
              data-cy="add-rule-button"
            >
              {intl.formatMessage({
                id: 'features.queueSettings.rules.list.addRule',
              })}
            </Button>,
          ]}
          title={intl.formatMessage({
            id: 'features.queueSettings.rules.list.title',
          })}
          description={intl.formatMessage({
            id: 'features.queueSettings.rules.list.description',
          })}
        />
      )}
    >
      {isLoading ? (
        <Stack p={4} spacing={0.5}>
          <Skeleton variant="rectangular" height={56} width="100%" />
          <Skeleton variant="rectangular" height={56} width="100%" />
          <Skeleton variant="rectangular" height={56} width="100%" />
        </Stack>
      ) : rules && rules.results.length ? (
        <Stack p={4} spacing={0.5}>
          {rules.results.map(rule => (
            <ItemTile
              data-id={rule.id}
              data-cy="rule-tile"
              key={rule.id}
              to={`rules/${rule.id}/detail`}
              quickActions={
                <IconButton
                  disabled={isPatching}
                  size="small"
                  onClick={() =>
                    patchRule({
                      patchedRuleId: rule.id,
                      patchedRule: { ...rule, enabled: !rule.enabled },
                    })
                  }
                >
                  {rule.enabled ? (
                    <SvgIcon color="success">
                      <IconToggleRight />
                    </SvgIcon>
                  ) : (
                    <SvgIcon>
                      <IconToggleLeft />
                    </SvgIcon>
                  )}
                </IconButton>
              }
            >
              <ItemTileDescription
                data-cy="rule-tile-description"
                description={rule.description}
                label={rule.name}
                isFaded={!rule.enabled}
                to={`rules/${rule.id}/detail`}
              />
              <Stack
                direction="row"
                alignItems="center"
                mx={8}
                spacing={1}
                color="text.secondary"
              >
                {rule.ruleActions.map(action => {
                  const schemaAction = action.action;
                  const knownAction = schemaAction
                    ? visibleActions.find(a => a.resolveAction(schemaAction))
                    : undefined;
                  const Icon = knownAction?.icon;
                  return Icon ? (
                    <Tooltip
                      title={intl.formatMessage({
                        id: `features.queueSettings.rules.actions.${knownAction.intlKey}.name`,
                      })}
                    >
                      <Icon fontSize="small" color="inherit" />
                    </Tooltip>
                  ) : null;
                })}
              </Stack>
            </ItemTile>
          ))}
        </Stack>
      ) : (
        <Stack flex={0.8} p={4} justifyContent="center">
          <RulesPageEmpty createRuleUrl={createRuleUrl} />
        </Stack>
      )}
    </PageLayoutV2>
  );
};
