import { ExtensionEvent, FunctionRuntime } from '@rossum/api-client/hooks';
import { fromEntries } from 'remeda';
import { Url } from '../../../../types/basic';
import { HookFormObjectValue } from '../../../../types/CustomTypes/HookFormObject';
import {
  Extension,
  ExtensionType,
  SideloadValues,
} from '../../../../types/extensions';
import { isPublicFunction, isPublicWebhook } from '../../helpers';

export type ExtensionData = {
  active: boolean;
  description: string;
  events: Array<ExtensionEvent>;
  name: string;
  queues: Array<Url>;
  secret?: string;
  schedule?: string;
  settings: HookFormObjectValue;
  settingsSchema?: HookFormObjectValue;
  secrets?: HookFormObjectValue;
  secretsSchema?: HookFormObjectValue;
  sideload: Array<SideloadValues>;
  tokenOwner: Url;
  type: ExtensionType;
  url: string;
  runtime: FunctionRuntime | undefined;
  payloadLoggingEnabled: boolean;
};

export const NO_CHANGE_SECRET_PLACEHOLDER = '__change_me__';

export const getSecretsWithPlaceholders = ({
  settingsSchemaProperties,
  secretsSchemaProperties,
}: {
  secretsSchemaProperties?: unknown;
  settingsSchemaProperties?: unknown;
}) =>
  fromEntries(
    (secretsSchemaProperties
      ? Object.entries(secretsSchemaProperties)
      : Object.entries(settingsSchemaProperties || {}).filter(
          ([_, details]) => details?.['x-rossum-hook-secret'] === true
        )
    ).map(([secretName]) => [secretName, NO_CHANGE_SECRET_PLACEHOLDER])
  );

export const getDefaultValues = (extension: Extension) => {
  const extensionIsPublicFunction = isPublicFunction(extension);
  const isWebhookPublic = isPublicWebhook(extension);

  return {
    active: extension.active,
    name: extension.name,
    description: extension.description,
    events: extension.events,
    queues: extension.queues,
    runtime: extensionIsPublicFunction ? extension.config.runtime : undefined,
    type: extension.type,
    private: extension.config.private,
    url: isWebhookPublic ? extension.config.url : '',
    secret: isWebhookPublic ? extension.config.secret : '',
    sideload: extension.sideload,
    settings: extension.settings,
    secrets: getSecretsWithPlaceholders({
      settingsSchemaProperties: extension.settingsSchema?.properties,
      secretsSchemaProperties: extension.secretsSchema?.properties,
    }),
    tokenOwner: extension.tokenOwner,
    schedule: extension.config.schedule?.cron ?? '',
    payloadLoggingEnabled: extension.config.payloadLoggingEnabled ?? false,
  };
};
