import { Button, ButtonProps, ListItem } from '@rossum/ui/material';
import { useCallback } from 'react';
import { SidebarButtonFieldModel } from '../useSidebarData';

export type ButtonItemProps = Omit<ButtonProps, 'onClick'> & {
  item: SidebarButtonFieldModel;
  onClick: (
    id: number,
    popupUrl?: string | null,
    canObtainToken?: boolean
  ) => void;
};

export const ButtonItem = ({
  item,
  onClick,
  ...buttonProps
}: ButtonItemProps) => {
  const handleClick = useCallback(() => {
    onClick(item.id, item.url, item.canObtainToken);
  }, [item.canObtainToken, item.id, item.url, onClick]);

  return (
    <ListItem sx={{ justifyContent: 'flex-end', pr: 1 }}>
      <Button
        variant="outlined"
        color="secondary"
        {...buttonProps}
        onClick={handleClick}
      >
        {item.label}
      </Button>
    </ListItem>
  );
};
