import { ListItem, ListItemProps, Stack } from '@rossum/ui/material';
import { paperBackgroundMap } from '@rossum/ui/theme';
import { useEffect, useRef } from 'react';
import { SIDEBAR_TOPBAR_HEIGHT } from '../../sidebar-topbar/SidebarTopbar';

const SCROLL_TOP_OFFSET = 100;

type MagicContainerProps = ListItemProps & {
  sidebarScrollableRef: HTMLDivElement | null;
  active: boolean;
  focused?: boolean;
  selected?: boolean;
  disabled: boolean;
  hasMagicLine?: boolean;
};

export const MagicContainer = ({
  sidebarScrollableRef,
  active,
  focused,
  selected,
  disabled,
  hasMagicLine = true,
  children,
  ...restListItemProps
}: MagicContainerProps) => {
  const { sx: listItemSx } = restListItemProps;

  const ref = useRef<HTMLLIElement | null>(null);

  // TODO: @sidebarV2 Constants?
  useEffect(() => {
    if (active && ref.current && sidebarScrollableRef) {
      ref.current.style.position = 'relative';
      const { offsetTop } = ref.current;
      ref.current.style.position = 'sticky';

      sidebarScrollableRef.scrollTo({
        top: offsetTop - SCROLL_TOP_OFFSET,
        behavior: 'smooth',
      });
    }
  }, [active, sidebarScrollableRef]);

  return (
    <ListItem
      {...restListItemProps}
      ref={ref}
      id={hasMagicLine && active ? 'magic-line-source' : undefined}
      style={{
        position: active ? 'sticky' : 'relative',
        cursor: active ? 'default' : 'pointer',
      }}
      sx={{
        ...listItemSx,
        py: active ? 1 : 0,
        // fancy, because magic
        transition: theme =>
          theme.transitions.create(['padding-top', 'padding-bottom'], {
            duration: 200,
            easing: theme.transitions.easing.easeInOut,
          }),
        pl: 2,
        pr: 1,
        backgroundColor: theme => theme.palette.background.paper,
        ...(active || selected
          ? {
              backgroundImage: theme =>
                `linear-gradient(${theme.palette.action.selected}, ${theme.palette.action.selected})`,
              top: `-${SIDEBAR_TOPBAR_HEIGHT}px`,
              bottom: 0,
              zIndex: 2,
            }
          : focused
            ? {
                // TODO: @ui put paper backgrounds in theme
                backgroundColor: theme =>
                  paperBackgroundMap[theme.palette.mode][4] ?? 'transparent',
              }
            : {}),
        ...(active
          ? disabled
            ? {
                boxShadow: theme =>
                  `inset 4px 0 0 ${theme.palette.action.disabled}`,
              }
            : {
                boxShadow: theme =>
                  `inset 4px 0 0 ${theme.palette.primary.main}`,
              }
          : {}),
      }}
    >
      <Stack spacing={1} sx={{ width: '100%' }}>
        {children}
      </Stack>
    </ListItem>
  );
};
