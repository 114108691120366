import { findLastIndex, sortBy, sum } from 'lodash';
import { mapValues, range } from 'remeda';
import { clock } from './clock';
import { TimeInterval } from './timers';

type InactiveIntervals = {
  // A list of [start, end, start, end, ...] timestamps.
  // The last interval can be unfinished, that's why the type isn't [number, number][]
  intervals: number[];

  // We can avoid tracking intervals that are too short
  minLength: number;
};

const data: Record<string, InactiveIntervals> = {};

const calculateOverlap = (a: TimeInterval, b: TimeInterval) => {
  if (a[1] <= b[0] || a[0] >= b[1]) return 0;

  return Math.min(a[1], b[1]) - Math.max(a[0], b[0]);
};

// Intervals come in pairs. An odd number indicates the last interval wasn't finished yet.
const isStarted = (data: InactiveIntervals) => data.intervals.length % 2 === 1;

export const inactiveIntervals = {
  snapshot: () => {
    return mapValues(data, v => ({
      intervals: [...v.intervals],
      minLength: v.minLength,
    }));
  },

  clear: () => {
    Object.keys(data).forEach(k => delete data[k]);
  },

  init: (name: string, options: { minLength: number }) => {
    data[name] = { intervals: [], minLength: options.minLength };
  },

  start: (name: string) => {
    if (!data[name]) data[name] = { intervals: [], minLength: 0 };

    // Ignore multiple starts
    if (isStarted(data[name])) {
      return;
    }

    data[name].intervals.push(clock.now());
  },

  isStarted: (name: string) => {
    return data[name] && isStarted(data[name]);
  },

  stop: (name: string): TimeInterval | undefined => {
    const d = data[name];

    // Ignore multiple stops, or stops for a name that doesn't exist
    if (!d || !isStarted(d)) return undefined;

    const start = d.intervals[d.intervals.length - 1];
    const end = clock.now();

    if (end === start || end - start < d.minLength) {
      d.intervals.pop();
      return undefined;
    }

    d.intervals.push(end);
    return [start, end];
  },

  // Calculate total inactive time, taking into account overlaps
  // and ignoring intervals that are too short.
  getTotalInactiveTimeIn: (interval: TimeInterval) => {
    const now = clock.now();

    // TODO this can be done more effectively
    const sortedByStartTime = sortBy(
      Object.values(data).flatMap(v =>
        range(0, Math.ceil(v.intervals.length / 2))
          .map(
            i =>
              [
                v.intervals[2 * i],
                v.intervals[2 * i + 1] ?? now,
              ] as TimeInterval
          )
          .filter(([start, end]) => end - start >= v.minLength)
      ),
      ([start, _]) => start
    );

    const merged = sortedByStartTime.reduce<TimeInterval[]>((acc, cur) => {
      if (acc.length === 0) {
        return [cur];
      }

      const last = acc[acc.length - 1];

      if (cur[0] > last[1]) {
        acc.push(cur);
      } else {
        last[1] = Math.max(last[1], cur[1]);
      }

      return acc;
    }, []);

    return sum(merged.map(m => calculateOverlap(m, interval)));
  },

  clearBefore: (timeStamp: number, name: string) => {
    if (!data[name]) {
      return;
    }

    const intervalsData = data[name].intervals;

    const lastClosedIntervalIndex = findLastIndex(
      intervalsData,
      (ts, index) => ts <= timeStamp && index % 2 === 1
    );

    if (lastClosedIntervalIndex === -1) {
      return;
    }

    data[name].intervals = intervalsData.slice(lastClosedIntervalIndex + 1);
  },
};
