import { escapeRegExp, initial, last } from 'lodash';
import { usersPath } from '../../../containers/Users/helpers';
import { Organization } from '../../../types/organization';
import { User } from '../../../types/user';

export const getName = ({
  firstName,
  lastName,
  username,
  email,
}: Partial<User> = {}) =>
  firstName || lastName ? `${firstName} ${lastName}`.trim() : username || email;

export const splitFullName = (name: string) => {
  const names = name.split(' ');
  const lastName = last(names) ?? '';
  const firstName = initial(names).join(' ');

  return { firstName, lastName };
};

export const pathnameRequiresUsers = ({ pathname }: { pathname: string }) =>
  pathname.includes('/statistics') ||
  pathname.includes('/settings/extensions') ||
  pathnameContainsUsers({ pathname });

export const pathnameContainsQueueUsers = (pathname: string) =>
  !!pathname.match('/queues/\\d+/settings/access');

export const pathnameContainsUsers = ({ pathname }: { pathname: string }) =>
  pathname.includes(usersPath()) || pathnameContainsQueueUsers(pathname);

export const filterUserOrganizations = (
  search: string,
  userOrganizations: Organization[]
) =>
  userOrganizations.filter(
    ({ name, id }: Organization) =>
      `${name} ${id}`.toUpperCase().search(escapeRegExp(search.toUpperCase())) >
      -1
  );
