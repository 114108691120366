import { Url } from '@rossum/api-client';
import { useMemo } from 'react';
import { uniqueBy } from 'remeda';
import { useTransformAnnotations } from '../../document-list-base/hooks/useTransformAnnotation';
import { getFilterItemForExcludeAnnotations } from '../helpers';
import { useFilteredAnnotations } from './useFilteredAnnotations';
import { useSenderEmail } from './useSenderEmail';

export const useFetchSuggestedAttachments = ({
  rootAnnotationUrl,
  existingAttachments,
}: {
  rootAnnotationUrl: Url;
  existingAttachments: Url[];
}) => {
  const defaultQuery = { pageSize: 100 };

  const {
    data: response,
    isInitialLoading,
    isError,
  } = useFilteredAnnotations({
    sideloadRelations: true,
    uniqKey: rootAnnotationUrl,
    query: { ...defaultQuery },
    filtering: {
      items: [
        {
          field: 'annotation',
          value: rootAnnotationUrl,
          operator: 'equals',
        },
      ],
    },
  });

  const siblingRelations = response?.relations?.filter(r => r.type === 'edit');

  const siblingAnnotationsUrls =
    siblingRelations?.length &&
    siblingRelations.find(r => r.annotations.find(a => a === rootAnnotationUrl))
      ?.annotations;

  const annotation = response?.results.find(r => r.url === rootAnnotationUrl);
  const { emailThread, email: emailUrl } = annotation || {};

  const emailSender = useSenderEmail(emailUrl || '');

  const excludedAnnotationsForFilter = useMemo(
    () => [
      getFilterItemForExcludeAnnotations(
        rootAnnotationUrl,
        existingAttachments
      ),
      { field: 'relations__type', value: 'attachment', operator: 'not' },
    ],
    [rootAnnotationUrl, existingAttachments]
  );

  const {
    data: responseFromEmailThread,
    isInitialLoading: fromEmailThreadIsLoading,
    isError: hasEmailThreadFailed,
  } = useFilteredAnnotations({
    uniqKey: emailThread ? `${rootAnnotationUrl}-${emailThread}` : null,
    query: defaultQuery,
    filtering: {
      items: [
        ...excludedAnnotationsForFilter,
        {
          field: 'email_thread',
          value: emailThread,
          operator: 'equals',
        },
      ],
    },
  });

  const {
    data: responseSiblings,
    isInitialLoading: siblingsIsLoading,
    isError: hasSiblingsFailed,
  } = useFilteredAnnotations({
    uniqKey:
      siblingAnnotationsUrls && !!siblingAnnotationsUrls.length
        ? `${rootAnnotationUrl}-${siblingAnnotationsUrls.map(a => a).join('-')}`
        : null,
    query: defaultQuery,
    filtering: {
      items: [
        ...excludedAnnotationsForFilter,
        {
          field: 'annotation',
          value: siblingAnnotationsUrls,
          operator: 'isAnyOf',
        },
      ],
    },
  });

  const {
    data: responseSameEmailSender,
    isInitialLoading: sameEmailSenderIsLoading,
    isError: hasEmailSenderFailed,
  } = useFilteredAnnotations({
    uniqKey: emailSender ? `${rootAnnotationUrl}-${emailSender}` : null,
    query: defaultQuery,
    filtering: {
      items: [
        ...excludedAnnotationsForFilter,
        {
          field: 'email_sender',
          value: emailSender || '',
          operator: 'equals',
        },
      ],
    },
  });

  const fromEmailThread = {
    annotations: responseFromEmailThread?.results || [],
    documents: responseFromEmailThread?.documents || [],
    modifiers: responseFromEmailThread?.modifiers || [],
  };

  const siblings = {
    annotations: responseSiblings?.results || [],
    documents: responseSiblings?.documents || [],
    modifiers: responseSiblings?.modifiers || [],
  };

  const sameEmailSender = {
    annotations: responseSameEmailSender?.results || [],
    documents: responseSameEmailSender?.documents || [],
    modifiers: responseSameEmailSender?.modifiers || [],
  };

  const annotations = uniqueBy(
    [
      ...fromEmailThread.annotations,
      ...siblings.annotations,
      ...sameEmailSender.annotations,
    ],
    a => a.id
  );

  const documents = uniqueBy(
    [
      ...fromEmailThread.documents,
      ...siblings.documents,
      ...sameEmailSender.documents,
    ],
    d => d.id
  );
  const modifiers = uniqueBy(
    [
      ...fromEmailThread.modifiers,
      ...siblings.modifiers,
      ...sameEmailSender.modifiers,
    ],
    m => m.id
  );

  const { transformedAnnotations, isLoading: isTransforming } =
    useTransformAnnotations({
      annotations: annotations.length ? annotations : undefined,
      documents: documents.length ? documents : undefined,
      modifiers: modifiers.length ? modifiers : undefined,
    });

  return {
    suggestedAttachments: transformedAnnotations,
    isLoading:
      isInitialLoading ||
      isTransforming ||
      fromEmailThreadIsLoading ||
      siblingsIsLoading ||
      sameEmailSenderIsLoading,
    isError:
      isError ||
      hasEmailSenderFailed ||
      hasEmailThreadFailed ||
      hasSiblingsFailed,
  };
};
