import { includes } from 'lodash';
import { combineEpics } from 'redux-observable';
import { isTruthy, omit } from 'remeda';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { AnyDatapointSchema, Schema } from '../../../types/schema';
import { fetchQueueFulfilled } from '../queues/actions';
import { currentQueueFromPathnameSelector } from '../queues/selector';
import { enterQueue, enterQueueEditor, leaveValidation } from '../ui/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  fetchSchema,
  fetchSchemaFulfilled,
  updateSchema,
  updateSchemaContent,
  updateSchemaFulfilled,
  validateSchema,
  validateSchemaFulfilled,
} from './actions';
import { flattenSchema } from './helpers';
import {
  SchemaBeforeFlattening,
  ValidateSchemaFulfilledPayload,
} from './types';

const fetchSchemaEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchSchema)),
    mergeMap(({ meta: { schemaUrl } }) => {
      const snakeCase = includes(
        state$.value.router.location.pathname,
        '/schema'
      );
      return authGetJSON$<SchemaBeforeFlattening>(`${schemaUrl}`, {
        getRawResponse: snakeCase,
      }).pipe(
        takeUntil(action$.pipe(filter(isActionOf(leaveValidation)))),
        map(flattenSchema),
        map(fetchSchemaFulfilled),
        catchError(errorHandler)
      );
    })
  )
);

const updateSchemaEpic = makeEpic((action$, state$, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf(updateSchema)),
    mergeMap(({ payload: schema, meta }) => {
      const snakeCase = includes(
        state$.value.router.location.pathname,
        '/schema'
      );
      // TODO: we do not even have proper type for getRawResponse: true case - snake_cased version of schema so the type is not accurate
      // probably doesn't have a sense to make it correct, better to move to api-client completely
      return authPatch$<SchemaBeforeFlattening>(
        schema.url,
        omit(schema, ['metadata']),
        {
          getRawResponse: snakeCase,
        }
      ).pipe(
        map(flattenSchema),
        map(flattenedSchema => updateSchemaFulfilled(flattenedSchema, meta)),
        catchError(errorHandler)
      );
    })
  )
);

const updateSchemaContentEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateSchemaContent)),
    map(({ payload: schema, meta }) => ({
      ...(state$.value.schema as Schema),
      content: schema as unknown as Array<AnyDatapointSchema>,
      meta,
    })),
    map(({ meta, ...schema }) => updateSchema(schema, meta))
  )
);

const fetchQueueDetailSchema = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchQueueFulfilled)),
    pluck('payload', 'schema'),
    filter(
      () => !includes(state$.value.router.location.pathname, '/annotations')
    ),
    map(schemaUrl => fetchSchema(schemaUrl))
  )
);

const fetchQueueDetailSchemaEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf([enterQueue, enterQueueEditor])),
    map(() => state$.value),
    map(currentQueueFromPathnameSelector),
    filter(isNotNullOrUndefined),
    map(({ schema }) => fetchSchema(schema))
  )
);

const validateSchemaEpic = makeEpic((action$, _, { authPost$ }) =>
  action$.pipe(
    filter(isActionOf(validateSchema)),
    pluck('payload'),
    filter(isTruthy),
    debounceTime(500),
    switchMap(payload =>
      authPost$<ValidateSchemaFulfilledPayload>(
        `${apiUrl}/schemas/validate`,
        payload
      ).pipe(
        map(response => validateSchemaFulfilled(response, payload.content)),
        catchError(errorHandler)
      )
    )
  )
);

export default combineEpics(
  fetchQueueDetailSchema,
  fetchQueueDetailSchemaEpic,
  fetchSchemaEpic,
  updateSchemaContentEpic,
  updateSchemaEpic,
  validateSchemaEpic
);
