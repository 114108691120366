import { MoreVert, OpenInNew } from '@rossum/ui/icons';
import {
  Button,
  Dialog,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { chain } from 'lodash';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CLI_HELP_LINK } from '../../../constants/values';
import { boldText } from '../../../lib/formaterValues';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import {
  deleteAllDatapoints,
  discardSuggestions,
  suggestTable,
} from '../../../redux/modules/datapoints/actions';
import { hasSuggestionsSelector } from '../../../redux/modules/datapoints/suggestedOperations/selector';
import { hideArticleLinksSelector } from '../../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../../redux/modules/ui/selectors';
import { MultivalueDatapointDataST } from '../../../types/datapoints';
import { State } from '../../../types/state';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import ActionDialog from '../../UI/ActionDialog';
import ActionDialogActions from '../../UI/ActionDialog/ActionDialogActions';
import { useCurrentPage } from './SuggestButton/useCurrentPage';

type ContextMenuProps = {
  currentMultivalue: MultivalueDatapointDataST;
  openSettings: () => void;
  amount: number;
};

export const ContextMenu = ({
  currentMultivalue,
  openSettings,
  amount,
}: ContextMenuProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const dispatch = useDispatch();

  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const newSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');

  const hideArticleLinks = useSelector(hideArticleLinksSelector);

  const pages = useSelector((state: State) => state.pages.pages ?? []);

  const { pageIndex: visiblePageIndex, pageNumber: currentPage } =
    useCurrentPage();
  const waitingForSuggestions = useSelector(
    (state: State) => state.datapoints.waitingForSuggestions
  );

  const discardSuggestionsCallback = useCallback(() => {
    dispatch(discardSuggestions());
  }, [dispatch]);

  const canDiscardSuggestions = useSelector(hasSuggestionsSelector);

  const suggestNextPages = useCallback(() => {
    if (!waitingForSuggestions && currentPage) {
      const pagesChunks = chain(pages)
        .slice(visiblePageIndex)
        .map(page => page.number)
        .filter(isNotNullOrUndefined)
        .chunk(5)
        .value();

      dispatch(
        suggestTable({
          multivalueId: currentMultivalue.id,
          pagesChunks,
        })
      );
    }
  }, [
    currentMultivalue.id,
    currentPage,
    dispatch,
    pages,
    visiblePageIndex,
    waitingForSuggestions,
  ]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onDeleteAll = () => {
    dispatch(deleteAllDatapoints(currentMultivalue.meta.index));
  };

  const onCloseDeleteDialog = () => setOpenDeleteDialog(false);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        data-cy="line-items-context-menu"
        sx={{ p: '5px', minWidth: '36px' }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </Button>
      <Menu
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{
          dense: true,
          id: 'line-items-context-menu',
        }}
      >
        {complexLineItemsEnabled && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              suggestNextPages();
            }}
            data-cy="line-items-suggest-next-pages"
          >
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.lineItems.splitButton.other.suggestNext',
            })}
          </MenuItem>
        )}
        {complexLineItemsEnabled && (
          <MenuItem
            disabled={!canDiscardSuggestions}
            onClick={() => {
              setAnchorEl(null);
              discardSuggestionsCallback();
            }}
            data-cy="line-items-reject-suggestions"
          >
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.lineItems.contextMenu.rejectSuggestions',
            })}
          </MenuItem>
        )}
        <MenuItem
          data-cy="line-items-delete-all-btn"
          disabled={amount < 1}
          onClick={() => {
            setAnchorEl(null);
            return setOpenDeleteDialog(true);
          }}
        >
          {intl.formatMessage({
            id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.confirm.button',
          })}
        </MenuItem>
        {newSidebarEnabled ? null : (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              openSettings();
            }}
            data-cy="line-items-open-settings"
          >
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.lineItems.contextMenu.settings',
            })}
          </MenuItem>
        )}
        {complexLineItemsEnabled && !hideArticleLinks && (
          <MenuItem
            component="a"
            href={CLI_HELP_LINK}
            target="_blank"
            data-cy="line-items-context-menu-help"
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography fontSize="inherit">
                {intl.formatMessage({
                  id: 'components.documentValidation.sidebar.lineItems.contextMenu.help',
                })}
              </Typography>
              <OpenInNew fontSize="inherit" />
            </Stack>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openDeleteDialog}
        PaperProps={{
          sx: { width: 480, minHeight: 220, position: 'fixed' },
          elevation: 2,
        }}
        onClose={onCloseDeleteDialog}
        sx={{ transition: 'smooth' }}
      >
        <ActionDialog
          dialogTitle={intl.formatMessage({
            id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.title',
          })}
          onCancel={onCloseDeleteDialog}
          dialogColor="primary"
          dialogActions={
            <ActionDialogActions
              confirmButtonProps={{
                onClick: () => {
                  onCloseDeleteDialog();
                  onDeleteAll();
                },
                color: 'primary',
              }}
              confirmButtonText={intl.formatMessage({
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.confirm.button',
              })}
              cancelButtonText={intl.formatMessage({
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.cancel.button',
              })}
              dataCyConfirm="line-items-delete-all-confirm-btn"
              dataCyCancel="line-items-delete-all-cancel-btn"
            />
          }
          dataCy="close-dialog-btn"
        >
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: 'components.documentValidation.sidebar.lineItems.deleteAll.modal.text',
              },
              {
                boldText,
                amount,
              }
            )}
          </Typography>
        </ActionDialog>
      </Dialog>
    </>
  );
};
