import {
  IconDotsVertical,
  IconEdit,
  IconInfoCircle,
  IconRefresh,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import {
  Box,
  IconButton,
  Menu,
  SvgIcon,
  SvgIconProps,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NonAdminRestrictor } from '../../../../../components/Restrictors';
import {
  setPendingValidationForFormulaDatapoint,
  updateDatapointValue,
} from '../../../../../redux/modules/datapoints/actions';
import {
  SchemaFieldIcon,
  SchemaFieldIconType,
  SchemaFieldIconVariant,
} from '../../../../../ui/schema-field-icon/SchemaFieldIcon';
import {
  isEditableFormulaField,
  isEditedFormulaField,
} from '../../formula-helpers';
import { Item, ItemWithValue } from '../utils';
import { ContextActionItem } from './ContextActionItem';
import { useFieldDetailsDialog } from './useFieldDetailsDialog';

type ItemContextActionsProps = {
  item: Item;
  active: boolean;
  disabled: boolean;
  iconProps: {
    type: SchemaFieldIconType;
    variant: SchemaFieldIconVariant;
    color: SvgIconProps['color'];
  };
  fieldSettingsPath: string | null;
};

// TODO: @sidebarV2 resolve name and props - can we derive them all if we pass whole `item`?
export const ItemContextActions = ({
  active,
  iconProps,
  fieldSettingsPath,
  item,
  disabled,
}: ItemContextActionsProps) => {
  const intl = useIntl();

  const [hovered, setHovered] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMouseEnter = useCallback(() => {
    if (active) {
      setHovered(true);
    }
  }, [active]);

  const handleMouseLeave = useCallback(() => {
    if (!anchorEl) {
      setHovered(false);
    }
  }, [anchorEl]);

  const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(e => {
      setHovered(true);
      setAnchorEl(e.currentTarget);
    }, []);

  const handleCloseMenu = useCallback(() => {
    setHovered(false);
    setAnchorEl(null);
  }, []);

  const dispatch = useDispatch();

  const onReconnectFormulaField = useCallback(
    (item: ItemWithValue) => {
      handleCloseMenu();
      dispatch(setPendingValidationForFormulaDatapoint(item.id));
      dispatch(
        updateDatapointValue(
          {
            id: item.id,
            index: item.meta.datapointIndex,
            validationSource: 'human',
            oldValue: item.value,
            reason: 'reconnect-formula-field',
            noRecalculation: false,
          },
          ''
        )
      );
    },
    [dispatch, handleCloseMenu]
  );

  // TODO: should we reuse `handleDatapointChange` from DocumentSidebar with different `reason`?
  const onDeleteValue = useCallback(
    (item: ItemWithValue) => {
      handleCloseMenu();
      dispatch(
        updateDatapointValue(
          {
            id: item.id,
            index: item.meta.datapointIndex,
            oldValue: item.value,
            validationSource: 'human',
            reason: 'sidebar-delete-value',
            noRecalculation: isEditableFormulaField(
              item.valueSource,
              item.editing
            ),
          },
          ''
        )
      );
    },
    [dispatch, handleCloseMenu]
  );

  const { openFieldDetailsDialog, fieldDetailsAvailable, fieldDetailsDialog } =
    useFieldDetailsDialog(item);

  const handleFieldDetailsDialogOpen = useCallback(() => {
    handleCloseMenu();
    openFieldDetailsDialog();
  }, [openFieldDetailsDialog, handleCloseMenu]);

  return (
    <>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {hovered && active ? (
          // TODO: @sidebarV2 extract to separate component?
          <>
            <IconButton
              size="small"
              color="secondary"
              onClick={handleOpenMenu}
              sx={{ position: 'absolute', top: '11px', left: '11px' }}
            >
              <SvgIcon fontSize="small">
                <IconDotsVertical />
              </SvgIcon>
            </IconButton>
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
            >
              {'value' in item &&
                !disabled &&
                isEditableFormulaField(item.valueSource, item.editing) && (
                  <ContextActionItem
                    icon={<IconRefresh />}
                    onClick={() => onReconnectFormulaField(item)}
                    disabled={
                      !isEditedFormulaField(
                        item.valueSource,
                        'noRecalculation' in item
                          ? item.noRecalculation
                          : undefined
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'components.sidebarV2.itemContextActions.restoreFormulaValue',
                    })}
                  </ContextActionItem>
                )}
              {fieldSettingsPath && (
                <NonAdminRestrictor>
                  <ContextActionItem
                    icon={<IconEdit />}
                    component={Link}
                    to={fieldSettingsPath}
                  >
                    {intl.formatMessage({
                      id: 'components.sidebarV2.itemContextActions.editInFieldsSettings',
                    })}
                  </ContextActionItem>
                </NonAdminRestrictor>
              )}

              {fieldDetailsAvailable && (
                <ContextActionItem
                  icon={<IconInfoCircle />}
                  onClick={handleFieldDetailsDialogOpen}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.itemContextActions.showFieldDetails',
                  })}
                </ContextActionItem>
              )}

              {'value' in item && !disabled && (
                <ContextActionItem
                  icon={<IconTrash />}
                  onClick={() => onDeleteValue(item)}
                  disabled={item.value === ''}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.itemContextActions.deleteValue',
                  })}
                </ContextActionItem>
              )}
            </Menu>
          </>
        ) : (
          <SchemaFieldIcon
            type={iconProps.type}
            variant={iconProps.variant}
            color={iconProps.color}
            fontSize="small"
            sx={{ display: 'block' }}
          />
        )}
      </Box>
      {fieldDetailsDialog}
    </>
  );
};
