import { Button, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { Link as RouterLink } from 'react-router-dom';
import * as animationData from './animations/error404.json';

const NotFound = () => {
  const intl = useIntl();

  return (
    <>
      <Lottie
        loop
        play
        animationData={animationData}
        style={{
          width: '14vw',
          height: '14vw',
        }}
      />
      <Stack width={380} spacing={2}>
        <Typography variant="h5" color="text.secondary" fontWeight="bold">
          {intl.formatMessage({
            id: `containers.errorPage.not-found.title`,
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: `containers.errorPage.not-found.text`,
          })}
        </Typography>
      </Stack>
      <Button
        component={RouterLink}
        to="/documents"
        color="secondary"
        variant="outlined"
        sx={{ mt: 2 }}
        data-cy="error-page-go-to-documents-button"
      >
        {intl.formatMessage({ id: 'containers.errorPage.not-found.button' })}
      </Button>
    </>
  );
};

export default NotFound;
