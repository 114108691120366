import { Tuple } from '../../../../../utils/types';

export const sidebarFooterActions = [
  'postpone',
  'reject',
  'next',
  'previous',
  'move',
  'email',
  'link',
  'download',
  'delete',
] as const;

// Interesting use-case of using `as const`s for form values
type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export const assertMutable = <T>(val: T): Mutable<T> => val;

export type SidebarFooterActionId = (typeof sidebarFooterActions)[number];

export type SidebarFooterAction = {
  id: SidebarFooterActionId;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  dataCy?: string;
};

// Will bitch if we add/remove actions and forget to add them to default
// Probably best we can do, guaranteeing that each action occurs exactly once is not possible in TS
// (without painful recursion that is)
export type SidebarFooterActionsOrder = Tuple<SidebarFooterActionId, 9>;
