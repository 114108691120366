import { Queue } from '@rossum/api-client/queues';
import { find, includes } from 'lodash';
import { State } from '../../../types/state';
import { Workspace } from '../../../types/workspace';

export const workspacesSelector = (state: State) => state.workspaces.list;

export const findWorkspaceByQueue = (
  workspaces: Array<Workspace>,
  { url }: Queue
): Workspace | null | undefined =>
  find(workspaces, ({ queues }) => includes(queues, url));
