import { yupResolver } from '@hookform/resolvers/yup';
import {
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import { Add, InfoOutlined } from '@rossum/ui/icons';
import { Button, CircularProgress, Tooltip } from '@rossum/ui/material';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { useState } from 'react';
import { Controller, useController, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ValidationInput from '../../../../../components/ReactHookForm/ValidationInput';
import ControlledTextArea from '../../../../../components/ReactHookForm/ValidationTextArea';
import {
  docsLinks,
  EXTERNAL_CRONTAB_VALIDATOR,
} from '../../../../../constants/values';
import { EventMultiSelect } from '../../../../../features/extensions/events/components/EventMultiSelect';
import { QueueMultiSelect } from '../../../../../features/queues/select-queue/QueueMultiSelect';
import { italicText, linebreak, link } from '../../../../../lib/formaterValues';
import { CreateExtension } from '../../../../../redux/modules/extensions/actions';
import { Url } from '../../../../../types/basic';
import { ExtensionType, SideloadValues } from '../../../../../types/extensions';
import { Users } from '../../../../../types/users';
import PaperBox from '../../../../../ui/paper-box/PaperBox';
import PaperBoxFooter from '../../../../../ui/paper-box/PaperBoxFooter';
import PaperBoxTitle from '../../../../../ui/paper-box/PaperBoxTitle';
import {
  defaultCode,
  defaultNodejsRuntime,
  defaultPythonRuntime,
  DefaultRuntime,
  runtimesConfig,
} from '../../../../Extension/config';
import { webhookUrlValidation } from '../../../../Extension/helpers';
import FormLabel from '../../../../User/components/FormLabel';
import { validateCronString } from '../../../lib/helpers';
import { useJSONfield } from '../../../lib/useJSONfield';
import styles from '../style.module.sass';
import AdvancedSettings from './AdvancedSettings';
import ExtensionTypeSelect from './ExtensionTypeSelect';
import RuntimeButton from './RuntimeButton';

type Props = {
  backLink?: string;
  defaultExtensionType: ExtensionType | undefined;
  onSubmit: CreateExtension;
  organizationUsers: Users;
};

type NewExtensionData = {
  description: string;
  events: Array<ExtensionEvent>;
  name: string;
  queues: Array<Url>;
  runtime: DefaultRuntime;
  secret: string;
  settings: Record<string, unknown>;
  secrets: Record<string, unknown>;
  sideload: Array<SideloadValues>;
  tokenOwner: Url | null;
  type: ExtensionType;
  schedule: string;
  url: string;
  payloadLoggingEnabled: boolean;
};

const createExtensionValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.name.required',
      })
    ),
    description: yup
      .string()
      .max(
        1000,
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.description.tooLong',
        })
      )
      .notRequired(),
    events: yup
      .array()
      .min(1)
      .required(
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.events.required',
        })
      ),
    schedule: yup
      .string()
      .when('events', (events: ExtensionEvent[], schema: yup.StringSchema) =>
        events.includes('invocation.scheduled')
          ? schema.test(
              'wrongFormat',
              intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.schedule.wrongFormat',
              }),
              validateCronString
            )
          : schema
      ),
    queues: yup.array(),
    type: yup.string().required('required'),
    runtime: yup.string().when('type', {
      is: extensionFunctionType,
      then: yup.string().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    url: yup.string().when('type', {
      is: extensionWebhookType,
      then: webhookUrlValidation(intl),
      otherwise: yup.string().nullable().notRequired(),
    }),
    secret: yup.string().nullable().notRequired(),
    sideload: yup.array(),
    tokenOwner: yup.string().nullable(),
    settings: yup.object(),
  });

const CreateExtensionForm = ({
  backLink,
  defaultExtensionType,
  onSubmit,
  organizationUsers,
}: Props) => {
  const intl = useIntl();

  const [showSecret, setShowSecret] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const defaultFormExtensionType =
    defaultExtensionType ?? extensionFunctionType;

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
    trigger,
  } = useForm<NewExtensionData>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      events: [],
      queues: [],
      sideload: [],
      tokenOwner: null,
      settings: {},
      secrets: {},
      type: defaultFormExtensionType,
      runtime: defaultPythonRuntime,
      url: '',
      secret: '',
      schedule: '',
    },

    resolver: yupResolver(createExtensionValidationSchema(intl)),
  });

  const {
    field: { value: watchType, onChange: onTypeChange },
  } = useController({ name: 'type', control });

  const {
    field: { value: watchRuntime, onChange: onWatchRuntimeChange },
  } = useController({ name: 'runtime', control });

  const {
    field: { value: watchEvents },
  } = useController({ name: 'events', control });

  const onExtensionUpdate = (data: NewExtensionData) => {
    switch (data.type) {
      case extensionWebhookType:
        return onSubmitWebhook(data);
      default:
        return onSubmitFunction(data);
    }
  };

  const onSubmitFunction = (data: NewExtensionData) => {
    setSubmitting(true);

    const shouldUseDefaultSchemaSideload =
      data.sideload.length === 0 &&
      data.events.some(event => event.startsWith('annotation_content.'));

    onSubmit({
      type: extensionFunctionType,
      name: data.name,
      description: data.description,
      config: {
        code: data.runtime
          ? defaultCode[data.runtime]
          : defaultCode[defaultNodejsRuntime],
        runtime: data.runtime || defaultNodejsRuntime,
        schedule: { cron: data.schedule },
        payloadLoggingEnabled: !!data.payloadLoggingEnabled,
      },
      events: data.events,
      queues: data.queues,
      sideload: shouldUseDefaultSchemaSideload ? ['schemas'] : data.sideload,
      tokenOwner: data.tokenOwner,
      settings: data.settings,
      secrets: data.secrets,
    });
  };

  const onSubmitWebhook = (data: NewExtensionData) => {
    setSubmitting(true);

    onSubmit({
      type: extensionWebhookType,
      name: data.name,
      description: data.description,
      config: {
        url: data.url,
        secret: data.secret || null,
        schedule: { cron: data.schedule },
        payloadLoggingEnabled: !!data.payloadLoggingEnabled,
      },
      events: data.events,
      queues: data.queues,
      sideload: data.sideload,
      tokenOwner: data.tokenOwner,
      settings: data.settings,
      secrets: data.secrets,
    });
  };

  const settingsJSONField = useJSONfield<NewExtensionData>({
    name: 'settings',
    trigger,
    setError,
    control,
  });

  const secretsJSONField = useJSONfield<NewExtensionData>({
    name: 'secrets',
    trigger,
    setError,
    control,
  });

  const returnLink = backLink || '/settings/extensions';

  return (
    <PaperBox>
      <PaperBoxTitle to={returnLink}>
        <FormattedMessage id="containers.settings.extensions.createExtension" />
      </PaperBoxTitle>
      <form onSubmit={handleSubmit(onExtensionUpdate)}>
        <div className={styles.Form}>
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.name.label" />
            </FormLabel>
          </div>
          <div className={styles.NameInputWidthLimiter}>
            <ValidationInput<NewExtensionData>
              control={control}
              name="name"
              getErrorMessage={(_, { message }) => message ?? ''}
              dataCy="extensions-name-input"
              placeholder={intl.formatMessage({
                id: `containers.settings.extensions.createExtension.name.placeholder`,
              })}
            />
          </div>
          <div className={styles.LabelWithOptional}>
            <div className={styles.FormLabel}>
              <FormLabel>
                <FormattedMessage id="containers.settings.extensions.createExtension.description.label" />
              </FormLabel>
            </div>
            <span className={styles.Optional}>
              <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
            </span>
          </div>
          <ControlledTextArea<NewExtensionData>
            className={styles.Textarea}
            name="description"
            placeholder={intl.formatMessage({
              id: 'containers.settings.extensions.createExtension.description.placeholder',
            })}
            getErrorMessage={(_, { message }) => message ?? ''}
            dataCy="extensions-description-input"
            control={control}
          />
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.events.label" />
              <Tooltip
                title={
                  <FormattedMessage
                    id="containers.settings.extensions.function.events.tooltip"
                    values={{
                      link: link(docsLinks.webhookEvents, { color: 'white' }),
                      linebreak,
                    }}
                  />
                }
                placement="top"
              >
                <InfoOutlined />
              </Tooltip>
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage id="containers.settings.extensions.createExtension.events.subLabel" />
          </div>
          <Controller
            control={control}
            name="events"
            render={({ field }) => <EventMultiSelect {...field} />}
          />
          <div className={styles.ErrorMessagePlaceholder}>
            {errors.events && (
              <span
                data-cy="error-message-eventsDropdown"
                className={styles.ErrorMessage}
              >
                <FormattedMessage id="containers.settings.extensions.createExtension.events.required" />
              </span>
            )}
          </div>
          {watchEvents.includes('invocation.scheduled') && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.schedule.label" />
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="containers.settings.extensions.createExtension.schedule.tooltip"
                        values={{
                          link: link(EXTERNAL_CRONTAB_VALIDATOR, {
                            color: 'white',
                          }),
                          linebreak,
                        }}
                      />
                    }
                    placement="top"
                  >
                    <InfoOutlined />
                  </Tooltip>
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.schedule.subLabel" />
              </div>
              <ValidationInput
                control={control}
                placeholder="*/10 * * * *"
                name="schedule"
                dataCy="extensions-webhook-schedule-input"
                getErrorMessage={(_, { message }) => message ?? ''}
              />
            </>
          )}
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.queues.label" />
            </FormLabel>
          </div>
          <div className={styles.SubLabel}>
            <FormattedMessage
              id="containers.settings.extensions.createExtension.queues.subLabel"
              values={{
                linebreak,
                italicText,
              }}
            />
          </div>
          <Controller
            control={control}
            name="queues"
            render={({ field }) => (
              <QueueMultiSelect {...field} withLabel={false} />
            )}
          />
          <div className={styles.FormLabel}>
            <FormLabel>
              <FormattedMessage id="containers.settings.extensions.createExtension.extensionType.label" />
            </FormLabel>
          </div>
          <div className={styles.ExtensionTypeWrapper}>
            <ExtensionTypeSelect
              extensionType={extensionFunctionType}
              selected={watchType === extensionFunctionType}
              onClick={() => onTypeChange(extensionFunctionType)}
              dataCy="extension-type-function"
            />
            <ExtensionTypeSelect
              extensionType={extensionWebhookType}
              selected={watchType === extensionWebhookType}
              onClick={() => onTypeChange(extensionWebhookType)}
              dataCy="extension-type-webhook"
            />
          </div>
          {watchType === extensionFunctionType && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.function.programmingLanguage.label" />
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.function.programmingLanguage.subLabel" />
              </div>
              <div className={styles.ExtensionTypeWrapper}>
                <RuntimeButton
                  selected={watchRuntime === defaultPythonRuntime}
                  runtime={defaultPythonRuntime}
                  onClick={() => onWatchRuntimeChange(defaultPythonRuntime)}
                  dataCy={`extensions-function-runtime-${runtimesConfig[defaultPythonRuntime].type}`}
                />
                <RuntimeButton
                  selected={watchRuntime === defaultNodejsRuntime}
                  runtime={defaultNodejsRuntime}
                  onClick={() => onWatchRuntimeChange(defaultNodejsRuntime)}
                  dataCy={`extensions-function-runtime-${runtimesConfig[defaultNodejsRuntime].type}`}
                />
              </div>
            </>
          )}
          {watchType === extensionWebhookType && (
            <>
              <div className={styles.FormLabel}>
                <FormLabel>
                  <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.label" />
                </FormLabel>
              </div>
              <div className={styles.SubLabel}>
                <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.subLabel" />
              </div>
              <ValidationInput<NewExtensionData>
                control={control}
                name="url"
                getErrorMessage={(_, { message }) => message ?? ''}
                dataCy="extensions-webhook-url-input"
                placeholder={intl.formatMessage({
                  id: `containers.settings.extensions.createExtension.webhook.url.placeholder`,
                })}
              />
              <div className={styles.LabelWithOptional}>
                <div>
                  <div className={styles.FormLabel}>
                    <FormLabel>
                      <FormattedMessage id="containers.settings.extensions.createExtension.webhook.sharedSecret.label" />
                    </FormLabel>
                  </div>
                  <div className={styles.SubLabel}>
                    <FormattedMessage
                      id="containers.settings.extensions.createExtension.webhook.sharedSecret.subLabel"
                      values={{ link: link(docsLinks.validatingPayloads) }}
                    />
                  </div>
                </div>
                <span className={styles.Optional}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
                </span>
              </div>
              <ValidationInput
                autoComplete="new-password"
                control={control}
                name="secret"
                type={showSecret ? 'text' : 'password'}
                rightIconProps={{
                  onClick: () => setShowSecret(!showSecret),
                  'data-cy': 'eye-icon',
                }}
                rightIcon={showSecret ? <EyeOffIcon /> : <EyeIcon />}
                dataCy="extensions-webhook-secret-input"
                getErrorMessage={(_, { message }) => message ?? ''}
                placeholder={intl.formatMessage({
                  id: 'containers.settings.extensions.createExtension.webhook.secret.placeholder',
                })}
              />
            </>
          )}
          <AdvancedSettings<NewExtensionData>
            allOrgUsers={organizationUsers.list}
            control={control}
            users={organizationUsers.list}
            settingsJSONField={settingsJSONField}
            secretsJSONField={secretsJSONField}
            setShowAdvancedSettings={setShowAdvancedSettings}
            showAdvancedSettings={showAdvancedSettings}
            showSecretsSection
          />
        </div>
        <PaperBoxFooter>
          <Link to={returnLink}>
            <Button
              variant="outlined"
              color="secondary"
              type="reset"
              data-cy="extensions-cancel-button"
            >
              <FormattedMessage id="containers.settings.extensions.createExtension.button.cancel" />
            </Button>
          </Link>
          <Button
            variant="contained"
            type="submit"
            startIcon={
              submitting ? (
                <CircularProgress color="inherit" size={14} />
              ) : (
                <Add />
              )
            }
            data-cy="extensions-confirm-button"
            disabled={!isValid || submitting}
          >
            {watchType === extensionFunctionType && (
              <FormattedMessage id="containers.settings.extensions.createExtension.button.createFunction" />
            )}
            {watchType === extensionWebhookType && (
              <FormattedMessage id="containers.settings.extensions.createExtension.button.createWebhook" />
            )}
          </Button>
        </PaperBoxFooter>
      </form>
    </PaperBox>
  );
};

export default CreateExtensionForm;
