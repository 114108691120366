import { Typography } from '@rossum/ui/material';
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { EnumOption } from '../../../../../types/schema';

const ListContext = createContext<{
  setSize: (index: number, size: number) => void;
  getSizeMap: () => Record<number, number>;
} | null>(null);

const useListContext = () => {
  const context = useContext(ListContext);

  if (context === null) {
    throw new Error(
      '`useListContext` must be used within a ListContext.Provider'
    );
  }

  return context;
};

type EnumOptionComponentProps = {
  option: EnumOption;
  props: React.HTMLAttributes<HTMLLIElement>;
  index: number;
};

const EnumOptionComponent = ({
  option,
  props,
  index,
}: EnumOptionComponentProps) => {
  const { setSize, getSizeMap } = useListContext();
  const root = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const sizeMap = getSizeMap();
    if (sizeMap[index] === undefined) {
      setSize(index, root.current?.getBoundingClientRect().height ?? 0);
    }
  }, [setSize, index, getSizeMap]);

  return (
    <Typography
      ref={root}
      variant="body2"
      component="li"
      {...props}
      whiteSpace="pre-wrap"
      overflow="hidden"
      sx={{
        overflowWrap: 'anywhere',
      }}
    >
      {option.label || '\u00A0'}
    </Typography>
  );
};

export type RenderRowData = [
  React.HTMLAttributes<HTMLLIElement> & React.Attributes,
  EnumOption,
  number,
];

const renderRow = (
  renderRowProps: ListChildComponentProps<Array<RenderRowData>>
) => {
  const { data, index: renderRowIndex, style } = renderRowProps;
  const rowData = data[renderRowIndex];

  if (!rowData) {
    return null;
  }

  const [{ key, ...props }, option, index] = rowData;

  return (
    <EnumOptionComponent
      key={`${key}-${data.length}`}
      index={index}
      props={{ ...props, style: { ...props.style, ...style, height: 'auto' } }}
      option={option}
    />
  );
};

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export const VirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;

  const itemData = children as Array<RenderRowData>;

  const itemCount = itemData.length;

  const listRef = useRef<VariableSizeList>(null);

  const sizeMap = useRef<Record<number, number>>({});

  const prevCount = useRef(itemCount);
  useLayoutEffect(() => {
    if (prevCount.current !== itemCount) {
      listRef.current?.resetAfterIndex(0);
      listRef.current?.scrollTo(0);
      sizeMap.current = {};
      prevCount.current = itemCount;
    }
  }, [itemCount]);

  const setSize = useCallback(
    (index: number, size: number) => {
      sizeMap.current[index] = size;
      listRef.current?.resetAfterIndex(index);
    },
    [listRef]
  );

  const getSizeMap = useCallback(() => sizeMap.current, []);

  const listContextValue = useMemo(
    () => ({ setSize, getSizeMap }),
    [setSize, getSizeMap]
  );

  const getSize = useCallback((index: number) => {
    // TODO: use itemData[index][1].label.length for estimating the height or just the fallback value
    return sizeMap.current[index] ?? 50;
  }, []);

  const height = useMemo(() => {
    if (itemCount > 8) {
      // there is also `maxHeight: 40vh` set on the parent MUI component
      return 8 * 50;
    }
    return itemData.map((_, i) => getSize(i)).reduce((a, b) => a + b, 0);
  }, [getSize, itemCount, itemData]);

  return (
    <ListContext.Provider value={listContextValue}>
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={height}
            width="100%"
            ref={listRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={getSize}
            overscanCount={20}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    </ListContext.Provider>
  );
});
