import { IconHelp } from '@rossum/ui/icons/tabler';
import {
  List,
  ListItem,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import * as R from 'remeda';
import HTMLMessage from '../../../../components/DatapointMessage/HTMLMessage';
import { DEVELOPER_HUB_LINK } from '../../../../constants/values';
import { snakeToSpaces, upperFirst } from '../../../../lib/keyConvertor';
import { AnnotationAutomationBlocker } from '../../../../redux/modules/annotation/types';
import { BlockersIcon } from './BlockersIcon';

const getArticleLink = (type: string) => {
  switch (type) {
    case 'suggested_edit_present':
    case 'is_duplicate':
      return `${DEVELOPER_HUB_LINK}docs/automation-rules-overview`;

    default:
      return '';
  }
};

const getTypeName = (type: AnnotationAutomationBlocker['type']) =>
  upperFirst(snakeToSpaces(type));

type AnnotationBlockerProps = {
  blocker: AnnotationAutomationBlocker;
};

export const AnnotationBlocker = ({ blocker }: AnnotationBlockerProps) => {
  const messages = R.unique(
    // `content` is in `extension` blocker
    blocker.details?.content ||
      // `messageContent` is in `error_message` blocker
      blocker.details?.messageContent ||
      []
  );

  return (
    <Stack direction="row" spacing={1}>
      <BlockersIcon />

      <Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">{getTypeName(blocker.type)}</Typography>
          {getArticleLink(blocker.type) && (
            <a
              href={getArticleLink(blocker.type)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIcon fontSize="small" color="info">
                <IconHelp />
              </SvgIcon>
            </a>
          )}
        </Stack>
        {messages.length > 0 && (
          <List
            disablePadding
            dense
            sx={{
              listStyleType: 'disc',
              color: 'text.secondary',
              pl: 3,
            }}
          >
            {messages.map(message => (
              <ListItem
                sx={{
                  display: 'list-item',
                  p: 0,
                }}
                key={message}
              >
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{ overflowX: 'auto' }}
                >
                  <HTMLMessage content={message} />
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </Stack>
    </Stack>
  );
};
