import { Box, Paper, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type TileProps = {
  title: string;
  icon: ReactNode;
  to: string;
  description: string;
};

const Tile = ({ title, icon, to, description }: TileProps) => {
  return (
    <Box
      component={Link}
      to={to}
      sx={{
        flex: 1,
        '&:hover': {
          textDecoration: 'none ',
          '& h6': {
            color: 'inherit',
          },
        },
      }}
    >
      <Stack
        component={Paper}
        direction="column"
        px={4}
        py={3}
        gap={3}
        height={1}
        sx={{
          borderRadius: 2,
          transition: t => [t.transitions.create('background-color')],
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: t => t.palette.action.selected,
          },
        }}
      >
        {icon}
        <Stack gap={1}>
          <Typography variant="h5">{title}</Typography>
          <Typography color="text.secondary">{description}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Tile;
