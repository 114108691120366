import { IconCheck, IconQuestionMark } from '@rossum/ui/icons/tabler';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@rossum/ui/material';
import { ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import {
  AUTOMATION_PROCESS,
  FIELDS_VALUE_NOT_FOUND,
} from '../../../../../constants/values';
import { Chunks } from '../../../../../lib/formaterValues';
import { annotationSelector } from '../../../../../redux/modules/annotation/selectors';
import DialogTitle from '../../../../../ui/dialog-title/DialogTitle';
import { formatConfidenceScore, Item } from '../utils';

type ValidationSourceProps = {
  text: string;
  iconColor?: SvgIconProps['color'];
  helpLink?: string | null;
  endAdornment?: ReactNode;
};

const ValidationSource = ({
  text,
  iconColor = 'inherit',
  helpLink,
  endAdornment = null,
}: ValidationSourceProps) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <SvgIcon fontSize="small" color={iconColor}>
      <IconCheck />
    </SvgIcon>
    <Typography variant="body2">{text}</Typography>
    {endAdornment}
    {helpLink && (
      <IconButton
        size="small"
        component="a"
        href={helpLink}
        target="_blank"
        rel="noreferrer noopener"
        sx={{ ':hover': { color: 'inherit' } }}
      >
        <SvgIcon fontSize="small">
          <IconQuestionMark />
        </SvgIcon>
      </IconButton>
    )}
  </Stack>
);

type FieldDetailsDialogProps = {
  onClose: () => void;
  validationSources: string[];
  documentAutomated: boolean;
  title: string;
  confidenceScore: number | null;
};

const FieldDetailsDialog = ({
  onClose,
  validationSources,
  title,
  documentAutomated,
  confidenceScore,
}: FieldDetailsDialogProps) => {
  const intl = useIntl();
  const [validatedByHuman, validatedByOtherSources] = R.partition(
    validationSources,
    source => source === 'human'
  );

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{ elevation: 0, sx: { width: 450 } }}
    >
      <DialogTitle onClose={onClose} title={title} />
      <DialogContent>
        <Stack spacing={2}>
          <Stack sx={{ pt: 2 }} spacing={1}>
            <Typography variant="h6">
              {intl.formatMessage({
                id: 'components.sidebarV2.fieldDetailsDialog.validationSources.title',
              })}
            </Typography>
            {!!validatedByHuman.length && (
              <ValidationSource
                iconColor="success"
                text={intl.formatMessage({
                  id: 'components.documentValidation.validationSources.tooltip.human',
                })}
              />
            )}
          </Stack>

          {validatedByOtherSources.length ? (
            <Stack>
              <Typography variant="h6" color="text.secondary">
                {intl.formatMessage(
                  {
                    id: 'components.documentValidation.validationSources.tooltip.otherSources.title',
                  },
                  {
                    white: (chunks: Chunks[]) => (
                      <Typography
                        component="span"
                        color="text.primary"
                        fontWeight="bold"
                      >
                        {chunks}
                      </Typography>
                    ),
                  }
                )}
              </Typography>
              {validatedByOtherSources.map(validationSource => {
                switch (validationSource) {
                  case 'connector':
                  case 'non_required':
                  case 'additional_value':
                  case 'default_value':
                  case 'history':
                  case 'data_matching':
                  case 'checks':
                  case 'table_suggester':
                  case 'not_found':
                  case 'score':
                    if (
                      validationSource === 'not_found' &&
                      !documentAutomated
                    ) {
                      return null;
                    }

                    return (
                      <ValidationSource
                        key={validationSource}
                        text={intl.formatMessage({
                          id: `components.documentValidation.validationSources.tooltip.${validationSource}`,
                        })}
                        helpLink={
                          validationSource === 'not_found'
                            ? FIELDS_VALUE_NOT_FOUND
                            : AUTOMATION_PROCESS
                        }
                        endAdornment={
                          validationSource === 'score' ? (
                            <Chip
                              size="small"
                              label={
                                formatConfidenceScore(confidenceScore) ?? '---'
                              }
                            />
                          ) : null
                        }
                      />
                    );

                  default:
                    return (
                      <ValidationSource
                        key={validationSource}
                        text={intl.formatMessage({
                          id: 'components.documentValidation.validationSources.tooltip.other',
                        })}
                        helpLink={AUTOMATION_PROCESS}
                      />
                    );
                }
              })}
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {intl.formatMessage({
            id: 'components.sidebarV2.fieldDetailsDialog.close',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useFieldDetailsDialog = (item: Item) => {
  const { automated = false } = useSelector(annotationSelector) ?? {};
  const [fieldDetailsDialogOpen, setFieldDetailsDialogOpen] = useState(false);
  const validationSources =
    'validationSources' in item ? item.validationSources : [];

  const isIrrelevant =
    validationSources.length === 1 &&
    validationSources.includes('not_found') &&
    !automated;

  const fieldDetailsAvailable = !!validationSources.length && !isIrrelevant;

  const handleDialogOpen = useCallback(
    () => setFieldDetailsDialogOpen(true),
    []
  );

  const handleDialogClose = useCallback(() => {
    setFieldDetailsDialogOpen(false);
  }, []);

  const fieldDetailsDialog = fieldDetailsDialogOpen ? (
    <FieldDetailsDialog
      onClose={handleDialogClose}
      validationSources={validationSources}
      documentAutomated={automated}
      confidenceScore={'confidenceScore' in item ? item.confidenceScore : null}
      title={'parentLabel' in item ? item.parentLabel : item.label}
    />
  ) : null;

  return {
    fieldDetailsAvailable,
    fieldDetailsDialog,
    openFieldDetailsDialog: handleDialogOpen,
  };
};
