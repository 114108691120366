import { getIDFromUrl } from '@rossum/api-client';
import { Relation } from '@rossum/api-client/relations';
import { Search } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { GridRowId, GridRowSelectionModel } from '@rossum/ui/x-data-grid-pro';
import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { unique, uniqueBy } from 'remeda';
import { throwInfo } from '../../redux/modules/messages/actions';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import { TransformedAnnotation } from '../document-list-base/hooks/useTransformAnnotation';
import { AddAttachmentsGrid } from './components/AddAttachmentsGrid';
import { AttachmentModalFooter } from './components/AttachmentModalFooter';
import { SuggestedAttachments } from './components/SuggestedAttachments';
import { useCreateAttachments } from './hooks/useAddAttachments';
import { useFetchAttachmentRelation } from './hooks/useFetchAttachmentRelation';
import { usePatchAttachment } from './hooks/usePatchAttachment';
import { HandleOnRowSelectionModelChange } from './types';

const DIALOG_WIDTH = 950;
const SEARCH_MIN_CHAR_LENGTH = 3;

type Props = {
  onClose: () => void;
  rootAnnotationUrl: string | null;
};

export const AddAttachmentsModal = ({ onClose, rootAnnotationUrl }: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedAnnotations, setSelectedAnnotations] = useState<
    TransformedAnnotation[]
  >([]);

  const closeModal = () => {
    setSelectedAnnotations([]);
    onClose();
  };

  const { attachmentRelation } = useFetchAttachmentRelation(
    rootAnnotationUrl ? getIDFromUrl(rootAnnotationUrl) : null
  );

  const { mutate: patchAttachment, isLoading: isPatching } =
    usePatchAttachment();

  const { mutate: createAttachments, isLoading: isCreating } =
    useCreateAttachments();

  const handleSubmit = () => {
    const annotations = selectedAnnotations.map(annotation => annotation.url);

    if (!annotations.length || !rootAnnotationUrl) return;

    const onSuccess = () => {
      dispatch(
        throwInfo('attachmentsAdded', undefined, {
          count: annotations.length,
        })
      );
      closeModal();
    };

    if (attachmentRelation) {
      const newAnnotations = unique([
        ...attachmentRelation.annotations,
        ...annotations,
      ]);

      patchAttachment(
        {
          relationId: attachmentRelation.id,
          payload: { annotations: newAnnotations },
        },
        {
          onSuccess,
        }
      );
    } else
      createAttachments(
        {
          type: 'attachment',
          parent: rootAnnotationUrl,
          annotations,
        },
        {
          onSuccess,
        }
      );
  };

  const isLoading = isPatching || isCreating;

  return (
    <Dialog
      open={!!rootAnnotationUrl}
      onClose={closeModal}
      PaperProps={{
        elevation: 2,
        sx: {
          width: DIALOG_WIDTH,
          maxWidth: 'unset',
          minHeight: 'min(88vh, 620px)',
        },
      }}
    >
      <DialogTitle
        title={intl.formatMessage({
          id: 'components.modal.addAttachments.title',
        })}
        onClose={closeModal}
        closeDataCy="add-attachments-dialog-close-icon"
      />
      {rootAnnotationUrl ? (
        <AddAttachmentsModalContent
          rootAnnotationUrl={rootAnnotationUrl}
          existingRelation={attachmentRelation}
          selectedAnnotations={selectedAnnotations}
          setSelectedAnnotations={setSelectedAnnotations}
        />
      ) : null}
      <DialogActions sx={{ justifySelf: 'right', p: 3, pt: 0 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={closeModal}
          data-cy="add-attachments-dialog-cancel-btn"
        >
          {intl.formatMessage({
            id: 'components.modal.cancel',
          })}
        </Button>
        <Button
          variant="contained"
          startIcon={
            isLoading ? <CircularProgress color="inherit" size={16} /> : null
          }
          disabled={!selectedAnnotations.length || isLoading}
          onClick={handleSubmit}
          data-cy="add-attachments-dialog-submit-btn"
        >
          {intl.formatMessage(
            {
              id: 'components.modal.addAttachments.submit',
            },
            {
              count: selectedAnnotations.length,
            }
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddAttachmentsModalContent = ({
  rootAnnotationUrl,
  existingRelation,
  selectedAnnotations,
  setSelectedAnnotations,
}: {
  rootAnnotationUrl: string;
  existingRelation: Relation | undefined | null;
  selectedAnnotations: TransformedAnnotation[];
  setSelectedAnnotations: Dispatch<SetStateAction<TransformedAnnotation[]>>;
}) => {
  const intl = useIntl();

  const [searchValue, setSearchValue] = useState<string>('');

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const handleOnRowSelectionModelChange: HandleOnRowSelectionModelChange = (
    attachments,
    newModel
  ) => {
    if (!attachments) return;

    const selectedAttachments = [...selectedAnnotations, ...attachments].filter(
      annotation => newModel.includes(annotation.id)
    );

    setRowSelectionModel(newModel);
    setSelectedAnnotations(uniqueBy(selectedAttachments, a => a.id));
  };

  const onRemoveSelection = (id: GridRowId) => {
    setRowSelectionModel(prev => prev.filter(prevId => prevId !== id));
    setSelectedAnnotations(prev =>
      prev.filter(annotation => annotation.id !== id)
    );
  };

  const existingAttachments = existingRelation?.annotations || [];

  const isSearchValid = searchValue.length >= SEARCH_MIN_CHAR_LENGTH;

  return (
    <DialogContent>
      <Stack pt={2} height={1}>
        <Stack sx={{ minHeight: 56 }}>
          <TextField
            placeholder={intl.formatMessage({
              id: 'components.modal.addAttachments.searchPlaceholder',
            })}
            name="attachmentSearch"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            data-cy="add-attachment-modal-search"
            InputProps={{
              startAdornment: <Search />,
            }}
            sx={{ maxWidth: 0.5 }}
            helperText={
              searchValue.length && !isSearchValid ? (
                <Typography variant="caption" color="text.secondary">
                  {intl.formatMessage(
                    {
                      id: 'containers.allDocuments.search.minLength',
                    },
                    { minLength: SEARCH_MIN_CHAR_LENGTH }
                  )}
                </Typography>
              ) : undefined
            }
          />
        </Stack>
        {isSearchValid ? (
          <AddAttachmentsGrid
            rootAnnotationUrl={rootAnnotationUrl}
            existingAttachments={existingAttachments}
            searchValue={searchValue}
            rowSelectionModel={rowSelectionModel}
            handleOnRowSelectionModelChange={handleOnRowSelectionModelChange}
            isSearchValid={isSearchValid}
          />
        ) : (
          <SuggestedAttachments
            rootAnnotationUrl={rootAnnotationUrl}
            existingAttachments={existingAttachments}
            rowSelectionModel={rowSelectionModel}
            handleOnRowSelectionModelChange={handleOnRowSelectionModelChange}
          />
        )}
        <AttachmentModalFooter
          selectedAnnotations={selectedAnnotations.map(
            ({ id, original_file_name }) => ({
              name: original_file_name || '',
              id,
            })
          )}
          onRemove={onRemoveSelection}
        />
      </Stack>
    </DialogContent>
  );
};
