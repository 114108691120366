import { MetaField } from '@rossum/api-client/shared';
import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
} from '@rossum/ui/x-data-grid-pro';
import { groupBy } from 'lodash';
import { uniqueBy } from 'remeda';
import { absoluteApiUrl } from '../../../constants/config';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { dataGridOperatorsMap, detailsColumnOptionKeys } from './constants';
import { dateToQuery, isDateOperator } from './dateUtils';
import {
  getColumn,
  getOperator,
  isEmptyValue,
  isFilterContextSimple,
} from './helpers';
import {
  ApiConditionModel,
  FilterContext,
  FilterItem,
  FilterValues,
  MQLOperators,
  TypedGridColDef,
} from './types';

export const createNewFilterQuery = (newFilterItems: GridFilterItem[]) =>
  JSON.stringify({
    items: newFilterItems,
    logicOperator: GridLogicOperator.And,
  });

const idToUrl = (id: number | string, path: string) =>
  `${absoluteApiUrl}${path}/${id}`;

const modifierFn = (value: string) => idToUrl(value, '/users');

const valueNormalizers: Partial<Record<MetaField, (val: string) => string>> = {
  labels: (value: string) => idToUrl(value, '/labels'),
  queue: (value: string) => idToUrl(value, '/queues'),
  assignees: modifierFn,
  modifier: modifierFn,
  exported_by: modifierFn,
  confirmed_by: modifierFn,
  rejected_by: modifierFn,
  deleted_by: modifierFn,
};

const fieldHasNormalizer = <O extends Record<string, unknown>>(
  key: string | number | symbol,
  obj: O
): key is keyof O => key in obj;

/**
 * Use this function in case the value needs to be transformed to different format once,
 * e.g. convert ID to URL before making request
 */
export const transformValue = (value: string, field: string) => {
  // isEmpty or isNotEmpty should not be normalised
  if (typeof value === 'boolean') return value;

  const valueNormalizer = fieldHasNormalizer(field, valueNormalizers)
    ? valueNormalizers[field]
    : null;

  return valueNormalizer
    ? Array.isArray(value)
      ? value.map(valueNormalizer)
      : valueNormalizer(value)
    : value;
};

type ExistingFilterState = Record<string, FilterItem> | null;

const isValidStringOrNumber = (value: unknown) =>
  typeof value === 'string' || typeof value === 'boolean';

const mergeNormalizers = {
  multiSelect: <EV, IV>(existingValue: EV, incomingValue: IV) => {
    const existingArray = Array.isArray(existingValue) ? existingValue : [];
    const incomingArray = Array.isArray(incomingValue) ? incomingValue : [];
    return [...existingArray, ...incomingArray];
  },
  string: (_: unknown, incomingValue: unknown) => {
    return isValidStringOrNumber(incomingValue) ? incomingValue : null;
  },
  number: (_: unknown, incomingValue: unknown) => {
    return isValidStringOrNumber(incomingValue) ? incomingValue : null;
  },
  date: () => null,
} as const;

// if for some reason we receive a filter query that has the same operator multiple times (e.g. 2-3 'isAnyOf' or 'greater than') we normalize them
// either by merging the array values together, or by prioritising the late comer over the others
// date component handles this locally by warning the user so we dont handle it here
const mergeSameOperators = (filterContext: FilterValues[]) => {
  const mergedFilter = filterContext.reduce<FilterValues[]>(
    (acc, { operator, value }) => {
      const existingFilter = acc.find(
        el => el?.operator.value === operator.value
      );

      const mergedValues = mergeNormalizers[operator.type](
        existingFilter?.value,
        value
      );

      if (mergedValues === null) return acc;

      if (!existingFilter) return acc.concat({ operator, value });

      const mergedFilter = {
        ...existingFilter,
        value: mergedValues,
      };

      return uniqueBy([mergedFilter, ...acc], filter => filter.operator.value);
    },
    []
  );

  const firstMergedFilter = mergedFilter[0];
  if (!firstMergedFilter) return filterContext;

  return mergedFilter.length === 1 ? firstMergedFilter : mergedFilter;
};

export const getInitialFilter = (
  existingFilter: GridFilterModel,
  columns: TypedGridColDef[]
): ExistingFilterState => {
  return existingFilter.items.reduce<ExistingFilterState>(
    (acc, gridFilterItem) => {
      const column = getColumn(gridFilterItem.field, columns);
      const operator = getOperator(gridFilterItem.operator, column?.operators);

      const newFilter: FilterContext | null = operator
        ? {
            operator,
            value: gridFilterItem.value,
          }
        : null;

      // this means that the url has some unexpected value
      if (!column || !newFilter) return acc;

      const existingColumn = acc?.[gridFilterItem.field];

      if (existingColumn) {
        // simpleFilter only exists if we re-iterate over the column for the first time which is the moment we move simple filter into advancedFilters
        const existingFilters = isFilterContextSimple(
          existingColumn.filterContext
        )
          ? [existingColumn.filterContext]
          : existingColumn.filterContext;

        const mergedFilter = mergeSameOperators([
          ...existingFilters,
          newFilter,
        ]);

        return {
          ...acc,
          [gridFilterItem.field]: {
            column,
            filterContext: mergedFilter,
          },
        };
      }

      const prev = acc ?? {};

      return {
        ...prev,
        [gridFilterItem.field]: {
          column,
          filterContext: newFilter,
        },
      };
    },
    null
  );
};

const resolveUnorganisedFieldsInUrl = (items: GridFilterItem[]) =>
  Object.values(groupBy(items, 'field')).flat();

const replaceExistingItem = (
  existingFilter: GridFilterModel,
  incomingFilters: GridFilterItem[]
) => {
  if (!incomingFilters.length) return existingFilter.items;
  // currently incomingFilters come only from one column that has an existing filter
  // so we won't be checking for undefined values later on, we know they must be there.
  const columnName = incomingFilters[0]?.field;

  // we should always make sure filters with same column come after each other in the array existingFilter.items
  // we mainly do this for possible saved bookmarks in the past
  const existingItems = resolveUnorganisedFieldsInUrl(existingFilter.items);

  const columnFirstIndex = existingItems.findIndex(
    item => item.field === columnName
  );
  const columnLastIndex = existingItems.findLastIndex(
    item => item.field === columnName
  );

  return [
    ...existingItems.slice(0, columnFirstIndex),
    ...incomingFilters,
    ...existingItems.slice(columnLastIndex + 1),
  ];
};

export const getUpdatedExistingFilter = ({
  existingFilter,
  incomingFilters,
  isReplaceAction,
}: {
  existingFilter: GridFilterModel | null;
  incomingFilters: GridFilterItem[];
  isReplaceAction?: boolean;
}): GridFilterItem[] => {
  if (!existingFilter)
    return incomingFilters.flatMap(item =>
      isEmptyValue(item.value) ? [] : [item]
    );

  if (isReplaceAction)
    return replaceExistingItem(existingFilter, incomingFilters);

  // add new filter if the operator does not exist
  return existingFilter.items.concat(incomingFilters);
};

const firstStringToUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const resolveDateOperator = (operator: string) =>
  ({ onOrBefore: 'before', onOrAfter: 'after' })[operator] || operator;

export const encodeEmailsFiltering = (filterModel: GridFilterModel) =>
  filterModel.items.reduce((acc, { field, operator, value }) => {
    if (isDateOperator(operator)) {
      const dateOperator = resolveDateOperator(operator);
      return {
        ...acc,
        // api for emailThreads filtering uses combination of field name and operator to determine the date filter
        [`${snakeToCamel(field)}${firstStringToUpperCase(dateOperator)}`]:
          dateToQuery(new Date(value)),
      };
    }

    if (field === 'options' && Array.isArray(value)) {
      return {
        ...acc,
        ...value.reduce<Record<string, true>>((acc, value) => {
          return { ...acc, [`${value}`]: true };
        }, {}),
      };
    }

    return acc;
  }, {});

type ColumnEncoder = (params: {
  mqlOperator: MQLOperators;
  value: unknown;
}) => ApiConditionModel['$and'] | null;

const relationsEncoder =
  (key: 'duplicate' | 'attachment') => (operator: string) => ({
    relations__type: {
      [operator]: key,
    },
  });

const detailsValueMap = {
  emails: operator => ({
    email_thread: {
      [dataGridOperatorsMap.isEmpty]: operator !== '$eq',
    },
  }),
  duplicates: relationsEncoder('duplicate'),
  attachments: relationsEncoder('attachment'),
  automated: operator => ({ automated: { [operator]: true } }),
  automatically_rejected: operator => ({
    automatically_rejected: { [operator]: true },
  }),
} as const satisfies Record<
  keyof typeof detailsColumnOptionKeys,
  (operator: string) => ApiConditionModel['$and'][number]
>;

const isDetailsKey = (
  val: string
): val is keyof typeof detailsColumnOptionKeys => val in detailsValueMap;

export const columnEncoderMap: Record<string, ColumnEncoder> = {
  details: ({ mqlOperator, value }) => {
    if (!Array.isArray(value)) return null;

    const operatorKey: MQLOperators = mqlOperator === '$in' ? '$eq' : '$ne';

    return value.flatMap(val => {
      if (typeof val !== 'string') return [];

      const valueEncoder = isDetailsKey(val) ? detailsValueMap[val] : null;

      return valueEncoder ? valueEncoder(operatorKey) : [];
    });
  },
};
