import {
  IconCode,
  IconDeviceTv,
  IconHelpCircle,
  IconInfoSquare,
  IconKeyboard,
  IconLifebuoy,
  IconLink,
  IconRocket,
} from '@rossum/ui/icons/tabler';
import { IconButton, Menu, Stack, SvgIcon } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  DEVELOPER_HUB_LINK,
  HELP_CENTER_LINK,
  KEYBOARD_SHORTCUTS_LINK,
  QUICK_START_GUIDE,
  ROSSUM_VIDEOS_LINK,
} from '../../constants/values';
import {
  organizationSelector,
  supportMenuItemsSelector,
} from '../../redux/modules/organization/selectors';
import { reportProblem } from '../../redux/modules/report/actions';
import { SupportMenuItem } from '../../types/organization';
import { State } from '../../types/state';
import { MenuLinkItem } from '../../ui/menu-link-item/MenuLinkItem';

type StaticMenuItem = {
  key:
    | 'quickStart'
    | 'videos'
    | 'helpCenter'
    | 'devHub'
    | 'keyboardShortcuts'
    | 'report';
  icon: React.ElementType;
  href: string | undefined;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  dataCy?: string;
  rel?: string;
};

type StateProps = {
  organizationLoaded: boolean;
  supportMenuItems?: Array<SupportMenuItem>;
};

type DispatchProps = {
  openDialog: () => void;
};

type Props = StateProps & DispatchProps;

const HelpPanel = ({
  organizationLoaded,
  supportMenuItems,
  openDialog,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();

  const isOpen = !!anchorEl;

  const hideSupportMenu =
    !organizationLoaded || (supportMenuItems && supportMenuItems.length === 0);

  if (hideSupportMenu) {
    return null;
  }

  const staticMenuItems: Array<StaticMenuItem> = [
    {
      key: 'quickStart',
      icon: IconRocket,
      href: QUICK_START_GUIDE,
      rel: 'noreferrer noopener',
    },
    {
      key: 'videos',
      icon: IconDeviceTv,
      href: ROSSUM_VIDEOS_LINK,
      rel: 'noreferrer noopener',
    },
    {
      key: 'helpCenter',
      icon: IconLifebuoy,
      href: HELP_CENTER_LINK,
      rel: 'noreferrer noopener',
    },
    {
      key: 'devHub',
      icon: IconCode,
      href: DEVELOPER_HUB_LINK,
      rel: 'noreferrer noopener',
    },
    {
      key: 'keyboardShortcuts',
      icon: IconKeyboard,
      href: KEYBOARD_SHORTCUTS_LINK,
      rel: 'noreferrer noopener',
    },
    {
      key: 'report',
      icon: IconInfoSquare,
      href: undefined,
      dataCy: 'report-problem-button',
      onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        openDialog();
      },
    },
  ] as const;

  return (
    <Stack direction="row" spacing={1}>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {supportMenuItems
          ? supportMenuItems.map(({ url, label }) => (
              <MenuLinkItem
                key={url + label}
                href={url}
                Icon={IconLink}
                label={label}
                onClick={undefined}
                rel="noopener noreferrer"
              />
            ))
          : staticMenuItems.map(item => (
              <MenuLinkItem
                key={item.key}
                href={item.href}
                Icon={item.icon}
                label={intl.formatMessage({
                  id: `components.helpPanel.${item.key}`,
                })}
                onClick={item.onClick}
                dataCy={item.dataCy}
                rel={item.rel}
              />
            ))}
      </Menu>
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        color="secondary"
        data-cy="help-panel-button"
      >
        <SvgIcon
          sx={{ fill: 'none' }}
          fontSize="small"
          component={IconHelpCircle}
        />
      </IconButton>
    </Stack>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  organizationLoaded: !!organizationSelector(state).id,
  supportMenuItems: supportMenuItemsSelector(state),
});

export default connect(mapStateToProps, {
  openDialog: reportProblem,
})(HelpPanel);
