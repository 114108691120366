import { getMasterDataHub } from '@rossum/mdh-api-client';
import { AxiosRequestConfig } from 'axios';
import { getAuthToken } from '../../lib/token';

export const datasetApi = getMasterDataHub();

export const getDatasetRequestConfig: () => AxiosRequestConfig = () => ({
  headers: { Authorization: `Bearer ${getAuthToken()}` },
  baseURL: 'https://7923-39.app.review.r8.lol',
});
