import { Button, Stack } from '@rossum/ui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DrawerConfig } from '../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { Annotation } from '../../../../types/annotation';
import { SidebarFooterActions } from './sidebar-footer-actions/SidebarFooterActions';
import { useConfirmButton } from './sidebar-footer-actions/useConfirmButton';

// 35px width + 8px spacing
const SIDEBAR_ACTION_BUTTON_WIDTH = 44;

type SidebarFooterProps = {
  annotation: Annotation;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

const SidebarFooter = React.memo(
  ({ annotation, onEmailThreadOpen }: SidebarFooterProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [visibleActionButtonCount, setVisibleActionButtonCount] = useState(4);

    const resizeObserverRef = useRef<ResizeObserver>(
      new ResizeObserver(([entry]) => {
        const contentBox = entry ? entry.contentBoxSize[0] : null;
        if (contentBox) {
          const availableSpace = contentBox.inlineSize / 2;
          const noOfActions = Math.floor(
            availableSpace / SIDEBAR_ACTION_BUTTON_WIDTH
          );

          setVisibleActionButtonCount(count =>
            noOfActions !== count ? noOfActions : count
          );
        }
      })
    );

    useEffect(() => {
      const resizeObserver = resizeObserverRef.current;
      const container = containerRef.current;

      if (container) {
        resizeObserver.observe(container);
      }

      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
      };
    }, []);

    /** <Confirm button> */
    const {
      disabled: confirmButtonDisabled,
      focused: confirmButtonFocused,
      startIcon: confirmButtonStartIcon,
      label: confirmButtonLabel,
      onClick: handleConfirmClick,
      onKeyDown: handleConfirmKeyDown,
    } = useConfirmButton(annotation);

    const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      const confirmButton = confirmButtonRef.current;
      if (confirmButton) {
        if (confirmButtonFocused) {
          confirmButton.focus();
        } else {
          confirmButton.blur();
        }
      }
    }, [confirmButtonFocused]);
    /** </Confirm button> */

    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          py: 1,
          px: 1,
          width: '100%',
          borderTop: '1px solid',
          // TODO: Should be other.divider once available
          borderColor: theme => theme.palette.other.muted,
          backgroundColor: theme => theme.palette.background.paper,
        }}
        ref={containerRef}
      >
        <SidebarFooterActions
          visibleButtonsCount={visibleActionButtonCount}
          annotation={annotation}
          onEmailThreadOpen={onEmailThreadOpen}
        />
        <Button
          ref={confirmButtonRef}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
          disabled={confirmButtonDisabled}
          startIcon={confirmButtonStartIcon}
          onClick={handleConfirmClick}
          onKeyDownCapture={handleConfirmKeyDown}
        >
          {confirmButtonLabel}
        </Button>
      </Stack>
    );
  }
);

SidebarFooter.displayName = 'SidebarFooter';

export { SidebarFooter };
