import {
  Collapse,
  collapseClasses,
  Divider,
  dividerClasses,
  Stack,
} from '@rossum/ui/material';
import React, { Fragment, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ResizablePane } from '../../../components/ResizablePane/ResizablePane';
import { DrawerConfig } from '../../../containers/DocumentValidation/ValidationEmailDrawer';
import { assertNever } from '../../../lib/typeUtils';
import { annotationSelector } from '../../../redux/modules/annotation/selectors';
import {
  createDatapoint,
  deleteAllDatapoints,
  deleteDatapointAndNavigate,
  selectDatapoint,
  updateDatapointValue,
  validate,
} from '../../../redux/modules/datapoints/actions';
import {
  allMessagesSelector,
  datapointPathSelector,
} from '../../../redux/modules/datapoints/selector';
import { openPopup } from '../../../redux/modules/popup/actions';
import { addValueActiveSelector } from '../../../redux/modules/router/selectors';
import { readOnlySelector } from '../../../redux/modules/ui/selectors';
import { State } from '../../../types/state';
import { useAutomationBlockersList } from './automation-blockers/useAutomationBlockersList';
import { useDeleteRecommendations } from './delete-recommendations/useDeleteRecommendations';
import { isEditableFormulaField } from './formula-helpers';
import { useReviewNeeded } from './review-needed/useReviewNeeded';
import { SidebarContainer } from './shared/SidebarContainer';
import { SidebarFooter } from './sidebar-footer/SidebarFooter';
import { ButtonItem } from './sidebar-items/ButtonItem';
import { EnumItem } from './sidebar-items/EnumItem';
import { LineItemsItem } from './sidebar-items/LineItemsItem';
import { MultivalueItem } from './sidebar-items/MultivalueItem';
import { Messages } from './sidebar-items/shared/Messages';
import { SidebarSectionTitle } from './sidebar-items/shared/SidebarSectionTitle';
import { SimpleValueItem } from './sidebar-items/SimpleValueItem';
import {
  SIDEBAR_TOPBAR_HEIGHT,
  SidebarTopbar,
} from './sidebar-topbar/SidebarTopbar';
import {
  SidebarEnumFieldModel,
  SidebarMultivalueChildFieldModel,
  SidebarSimplevalueFieldModel,
  useSidebarData,
} from './useSidebarData';

type DocumentSidebarProps = {
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};
export const DocumentSidebar = React.memo(
  ({ onEmailThreadOpen }: DocumentSidebarProps) => {
    // TODO: @sidebarV2 prop
    const annotation = useSelector(annotationSelector);

    const intl = useIntl();

    const globalMessages = useSelector(allMessagesSelector).all;

    const sidebarData = useSidebarData();

    const currentDatapointPath = useSelector(datapointPathSelector);

    const readOnly = useSelector(readOnlySelector);

    const addValueActive = useSelector(addValueActiveSelector);

    const loadingDatapoints = useSelector(
      (state: State) => state.datapoints.loadingDatapointIds
    );

    const deleteRecommendations = useDeleteRecommendations();

    const reviewNeeded = useReviewNeeded();

    const dispatch = useDispatch();

    const scrollableRef = useRef<HTMLDivElement | null>(null);

    const handleSelectDatapoint = useCallback(
      (id: number) => {
        dispatch(selectDatapoint([id], { noTail: true }));
      },
      [dispatch]
    );

    const automationBlockersList = useAutomationBlockersList({
      handleSelectDatapoint,
    });

    const handleDatapointChange = useCallback(
      (
        item:
          | SidebarSimplevalueFieldModel
          | SidebarEnumFieldModel
          | SidebarMultivalueChildFieldModel,
        value: string
      ) => {
        dispatch(
          updateDatapointValue(
            {
              id: item.id,
              index: item.meta?.datapointIndex,
              oldValue: item.value,
              validationSource: 'human',
              reason: isEditableFormulaField(item.valueSource, item.editing)
                ? 'manual-formula-override-sidebar'
                : 'input-sidebar',
              noRecalculation:
                // we basically set only `true | undefined` in onChange here,
                // `false` AKA reconnecting the formula is handled elsewhere
                isEditableFormulaField(item.valueSource, item.editing) ||
                undefined,
            },
            value
          )
        );
      },
      [dispatch]
    );

    const handleButtonDatapointClick = useCallback(
      (id: number, popupUrl?: string | null, canObtainToken?: boolean) => {
        dispatch(
          popupUrl
            ? openPopup(popupUrl, canObtainToken === true)
            : validate([id], undefined, ['user_update', 'updated'])
        );
      },
      [dispatch]
    );

    const handleMultivalueAdd = useCallback(
      (parentIndex: number) => {
        dispatch(createDatapoint(parentIndex));
      },
      [dispatch]
    );

    const handleMultivalueDelete = useCallback(() => {
      // Only allow deleting multivalue children
      // TODO: This would be better done with just datapoint ID
      // Delete epic requires entire path, not just ID, so do this maybe when refactoring state mgmt
      if (currentDatapointPath.length === 3) {
        dispatch(deleteDatapointAndNavigate(currentDatapointPath));
      }
    }, [currentDatapointPath, dispatch]);

    const handleMultivalueDeleteAll = useCallback(
      (parentIndex: number) => {
        dispatch(deleteAllDatapoints(parentIndex));
      },
      [dispatch]
    );

    const globalMessagesSection = (
      <Collapse in={!!globalMessages && globalMessages.length > 0}>
        {!!globalMessages && globalMessages.length > 0 ? (
          <SidebarContainer
            title={intl.formatMessage({
              id: 'components.sidebarV2.messages.title',
            })}
          >
            <Stack py={1}>
              <Messages messages={globalMessages} alertVariant="outlined" />
            </Stack>
          </SidebarContainer>
        ) : (
          <span />
        )}
      </Collapse>
    );

    return (
      <ResizablePane
        storageKey="annotation-view:sidebar-width"
        paneConfig={{ min: 400, max: 700, initial: 400 }}
      >
        <Stack sx={{ width: '100%' }}>
          {/* TODO: Loading state */}
          {annotation ? (
            <SidebarTopbar
              scrollableRef={scrollableRef.current}
              annotation={annotation}
              onEmailThreadOpen={onEmailThreadOpen}
            />
          ) : null}
          <Stack
            id="sidebar-scrollable"
            ref={scrollableRef}
            divider={<Divider sx={{ mb: 2, mt: 1 }} />}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'background.paper',
              position: 'relative',
              overflowY: 'scroll',
              display: 'block',
              pt: `${SIDEBAR_TOPBAR_HEIGHT}px`,
              // do not render dividers after invisible sections
              [` .${collapseClasses.hidden} + .${dividerClasses.root}`]: {
                display: 'none',
              },
            }}
          >
            {deleteRecommendations}
            {reviewNeeded}
            {automationBlockersList}
            {globalMessagesSection}
            {sidebarData.map(item => {
              return (
                <Fragment key={item.id}>
                  <SidebarSectionTitle title={item.label} />

                  {/* TODO: Get rid of annotation redux dependency for all items (type) */}
                  {item.children.map(child => {
                    const active = currentDatapointPath.includes(child.id);
                    const loading = loadingDatapoints.includes(child.id);
                    switch (child.kind) {
                      case 'sidebar-simplevalue-field': {
                        return (
                          <SimpleValueItem
                            key={child.id}
                            sidebarScrollableRef={scrollableRef.current}
                            annotation={annotation}
                            item={child}
                            active={active}
                            disabled={readOnly}
                            onClick={handleSelectDatapoint}
                            onChange={handleDatapointChange}
                            loading={loading}
                          />
                        );
                      }

                      case 'sidebar-button-field': {
                        return (
                          <ButtonItem
                            key={child.id}
                            item={child}
                            onClick={handleButtonDatapointClick}
                          />
                        );
                      }

                      case 'sidebar-enum-field': {
                        return (
                          <EnumItem
                            key={child.id}
                            sidebarScrollableRef={scrollableRef.current}
                            annotation={annotation}
                            item={child}
                            active={active}
                            disabled={readOnly}
                            onClick={handleSelectDatapoint}
                            onChange={handleDatapointChange}
                            loading={loading}
                          />
                        );
                      }

                      case 'sidebar-multivalue-field': {
                        return (
                          <MultivalueItem
                            key={child.id}
                            sidebarScrollableRef={scrollableRef.current}
                            annotation={annotation}
                            item={child}
                            active={
                              active &&
                              currentDatapointPath.length === 2 &&
                              !addValueActive
                            }
                            disabled={readOnly}
                            focused={active}
                            addValueActive={addValueActive}
                            onClick={handleSelectDatapoint}
                            onChange={handleDatapointChange}
                            onAddChild={handleMultivalueAdd}
                            onDeleteChild={handleMultivalueDelete}
                            onDeleteAll={handleMultivalueDeleteAll}
                          />
                        );
                      }

                      case 'sidebar-line-item-field': {
                        return (
                          <LineItemsItem
                            key={child.id}
                            sidebarScrollableRef={scrollableRef.current}
                            annotation={annotation}
                            item={child}
                            disabled={readOnly}
                            active={active}
                            onClick={handleSelectDatapoint}
                          />
                        );
                      }

                      default: {
                        return assertNever(child);
                      }
                    }
                  })}
                </Fragment>
              );
            })}
          </Stack>
          {/* TODO: Loading state */}
          {annotation ? (
            <SidebarFooter
              annotation={annotation}
              onEmailThreadOpen={onEmailThreadOpen}
            />
          ) : null}
        </Stack>
      </ResizablePane>
    );
  }
);
